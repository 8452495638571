import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const cardData = [
  {
    imgSrc: 'https://shilohbridal.pmpframe.com/api/upload/order.png',
    title: 'Your Order',
    text: 'Track orders for seamless delivery updates.',
    path: '/dashboard/orders' 
  },
  {
    imgSrc: 'https://shilohbridal.pmpframe.com/api/upload/address.png',
    title: 'Your Address',
    text: 'Save your addresses for a faster, hassle-free checkout every time.',
    path: '/dashboard/address' 
  },
  {
    imgSrc: 'https://shilohbridal.pmpframe.com/api/upload/account.png',
    title: 'Payment Methods',
    text: 'Manage and update your saved payments',
    path: '/dashboard/PaymentMethods' 
  },
  {
    imgSrc: 'https://shilohbridal.pmpframe.com/api/upload/userdetails.png',
    title: 'Profile Details',
    text: 'Manage your personal and account information.',
    path: '/dashboard/PersonalDetails' 
  },
];

const Dashboard = () => {
  const navigate = useNavigate();
  const [fullName, setFullName] = useState("Full Name");

  // Function to handle card click and navigate to the appropriate path
  const handleCardClick = (path) => {
    navigate(path);
  };

  useEffect(() => {
    const userId = localStorage.getItem('user_id');

    if (userId) {
      const formData = new FormData();
      formData.append('action', 'user_details'); // Updated action as required
      formData.append('userid', userId);

      fetch(`https://shilohbridal.pmpframe.com/api/user.php`, {
        method: 'POST',
        body: formData,
      })
        .then(response => response.json())
        .then(data => {
          if (data.success && data.message.length > 0) {
            const { first_name, last_name } = data.message[0];
            setFullName(`${first_name} ${last_name}`);
          }
        })
        .catch(error => {
          console.error("Error fetching user details:", error);
        });
    }
  }, []);

  return (
    <Row className="g-4 px-5">
      <Col md={12}>
        <Card className="d-flex align-items-left p-4 px-5 shadow-sm">
          <div className="d-flex align-items-center">
            <img
              src="https://shilohbridal.pmpframe.com/api/upload/userdetails.png"
              alt="Profile"
              className="rounded-circle me-3"
              style={{ width: '65px', height: '65px' }}
            />
            <div className="me-auto">
              <h3 style={{ color: "#121212BF" }}>{fullName}</h3>
            </div>
            <Button variant="outline px-5 py-2" style={{ backgroundColor: "#ffffff", border: "2px solid #8E1336", color: "#8E1336", fontSize: "15px" }}>Edit</Button>
          </div>
        </Card>
      </Col>
      {cardData.map((card, index) => (
        <Col md={4} key={index}>
          <Card
            className="text-center shadow-sm h-100"
            onClick={() => handleCardClick(card.path)}
            style={{ cursor: 'pointer' }}
          >
            <Card.Img
              variant="top"
              src={card.imgSrc}
              className="mx-auto mt-3"
              style={{ width: '65px', height: '65px' }}
            />
            <Card.Body>
              <Card.Title style={{ fontSize: "24px" }}>{card.title}</Card.Title>
              <Card.Text className="small" style={{ fontSize: "12px" }}>{card.text}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default Dashboard;
