import { toast } from "react-toastify";
import {
  get_wish_list,
  update_wish_list,
} from "../../Redux/Slice/ProductSlice";
import store from "../../Redux/store";
import { useSelector } from "react-redux";
// import store from "../path-to-your-store"; // Adjust the path to your Redux store
// import { update_wish_list, get_wish_list } from "../path-to-your-actions"; // Adjust paths to actions

export const ReduceFunction = (arr, key) => {
  return arr
    ?.map((v) => Number(v?.[key] || 0))
    ?.reduce((a, b) => a + b, 0)
    ?.toFixed(2);
};

export const addToWishlist = async (itemCode) => {
  const userId = localStorage.getItem("user_id");
  if (!userId) {
    alert("Please login to add items to wishlist");
    return;
  }
  try {
    const { wish_list } = store.getState().productData;
    const result = await store.dispatch(
      update_wish_list({
        id: userId,
        code: itemCode,
        action: wish_list?.some((item) => item.itemcode === itemCode)
          ? "wishlist_remove"
          : "wishlist_add",
      })
    );

    const newData = result.payload;

    if (newData?.success) {
      toast.success(newData.message);
      store.dispatch(get_wish_list(userId));
    }
  } catch (error) {
    console.error("Error adding to wishlist:", error);
  }
};


export const handleSubmitMesurment = (measurements) => {
  const errors = {};
  const requiredFields = {
    neck_girth: { message: "Neck Girth is required.", min: 8, max: 23 },
    upper_bust: { message: "Upper Bust is required.", min: 16, max: 59 },
    medium_bust: { message: "Medium Bust is required.", min: 20, max: 62 },
    lower_bust: { message: "Lower Bust is required.", min: 20, max: 62 },
    Waist: { message: "Waist is required.", min: 15, max: 59 },
    Hips: { message: "Hips are required.", min: 24, max: 62 },
    Height: { message: "Height is required.", min: 50, max: 86 },
    waist_to_floor: {
      message: "Waist to Floor is required.",
      min: 26,
      max: 57,
    },
    waist_to_knee: {
      message: "Waist to Knee is required.",
      min: 15,
      max: 30,
    },
    shoulder_span: {
      message: "Shoulder Span is required.",
      min: 12,
      max: 19,
    },
    bust_height: { message: "Bust Height is required.", min: 6, max: 17 },
    front_corset: {
      message: "Front Corset Height is required.",
      min: 12,
      max: 23,
    },
    back_corset: {
      message: "Back Corset Height is required.",
      min: 12,
      max: 27,
    },
    corset_side: { message: "Corset Side is required.", min: 4, max: 19 },
    arm_girth: { message: "Arm Girth is required.", min: 7, max: 23 },
    sleeve_length: {
      message: "Sleeve Length is required.",
      min: 16,
      max: 29,
    },
    wrist_girth: { message: "Wrist Girth is required.", min: 4, max: 7 },
  };

  Object.entries(requiredFields).forEach(([key, { message, min, max }]) => {
    const value = parseFloat(measurements[key]);
    if (!measurements[key] || measurements[key].trim() === "") {
      errors[`${key}_error`] = message;
    } else if (isNaN(value) || value < min || value > max) {
      errors[`${key}_error`] = `Value must be between ${min} and ${max}.`;
    }
  });
  // setMeasurements({ ...measurements, ...errors });
  // if (Object.keys(errors).length === 0) {
  //   setIsModalOpen(false);
  // } else if (Object.keys(errors).length > 0) {
  //   setIsModalOpen(true);
  // }
  // handleSubmit(errors);
  return errors;
};