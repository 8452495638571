import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './ForgotPassword.css';

export default function NewPassword() {
  const navigate = useNavigate(); // Initialize the navigate hook

  const logo = "https://shilohbridal.pmpframe.com/api/upload/logo.svg";

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // You can add form validation logic here or an API call to verify the email/phone
    navigate('/OTP'); // Redirect to the OTP page
  }

  return (
    <div className="container-forgotpassword">
      <div className="row">
        <div className="col-lg-12 align-items-center justify-content-end">
          <div className="form-2-wrapper">
            <div className="logo text-center">
              <img src={logo} className="logo-image" alt="Shiloh Bridal Logo" />
            </div>
            <h2 className="text-center mb-4">Create New Password</h2> 
            <form onSubmit={handleSubmit}> {/* Attach onSubmit handler */}
              <div className="mb-3 form-box">
                <label className="text-forgot" style={{marginBottom: '16px', marginLeft: '18px'}}>
                Your New Password Must Be Different From Previously Used Password
                </label>
              
                <input type="email" className="form-control form-control-sm" placeholder="Enter New Password" />
              </div>

              <div className="mb-3 form-box">
                <input type="email" className="form-control form-control-sm" placeholder="Enter New Confirm Password" />
              </div>
              
              <button
                type="submit"
                className="btn btn-outline-secondary login-btn w-100 mb-3"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
