// import React from 'react';
// import { FaPhone, FaEnvelope } from 'react-icons/fa';

// // Reusable SectionHeader component
// const SectionHeader = ({ title }) => {
//     return <h4 className="mb-2" style={{ fontFamily: 'Playfair Display', color: '#121212BF', fontWeight: 500, fontSize: "24px" }}>{title}</h4>;
// };

// // Reusable List component
// const List = ({ items }) => {
//     return (
//         <ul className="list-unstyled">
//             {items.map((item, index) => (
//                 <li key={index} className="mb-2" style={{ fontFamily: 'Ubuntu', color: '#121212BF', fontSize: '16px', cursor: 'pointer' }}>
//                     {item}
//                 </li>
//             ))}
//         </ul>
//     );
// };

// // Footer Component
// const Footer = () => {
//     // Dynamic image data
//     const logo = 'https://shilohbridal.pmpframe.com/api/upload/logo.svg';
//     const socialImages = [
//         {
//             src: 'https://shilohbridal.pmpframe.com/api/upload/facebook.svg',
//             alt: 'Facebook',
//             href: 'https://www.facebook.com/' // Add actual link here
//         },
//         {
//             src: 'https://shilohbridal.pmpframe.com/api/upload/instagram.svg',
//             alt: 'Instagram',
//             href: 'https://www.instagram.com/' // Add actual link here
//         },
//         {
//             src: 'https://shilohbridal.pmpframe.com/api/upload/whatsapp.svg',
//             alt: 'WhatsApp',
//             href: 'https://wa.me/18001234567' // Add actual link here
//         }
//     ];

//     return (
//         <div className="container py-5">
//             <div className="mb-4">
//                 <img src={logo} className="mb-3" alt="footer-img" style={{ width: '100px',  }} />
//             </div>
//             <div className="row">
//                 <div className="col-md-3 col-12 mb-4">
//                     <SectionHeader title="About Us" />
//                     <List
//                         items={[
//                             'Blogs',
//                             'Collection',
//                             'Site Map',
//                             'Book',
//                             'Appointment',
//                         ]}
//                     />
//                 </div>

//                 <div className="col-md-3 col-12 mb-4">
//                     <SectionHeader title="Terms & Policies" />
//                     <List
//                         items={[
//                             'Terms & Conditions',
//                             'Cookie Policy',
//                             'Return Policy',
//                             'Exchange Policy',
//                             'Refund Policy',
//                         ]}
//                     />
//                 </div>

//                 <div className="col-md-3 col-12 mb-4">
//                     <SectionHeader title="Help & Support" />
//                     <List items={['Order Tracking', "FAQ's"]} />
//                 </div>

//                 <div className="col-md-3 col-12 mb-4">
//                     <SectionHeader title="Follow Us On" />
//                     <div className="d-flex gap-2 mb-3">
//                         {socialImages.map((social, index) => (
//                             <a key={index} href={social.href} target="_blank" rel="noopener noreferrer">
//                                 <img src={social.src} className="iconimages" alt={social.alt} style={{ width: '29px' }} />
//                             </a>
//                         ))}
//                     </div>
//                     <SectionHeader title="Contact Us" />
//                     <List items={[
//                         <span><FaPhone className="me-2 text-danger" /> 1800 123 4567</span>,
//                         <span><FaEnvelope className="me-2 text-danger" /> shilohbridals@gmail.com</span>
//                     ]} />
//                 </div>
//             </div>
//         </div>
//     );
// };

import React from 'react';
import { FaPhone, FaEnvelope } from 'react-icons/fa';
import facebook from "../../assets/icons/facebook.svg";
import instagram from "../../assets/icons/instagram.svg";
import whatsapp from "../../assets/icons/whatsapp.svg"

// Reusable SectionHeader component
const SectionHeader = ({ title }) => {
    return <h4 className="mb-2" style={{ fontFamily: 'Playfair Display', color: '#121212BF', fontWeight: 500, fontSize: "24px" }}>{title}</h4>;
};

// Reusable List component
const List = ({ items }) => {
    return (
        <ul className="list-unstyled">
            {items.map((item, index) => (
                <li key={index} className="mb-2" style={{ 
                    fontFamily: 'Ubuntu', 
                    color: '#121212BF', 
                    fontSize: '16px', 
                    cursor: 'pointer',
                    wordBreak: 'break-word',
                    overflowWrap: 'break-word'
                }}>
                    {item}
                </li>
            ))}
        </ul>
    );
};

// Footer Component
const Footer = () => {
    const logo = 'https://shilohbridal.pmpframe.com/api/upload/logo.svg';
    // const socialImages = [
    //     {
    //         src: 'https://shilohbridal.pmpframe.com/api/upload/facebook.svg',
    //         alt: 'Facebook',
    //         href: 'https://www.facebook.com/'
    //     },
    //     {
    //         src: 'https://shilohbridal.pmpframe.com/api/upload/instagram.svg',
    //         alt: 'Instagram',
    //         href: 'https://www.instagram.com/'
    //     },
    //     {
    //         src: 'https://shilohbridal.pmpframe.com/api/upload/whatsapp.svg',
    //         alt: 'WhatsApp',
    //         href: 'https://wa.me/18001234567'
    //     }
    // ];
    const socialImages = [
        {
            src: facebook,
            alt: 'Facebook',
            href: 'https://www.facebook.com/'
        },
        {
            src: instagram,
            alt: 'Instagram',
            href: 'https://www.instagram.com/'
        },
        {
            src: whatsapp,
            alt: 'WhatsApp',
            href: 'https://wa.me/18001234567'
        }
    ];

    return (
        <div className="container py-5">
            <div className="mb-4">
                <img src={logo} className="mb-3" alt="footer-img" style={{ width: '100px' }} />
            </div>
            
            {/* Desktop view (above 770px) */}
            <div className="row d-none d-md-flex">
                <div className="col-md-3 col-12 mb-4">
                    <SectionHeader title="About Us" />
                    <List
                        items={[
                            'Blogs',
                            'Collection',
                            'Site Map',
                            'Book',
                            'Appointment',
                        ]}
                    />
                </div>

                <div className="col-md-3 col-12 mb-4">
                    <SectionHeader title="Terms & Policies" />
                    <List
                        items={[
                            'Terms & Conditions',
                            'Cookie Policy',
                            'Return Policy',
                            'Exchange Policy',
                            'Refund Policy',
                        ]}
                    />
                </div>

                <div className="col-md-3 col-12 mb-4">
                    <SectionHeader title="Help & Support" />
                    <List items={['Order Tracking', "FAQ's"]} />
                </div>

                <div className="col-md-3 col-12 mb-4">
                    <SectionHeader title="Follow Us On" />
                    <div className="d-flex gap-2 mb-3">
                        {socialImages.map((social, index) => (
                            <a key={index} href={social.href} target="_blank" rel="noopener noreferrer">
                                <img src={social.src} className="iconimages" alt={social.alt} style={{ width: '29px' }} />
                            </a>
                        ))}
                    </div>
                    <SectionHeader title="Contact Us" />
                    <List items={[
                        <span key="phone" style={{ display: 'flex', alignItems: 'center' }}><FaPhone className="me-2 text-danger" /> 1800 123 4567</span>,
                        <span key="email" style={{ 
                            display: 'flex', 
                            alignItems: 'center',
                            wordBreak: 'break-word',
                            overflowWrap: 'break-word'
                        }}>
                            <FaEnvelope className="me-2 text-danger" style={{ flexShrink: 0 }} /> 
                            <span style={{ wordBreak: 'break-word' }}>shilohbridals@gmail.com</span>
                        </span>
                    ]} />
                </div>
            </div>

            {/* Mobile view (770px to 300px) */}
            <div className="d-md-none">
                {/* Row 1 */}
                <div className="row">
                    <div className="col-6 mb-4">
                        <SectionHeader title="About Us" />
                        <List
                            items={[
                                'Blogs',
                                'Collection',
                                'Site Map',
                                'Book',
                                'Appointment',
                            ]}
                        />
                    </div>
                    <div className="col-6 mb-4">
                        <SectionHeader title="Terms & Policies" />
                        <List
                            items={[
                                'Terms & Conditions',
                                'Cookie Policy',
                                'Return Policy',
                                'Exchange Policy',
                                'Refund Policy',
                            ]}
                        />
                    </div>
                </div>

                {/* Row 2 */}
                <div className="row">
                    <div className="col-6 mb-4">
                        <SectionHeader title="Help & Support" />
                        <List items={['Order Tracking', "FAQ's"]} />
                    </div>
                    <div className="col-6 mb-4">
                        <SectionHeader title="Follow Us On" />
                        <div className="d-flex gap-2 mb-3">
                            {socialImages.map((social, index) => (
                                <a key={index} href={social.href} target="_blank" rel="noopener noreferrer">
                                    <img src={social.src} className="iconimages" alt={social.alt} style={{ width: '29px' }} />
                                </a>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Row 3 - Contact Us in its own row */}
                <div className="row">
                    <div className="col-12 mb-4">
                        <SectionHeader title="Contact Us" />
                        <List items={[
                            <span key="phone" style={{ 
                                display: 'flex', 
                                alignItems: 'center',
                                justifyContent: 'flex-start'
                            }}>
                                <FaPhone className="me-2 text-danger" /> 
                                1800 123 4567
                            </span>,
                            <span key="email" style={{ 
                                display: 'flex', 
                                alignItems: 'flex-start',
                                fontSize: '18px',
                                wordBreak: 'break-word',
                                overflowWrap: 'break-word',
                                justifyContent: 'flex-start'
                            }}>
                                <FaEnvelope className="me-2 text-danger" style={{ flexShrink: 0, marginTop: '4px' }} /> 
                                <span style={{ wordBreak: 'break-word' }}>shilohbridals@gmail.com</span>
                            </span>
                        ]} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;

