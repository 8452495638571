import React, { useState, useEffect } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import NavHeading from '../Navheading/NavHeading';
import NavBar from '../Navbar/Navbar';

export default function PersonalDetails() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [loading, setLoading] = useState(true);

    // Fetch user details on component mount
    useEffect(() => {
        const userId = localStorage.getItem('user_id');
        if (userId) {
            const formData = new FormData();
            formData.append('action', 'user_details');
            formData.append('userid', userId);

            fetch(`https://shilohbridal.pmpframe.com/api/user.php`, {
                method: 'POST',
                body: formData,
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success && data.message.length > 0) {
                        const { first_name, last_name, email, mobilenumber } = data.message[0];
                        // Populate state with fetched data
                        setFirstName(first_name);
                        setLastName(last_name);
                        setEmail(email);
                        setMobileNumber(mobilenumber);
                    } else {
                        console.warn("User details not found:", data.message);
                    }
                })
                .catch(error => {
                    console.error("Error fetching user details:", error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, []);

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        const userId = localStorage.getItem('user_id');
        const formData = new FormData();
        formData.append('action', 'edit');
        formData.append('userid', userId);
        formData.append('first_name', firstName);
        formData.append('last_name', lastName);
        formData.append('email', email);
        formData.append('mobile_number', mobileNumber);

        fetch(`https://shilohbridal.pmpframe.com/api/user.php`, {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    alert("Details updated successfully!");
                } else {
                    alert("Error updating details: " + data.message);
                }
            })
            .catch(error => {
                console.error("Error updating details:", error);
            });
    };

    if (loading) {
        return <div>Loading...</div>; // Show a loading message while fetching data
    }

    return (
        <Row className="g-4 px-5">
        <Col md={12} className="d-flex justify-content-between align-items-center">
            <h2 style={{ color: "#121212BF" }}>Personal Details</h2>
        </Col>

        {/* Card for Editing Personal Details */}
        <Col md={12}>
            <Card className="d-flex p-4 px-5 shadow-sm">
                <div className="d-flex justify-content-between w-100 align-items-center py-3">
                    <h4 style={{ color: "#121212BF" }}>Edit Details</h4>
                </div>
                <Form onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <Col md={6}>
                            <Form.Group controlId="formFirstName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter first name"
                                    value={firstName} // Controlled input
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="formLastName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter last name"
                                    value={lastName} // Controlled input
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={6}>
                            <Form.Group controlId="formEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter email"
                                    value={email} // Controlled input
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="formMobileNumber">
                                <Form.Label>Mobile Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter mobile number"
                                    value={mobileNumber} // Controlled input
                                    onChange={(e) => setMobileNumber(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className="d-flex justify-content-center">
                        <Button
                            type="submit" // Set button type to submit
                            variant="outline px-5 py-2"
                            style={{ backgroundColor: "#ffffff", border: "2px solid #8E1336", color: "#8E1336", fontSize: "15px" }}
                        >
                            Save
                        </Button>
                    </div>
                </Form>
            </Card>
        </Col>
    </Row>
        // <div>
           
        //     <Container fluid className="dashboard-container">
        //         <h1 className="py-4" style={{ textAlign: "center", color: "#121212BF" }}>My Account</h1>
        //         <Row className="dashboard">
        //             <Col md={2}>
        //                 <Sidebar />
        //             </Col>
        //             <Col md={10}>
                     
        //             </Col>
        //         </Row>
        //     </Container>
        // </div>
    );
}
