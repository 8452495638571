export const Dropdown = ({ children, label, value, onChange,error }) => {
  return (
    <div
      className="btn-group d-flex flex-column gap-1"
      style={{ width: "100%", flex: 1 }}
    >
      {label && <label htmlFor="size-select">{label} </label>}
      <select
        id="size-select"
        value={value}
        onChange={onChange}
        style={{
          // border:"1px solid gray",
          // outline:"1px solid gray",
          minWidth: "100%",
          borderRadius: "4px",
          marginTop: "1px",
          // marginLeft: "12px",
          padding: "5px 0",
        }}
      >
        {children}
      </select>
      {error && <p style={{ color: "red", marginBottom: "0" }}>{error}</p>}
    </div>
  );
};

export const CheckBox = ({ checked, label, value, onChange }) => {
  return (
    <div className="btn-group" style={{ width: "100%", flex: 1 }}>
      {label && <label htmlFor="size-select">{label} </label>}
      <input
        type="checkbox"
        checked={checked}
        value={value}
        onChange={onChange}
        style={{ cursor: "pointer", height: "15px", width: "15px" }}
      />
    </div>
  );
};

export const InputBox = ({
  type = "text",
  label,
  value,
  onChange,
  error,
  placeholder,
  width,
  leftIcon,
  disabled
}) => {
  return (
    <div
      className="btn-group d-flex flex-column gap-1"
      style={{ maxWidth: width ? width : "100%", flex: 1 }}
    >
      {label && (
        <label htmlFor="size-select" className="fs-18px">
          {label}{" "}
        </label>
      )}

      <div
        style={{
          position: "relative",
          width: "100%",
        }}
      >
        <input
        disabled={disabled}
          placeholder={placeholder}
          type={type}
          value={value}
          onChange={onChange}
          style={{
            width: "100%",
            borderRadius: "4px",
            marginTop: "1px",
            padding: "5px 10px",
            border: `1px solid ${error ? "red" : "gray"}`,
          }}
        />
        {leftIcon && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              right: "15px",
              cursor: "pointer",
            }}
          >
            {leftIcon}
          </div>
        )}
      </div>

      {error && <p style={{ color: "red", marginBottom: "0" }}>{error}</p>}
    </div>
  );
};
