import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox } from "antd";
import { CheckCheck } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import store from "../../Redux/store";
import {
  get_filter_data,
  get_product_list,
} from "../../Redux/Slice/ProductSlice";

const s1 = "https://shilohbridal.pmpframe.com/api/upload/Silhouette/s1.svg";
const s2 = "https://shilohbridal.pmpframe.com/api/upload/Silhouette/s2.svg";
const s3 = "https://shilohbridal.pmpframe.com/api/upload/Silhouette/s3.svg";
const s4 = "https://shilohbridal.pmpframe.com/api/upload/Silhouette/s4.svg";
const s5 = "https://shilohbridal.pmpframe.com/api/upload/Silhouette/s5.svg";
const s6 = "https://shilohbridal.pmpframe.com/api/upload/Silhouette/s6.svg";

// Train Images
const t1 = "https://shilohbridal.pmpframe.com/api/upload/Train/t1.svg";
const t2 = "https://shilohbridal.pmpframe.com/api/upload/Train/t2.svg";
const t3 = "https://shilohbridal.pmpframe.com/api/upload/Train/t3.svg";
const t4 = "https://shilohbridal.pmpframe.com/api/upload/Train/t4.svg";
const t5 = "https://shilohbridal.pmpframe.com/api/upload/Train/t5.svg";
const t6 = "https://shilohbridal.pmpframe.com/api/upload/Train/t6.svg";
const t7 = "https://shilohbridal.pmpframe.com/api/upload/Train/t7.svg";
const t8 = "https://shilohbridal.pmpframe.com/api/upload/Train/t8.svg";

export const Filter = ({
  handleMenuToggle,
  openMenus,
  obj,
  filterData,
  setFilterData,
}) => {
  

  const handleCheckboxChange = (e, categoryIndex, itemIndex) => {
    const { checked } = e.target;

    setFilterData((prevData) =>
      prevData.map((category, catIdx) =>
        catIdx === categoryIndex
          ? {
              ...category,
              data: category.data.map((item, itemIdx) =>
                itemIdx === itemIndex ? { ...item, checked } : item
              ),
            }
          : category
      )
    );
  };
  return (
    <div className="category-menu">
      <h2
        className="p-2"
        style={{
          fontSize: "40px",
          fontFamily: "playfair display",
          fontWeight: "500",
          color: "#121212BF",
        }}
      >
        Filter
      </h2>
      <ul className="categories">
        {filterData?.map((v, i) => (
          <li
            key={i}
            className="list-group-item p-3"
            style={{ cursor: "pointer" }}
          >
            <span
              onClick={() => handleMenuToggle(i)}
              className="d-flex align-items-center justify-content-between"
              style={{
                fontSize: "26px",
                fontFamily: "Ubuntu",
                fontWeight: "400",
                color: "#121212BF",
              }}
            >
              {v?.label}
              <FontAwesomeIcon
                icon={faAngleDown}
                className={`dropdown-icon ${openMenus[i] ? "rotate" : ""}`}
              />
            </span>

            {openMenus[i] && (
              <ul
                className="dropdown-items mt-2"
                style={{
                  listStyle: "none",
                  padding: "10px",
                  border: "1px solid #ddd",
                  borderRadius: "5px",
                  backgroundColor: "#fff",
                }}
              >
                {v?.data?.map((val, ind) => (
                  <FilterList
                    value={val.checked}
                    onChange={(e) => handleCheckboxChange(e, i, ind)}
                    key={ind}
                    label={val?.label}
                  />
                ))}
              </ul>
            )}
          </li>
        ))}

        {/* <li
          className="list-group-item p-3"
          onClick={() => handleMenuToggle(1)}
          style={{ cursor: "pointer" }} // Makes the list item more visually distinct
        >
          <span
            className="d-flex align-items-center justify-content-between"
            style={{
              fontSize: "26px",
              fontFamily: "Ubuntu",
              fontWeight: "400",
              color: "#121212BF",
            }}
          >
            Size
            <FontAwesomeIcon
              icon={faAngleDown}
              className={`dropdown-icon ${openMenus[1] ? "rotate" : ""}`}
            />
          </span>

          {openMenus[1] && (
            <ul
              className="dropdown-items mt-2"
              style={{
                listStyle: "none",
                padding: "10px",
                border: "1px solid #ddd",
                borderRadius: "5px",
                backgroundColor: "#fff",
              }}
            >
              <li>
                <a
                  href="/"
                  style={{
                    display: "block",
                    padding: "8px",
                    color: "#121212BF",
                  }}
                >
                  Small
                </a>
              </li>
              <li>
                <a
                  href="/"
                  style={{
                    display: "block",
                    padding: "8px",
                    color: "#121212BF",
                  }}
                >
                  Medium
                </a>
              </li>
              <li>
                <a
                  href="/"
                  style={{
                    display: "block",
                    padding: "8px",
                    color: "#121212BF",
                  }}
                >
                  Large
                </a>
              </li>
              <li>
                <a
                  href="/"
                  style={{
                    display: "block",
                    padding: "8px",
                    color: "#121212BF",
                  }}
                >
                  X-Large
                </a>
              </li>
            </ul>
          )}
        </li> */}

        {/* Price Filter */}
        {/* <li
          className="list-group-item p-3"
          onClick={() => handleMenuToggle(2)}
          style={{ cursor: "pointer" }} // Makes the list item more visually distinct
        >
          <span
            className="d-flex align-items-center justify-content-between"
            style={{
              fontSize: "26px",
              fontFamily: "Ubuntu",
              fontWeight: "400",
              color: "#121212BF",
            }}
          >
            Price
            <FontAwesomeIcon
              icon={faAngleDown}
              className={`dropdown-icon ${openMenus[2] ? "rotate" : ""}`}
              style={{ marginLeft: "4px" }}
            />
          </span>

          {openMenus[2] && (
            <ul
              className="dropdown-items mt-2"
              style={{
                listStyle: "none",
                padding: "10px",
                border: "1px solid #ddd",
                borderRadius: "5px",
                backgroundColor: "#fff",
              }}
            >
              <li>
                <a
                  href="/"
                  style={{
                    display: "block",
                    padding: "8px",
                    color: "#121212BF",
                  }}
                >
                  Under Rs.2000
                </a>
              </li>
              <li>
                <a
                  href="/"
                  style={{
                    display: "block",
                    padding: "8px",
                    color: "#121212BF",
                  }}
                >
                  Rs.2000 - Rs.4000
                </a>
              </li>
              <li>
                <a
                  href="/"
                  style={{
                    display: "block",
                    padding: "8px",
                    color: "#121212BF",
                  }}
                >
                  Rs.4000 - Rs.6000
                </a>
              </li>
              <li>
                <a
                  href="/"
                  style={{
                    display: "block",
                    padding: "8px",
                    color: "#121212BF",
                  }}
                >
                  Above Rs.6000
                </a>
              </li>
            </ul>
          )}
        </li> */}

        {/* Color Filter */}
        {/* <li
          className="list-group-item p-3"
          onClick={() => handleMenuToggle(3)}
          style={{ cursor: "pointer" }}
        >
          <span
            className="d-flex align-items-center justify-content-between"
            style={{
              fontSize: "26px",
              fontFamily: "Ubuntu",
              fontWeight: "400",
              color: "#121212BF",
            }}
          >
            Color
            <FontAwesomeIcon
              icon={faAngleDown}
              className={`dropdown-icon ${openMenus[3] ? "rotate" : ""}`}
              style={{ marginLeft: "4px" }}
            />
          </span>

          {openMenus[3] && (
            <ul
              className="dropdown-items mt-2"
              style={{
                listStyle: "none",
                padding: "10px",
                border: "1px solid #ddd",
                borderRadius: "5px",
                backgroundColor: "#fff",
              }}
            >
              <li>
                <a
                  href="/"
                  style={{
                    display: "block",
                    padding: "8px",
                    color: "#121212BF",
                  }}
                >
                  Black
                </a>
              </li>
              <li>
                <a
                  href="/"
                  style={{
                    display: "block",
                    padding: "8px",
                    color: "#121212BF",
                  }}
                >
                  Red
                </a>
              </li>
              <li>
                <a
                  href="/"
                  style={{
                    display: "block",
                    padding: "8px",
                    color: "#121212BF",
                  }}
                >
                  Teal
                </a>
              </li>
              <li>
                <a
                  href="/"
                  style={{
                    display: "block",
                    padding: "8px",
                    color: "#121212BF",
                  }}
                >
                  White
                </a>
              </li>
            </ul>
          )}
        </li> */}

        {/* Silhouette Filter */}
        <li
          className="list-group-item p-3"
          onClick={() => handleMenuToggle(4)}
          style={{ cursor: "pointer" }}
        >
          <span
            className="d-flex align-items-center justify-content-between"
            style={{
              fontSize: "26px",
              fontFamily: "Ubuntu",
              fontWeight: "400",
              color: "#121212BF",
            }}
          >
            Silhouette
            <FontAwesomeIcon
              icon={faAngleDown}
              className={`dropdown-icon ${openMenus[4] ? "rotate" : ""}`}
              style={{ marginLeft: "4px" }}
            />
          </span>

          {openMenus[4] && (
            <div
              className="dropdown-items mt-2"
              style={{
                padding: "10px",
                border: "1px solid #ddd",
                borderRadius: "5px",
                backgroundColor: "#fff",
              }}
            >
              <div className="row">
                <div className="col-4">
                  <a href="/">
                    <img src={s1} alt="s1-images" className="img-fluid" />
                  </a>
                </div>
                <div className="col-4">
                  <a href="/">
                    <img src={s2} alt="s2-images" className="img-fluid" />
                  </a>
                </div>
                <div className="col-4">
                  <a href="/">
                    <img src={s3} alt="s3-images" className="img-fluid" />
                  </a>
                </div>
              </div>

              {/* Second row of 3 images */}
              <div className="row mt-3">
                <div className="col-4">
                  <a href="/">
                    <img src={s4} alt="s4-images" className="img-fluid" />
                  </a>
                </div>
                <div className="col-4">
                  <a href="/">
                    <img src={s5} alt="s5-images" className="img-fluid" />
                  </a>
                </div>
                <div className="col-4">
                  <a href="/">
                    <img src={s6} alt="s6-images" className="img-fluid" />
                  </a>
                </div>
              </div>
            </div>
          )}
        </li>

        {/* Fabric Filter */}
        {/* <li
          className="list-group-item p-3"
          onClick={() => handleMenuToggle(5)}
          style={{ cursor: "pointer" }}
        >
          <span
            className="d-flex align-items-center justify-content-between"
            style={{
              fontSize: "26px",
              fontFamily: "Ubuntu",
              fontWeight: "400",
              color: "#121212BF",
            }}
          >
            Fabric
            <FontAwesomeIcon
              icon={faAngleDown}
              className={`dropdown-icon ${openMenus[5] ? "rotate" : ""}`}
              style={{ marginLeft: "4px" }}
            />
          </span>

          {openMenus[5] && (
            <div
              className="dropdown-items mt-2"
              style={{
                padding: "10px",
                border: "1px solid #ddd",
                borderRadius: "5px",
                backgroundColor: "#fff",
              }}
            >
              <ul className="list-unstyled mb-0">
                <li>
                  <a href="/">Silk</a>
                </li>
                <li>
                  <a href="/">Georgette</a>
                </li>
                <li>
                  <a href="/">Net</a>
                </li>
              </ul>
            </div>
          )}
        </li> */}

        {/* Train Filter */}
        <li
          className="list-group-item p-3"
          onClick={() => handleMenuToggle(6)}
          style={{ cursor: "pointer" }}
        >
          <span
            className="d-flex align-items-center justify-content-between"
            style={{
              fontSize: "26px",
              fontFamily: "Ubuntu",
              fontWeight: "400",
              color: "#121212BF",
            }}
          >
            Train
            <FontAwesomeIcon
              icon={faAngleDown}
              className={`dropdown-icon ${openMenus[6] ? "rotate" : ""}`}
              style={{ marginLeft: "4px" }}
            />
          </span>

          {openMenus[6] && (
            <div
              className="dropdown-items mt-2"
              style={{
                padding: "10px",
                border: "1px solid #ddd",
                borderRadius: "5px",
                backgroundColor: "#fff",
              }}
            >
              {/* Row of 4 images */}
              <div className="row">
                <div className="col-3">
                  <a href="/">
                    <img src={t1} alt="t1" className="img-fluid" />
                  </a>
                </div>
                <div className="col-3">
                  <a href="/">
                    <img src={t2} alt="t2" className="img-fluid" />
                  </a>
                </div>
                <div className="col-3">
                  <a href="/">
                    <img src={t3} alt="t3" className="img-fluid" />
                  </a>
                </div>
                <div className="col-3">
                  <a href="/">
                    <img src={t4} alt="t4" className="img-fluid" />
                  </a>
                </div>
              </div>
              {/* Second row of 4 images */}
              <div className="row mt-3">
                <div className="col-3">
                  <a href="/">
                    <img src={t5} alt="t5" className="img-fluid" />
                  </a>
                </div>
                <div className="col-3">
                  <a href="/">
                    <img src={t6} alt="t6" className="img-fluid" />
                  </a>
                </div>
                <div className="col-3">
                  <a href="/">
                    <img src={t7} alt="t7" className="img-fluid" />
                  </a>
                </div>
                <div className="col-3">
                  <a href="/">
                    <img src={t8} alt="t8" className="img-fluid" />
                  </a>
                </div>
              </div>
            </div>
          )}
        </li>
      </ul>
    </div>
  );
};

export const FilterList = ({ label, value, onChange }) => {
  return (
    <li className="d-flex gap-3">
      <Checkbox checked={value} value={value} onChange={onChange}>
        {label}
      </Checkbox>
    </li>
  );
};
