import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { base_url } from "../../utils/data";

export const get_blog_list = createAsyncThunk("blog_data", async (data) => {
  const body = new FormData();
  body.append("action", "listall");
  const response = await fetch(base_url + "admin/blog.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  return res;
});

export const get_testimonial_list = createAsyncThunk(
  "testimonial_data",
  async (data) => {
    const body = new FormData();
    body.append("action", "list");
    const response = await fetch(base_url + "admin/testimonial.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    return res;
  }
);

export const get_category_list = createAsyncThunk(
  "category_list",
  async (data) => {
    const body = new FormData();
    body.append("action", "product_list");
    const response = await fetch(base_url + "admin/product_category.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    return res;
  }
);
export const get_gallary_list = createAsyncThunk(
  "gallary_list",
  async (data) => {
    const body = new FormData();
    body.append("action", "gallery_list");
    const response = await fetch(base_url + "gallery.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    return res;
  }
);

// productSubCategory(category,sub_category)

export const get_shop_by_category = createAsyncThunk(
  "shop_by_category",
  async (data) => {
    const body = new FormData();
    body.append("action", "productSubCategory");
    const response = await fetch(base_url + "product.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    return res;
  }
);

export const get_bridal_collection = createAsyncThunk(
  "bridal_collection",
  async (data) => {
    const body = new FormData();
    body.append("action", "productMainCategory");
    const response = await fetch(base_url + "product.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    return res;
  }
);

export const get_state_list = createAsyncThunk(
  "state_list",
  async (data) => {
    const body = new FormData();
    body.append("action", "states");
    const response = await fetch(base_url + "user.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    return res;
  }
);

const initialState = {
  blog: [],
  testimonial: [],
  category_list: [],
  gallary_list: [],
  wish_list: [],
  shop_by_category: [],
  bridal_collection:[],
  state_list:[]
};

const otherDataSlice = createSlice({
  name: "otherData",
  initialState: initialState,
  extraReducers: (builder) => {
    //  Blog Data
    builder.addCase(get_blog_list.fulfilled, (state, action) => {
      const data = action.payload;
      if (data.success) {
        state.blog = data?.data;
      } else {
        state.blog = [];
      }
    });

    //  Testimonial Data
    builder.addCase(get_testimonial_list.fulfilled, (state, action) => {
      const data = action.payload;
      if (data.success) {
        state.testimonial = data?.data;
      } else {
        state.testimonial = [];
      }
    });

    //  Category  Data
    builder.addCase(get_category_list.fulfilled, (state, action) => {
      const data = action.payload;
      if (data.success) {
        state.category_list = data?.categories;
      } else {
        state.category_list = [];
      }
    });

    //  Gallary list
    builder.addCase(get_gallary_list.fulfilled, (state, action) => {
      const data = action.payload;
      if (data.success) {
        state.gallary_list = data?.data;
      } else {
        state.gallary_list = [];
      }
    });

    //  Shop by Category Data

    builder.addCase(get_shop_by_category.fulfilled, (state, action) => {
      const data = action.payload;
      if (data.success) {
        state.shop_by_category = data?.data;
      } else {
        state.shop_by_category = [];
      }
    });
    builder.addCase(get_bridal_collection.fulfilled, (state, action) => {
      const data = action.payload;
      if (data.success) {
        state.bridal_collection = data?.data;
      } else {
        state.bridal_collection = [];
      }
    });

    builder.addCase(get_state_list.fulfilled, (state, action) => {
      const data = action.payload;
      if (data.success) {
        state.state_list = data?.data;
      } else {
        state.state_list = [];
      }
    });
  },
});

export default otherDataSlice.reducer;
