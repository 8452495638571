import React from "react";
import NavBar from "../components/Navbar/Navbar";
import NavHeading from "../components/Navheading/NavHeading";
// import Hero from '../components/Hero/Hero';
import Sidebar from "../components/Sidebar/Sidebar";
import Dashboard from "../components/Dashboard/Dashboard";
import { Container, Row, Col } from "react-bootstrap";

export default function Account() {
  return <Dashboard />;
}
