import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { base_url } from "../utils/data";
import moment from "moment/moment";

const BlogDetails = () => {
  const [blogData, setBlogData] = useState({});
  const { state } = useLocation();
  console.log("state ===", state);
  useEffect(() => {
    if (state) {
      fetchBlog();
    }
  }, [state]);

  const fetchBlog = async () => {
    const body = new FormData();
    body.append("action", "list");
    body.append("id", state?.id);
    const response = await fetch(base_url + "admin/blog.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    if (res?.success) {
      setBlogData(res?.data);
    } else {
      setBlogData({});
    }
  };

  console.log("blogData ===", blogData);

  return (
    <div className="container-fluid my-5 px-5 d-flex flex-column justify-content-center align-items-center">
      <div className="card h-100 border-0 d-flex flex-column justify-content-start align-items-start m-auto">
        <img
          src={blogData?.image}
          className="card-img-top"
          alt={`Blog ${blogData?.id}`}
          style={{
            width: "1100px",
            maxWidth: "100%",
            height: "auto",
            maxHeight: "800px",

            objectFit: "cover",
          }}
        />
        <div className="w-100 card-body py-3 px-0 d-flex flex-column  justify-content-start align-items-start">
          <p
            className="card-text fw-bold mt-4"
            style={{
              fontSize: "25px",
              fontFamily: "Ubuntu",
              color: "#121212BF",
            }}
          >
            {blogData?.title}
          </p>
          <p
            className="card-date mb-2"
            style={{
              fontSize: "18px",
              fontFamily: "Ubuntu",
              color: "#121212BF",
            }}
          >
            {moment(blogData?.date).format("MMM  DD,  YYYY")}
          </p>
          <p
            className="card-text  "
            style={{
              fontSize: "15px",
              fontFamily: "Ubuntu",
              color: "#121212BF",
            }}
            dangerouslySetInnerHTML={{ __html: blogData?.description }}
          />

          <div
            className="d-flex  flex-column justify-content-start align-items-start 
          gap-5
          "
          >
            <CardData num={1} />
            <CardData num={2} />
            <CardData num={3} />
            <CardData num={4} />
          </div>
        </div>
      </div>
    </div>
  );
};

const CardData = ({
  img = "https://shilohbridal.pmpframe.com/api/upload/Img3.svg",
  title = "Choosing the Perfect Veil",
  num = 1,
  text = "Lorem ipsum dolor sit amet consectetur adipisicing elit, Lorem ipsum dolor sit amet consectetur adipisicing elit, Lorem ipsum dolor sit amet consectetur adipisicing elit",
}) => {
  return (
    <div className="d-flex flex-column gap-4 mt-2">
      <div className="fw-bold mb-3">
        {num}. {title}
      </div>
      <div
        className={`d-flex ${
          num % 2 === 0 ? "flex-row-reverse" : "flex-row"
        } align-items-start gap-5`}
      >
        <img
          src={img}
          className="img-fluid" // Bootstrap class for responsive images
          style={{ maxWidth: "350px", maxHeight: "400px", objectFit: "cover" }} // Custom inline style for max size
          alt="image"
        />
        <div
          className="max-w-100 d-flex flex-column gap-3"
          style={{ maxWidth: "800px" }}
        >
         <span> {text}</span>
         <span> {text}</span>
         <span> {text}</span>
         <span> {text}</span>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
