import React, { useEffect, useState } from "react";
import SizeGuide from "../SizeGuide/SizeGuide";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as regularHeart } from "@fortawesome/free-regular-svg-icons";
import QtyInput from "../Quantity/Quantity";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { base_url } from "../../utils/data";
import { Tooltip } from "antd";
import Zoom from "react-img-zoom";
import { IoIosSearch } from "react-icons/io";

import {
  ExpendebleComponent,
  KeyValueList,
  MesurmentFunction,
  OptionComponent,
  ProductCardSub,
  StarRating,
  SwiperSlider,
} from "../../ChildComponent/OtherComponents";
import { Dropdown, InputBox } from "../../ChildComponent/Input/Inputs";
import { useSelector } from "react-redux";
import store from "../../Redux/store";
import {
  get_product,
  get_wish_list,
  update_wish_list,
} from "../../Redux/Slice/ProductSlice";
import { toast } from "react-toastify";
import { addToWishlist, handleSubmitMesurment, ReduceFunction } from "../../utils/Hook/OtherFunction";
import { CustomModal } from "../../ChildComponent/Modal/Modal";
import { Carousel } from "react-bootstrap";
import { SwiperSlide } from "swiper/react";
import { Button } from "../../ChildComponent/Button/Button";
import { FaSearch } from "react-icons/fa";

const custom =
  "https://shilohbridal.pmpframe.com/api/upload/custom_measurment/custom measurements.svg";

export default function ProductDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [addData, setAddData] = useState({});
  const [measurements, setMeasurements] = useState({});
  const [mainImage, setMainImage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [addingToCart, setAddingToCart] = useState(false);
  const userId = localStorage.getItem("user_id");
  const {
    wish_list,
    cart_item,
    product,
    product_loading,
    related_product_list,
  } = useSelector((state) => state.productData);

  console.log("product =====", product);
  // console.log("cart_item =====", cart_item);
  console.log("addData =====", addData);
  // console.log("related_product_list =====", related_product_list);

  useEffect(() => {
    store.dispatch(get_product({ id, userId })).then((data) => {
      const newData = data.payload?.data;
      setMainImage(!mainImage ? newData?.image : mainImage);
      const filterData = newData?.customizations?.filter(
        (v, i) => v?.customization_category == 8
      );
      // setSize(newData);
      console.log("newData ===", data.payload?.cart_item);
      const cartData = data.payload?.cart_item;

      if (cartData) {
        setAddData({
          basicPriceRow: cartData?.price,
          basicPrice: cartData?.price,
          basicDiscount: cartData?.discount,
          afterDiscountPrice: cartData?.price - cartData?.discount,
          color_name: cartData?.color,
          color_id: cartData?.color_id,
          count: cartData?.count,
          cust: cartData?.customization,
        });
        setMeasurements({ ...cartData });
      } else {
        setAddData({
          basicPriceRow: newData?.price,
          basicPrice: newData?.price,
          basicDiscount: newData?.discount,
          afterDiscountPrice: newData?.price - newData?.discount,
          color_name: newData?.color,
          color_id: "default",
          count: 1,
          cust: filterData?.length > 0 ? [filterData?.[0]] : [],
        });
      }

      // setAddData({
      //   basicPriceRow: newData?.price,
      //   basicPrice: newData?.price,
      //   basicDiscount: newData?.discount,
      //   afterDiscountPrice: newData?.price - newData?.discount,
      //   color_name: newData?.color,
      //   color_id: "default",
      //   count: 1,
      //   cust: filterData?.length > 0 ? [filterData?.[0]] : [],
      // });
    });
  }, [id]);

  useEffect(() => {
    store.dispatch(get_wish_list(userId));
  }, [userId]);

  const handleImageClick = (imageSrc) => setMainImage(imageSrc);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  // const handleFormChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };

  const handleSubmit = async () => {
    if (addingToCart) return;
    const userId = localStorage.getItem("user_id");
    if (!userId) {
      alert("Please log in to add items to your cart.");
      navigate("/Signin");
      return;
    }
    const measurementData = handleSubmitMesurment(measurements);

    if (Object.keys(measurementData).length > 0) {
      setIsModalOpen1(true);
      alert("Please fill in all custom measurements.");
      return;
    }
    try {
      setAddingToCart(true);
      const formDataObj = new FormData();
      formDataObj.append("action", "cart_add");
      formDataObj.append("user_id", userId);
      formDataObj.append("itemcode", product.itemcode);
      // formDataObj.append("size", size);
      formDataObj.append("count", addData?.count);
      formDataObj.append("color_id", addData?.color_id);
      formDataObj.append("color", addData?.color_name);
      formDataObj.append("count", addData?.count);

      if (addData?.cust?.length > 0) {
        addData?.cust?.forEach((item, i) => {
          formDataObj.append(`customization_id[${i}]`, item?.id);
        });
      }

      formDataObj.append("neck_girth", measurements.neck_girth);
      formDataObj.append("upper_bust", measurements.upper_bust);
      formDataObj.append("medium_bust", measurements.medium_bust);
      formDataObj.append("lower_bust", measurements.lower_bust);
      formDataObj.append("waist", measurements.Waist);
      formDataObj.append("hips", measurements.Hips);
      formDataObj.append("height", measurements.Height);
      formDataObj.append("waist_to_floor", measurements.waist_to_floor);
      formDataObj.append("waist_to_knee", measurements.waist_to_knee);
      formDataObj.append("shoulder_span", measurements.shoulder_span);
      formDataObj.append("bust_height", measurements.bust_height);
      formDataObj.append("front_corset", measurements.front_corset);
      formDataObj.append("back_corset", measurements.back_corset);
      formDataObj.append("corset_side", measurements.corset_side);
      formDataObj.append("arm_girth", measurements.arm_girth);
      formDataObj.append("sleeve_length", measurements.sleeve_length);
      formDataObj.append("wrist_girth", measurements.wrist_girth);

      const response = await fetch(base_url + "cart.php", {
        method: "POST",
        body: formDataObj,
      });

      const result = await response.json();
      if (result.success) {
        toast.success(result.message);
        navigate("/cart/mycart");
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.error("Error adding item to cart:", error);
      alert("Failed to add item to cart. Please try again.");
    } finally {
      setAddingToCart(false);
    }
  };

  if (product_loading) return <div>Loading...</div>;
  if (!product) return <div>Product not found</div>;

  const isVideo = (url) => {
    const videoExtensions = ["mp4", "webm", "ogg"]; // List of video file extensions
    const extension = url?.split(".").pop().toLowerCase();
    return videoExtensions.includes(extension);
  };

  const TrainChange = (item) => {
    const filterData = addData?.cust?.filter(
      (v, i) => v?.customization_category != item?.customization_category
    );
    setAddData({
      ...addData,
      cust: [...filterData, item],
    });
  };

  const CustChange = (e) => {
    try {
      if (typeof JSON.parse(e?.target?.value) == "object") {
        const data = JSON.parse(e.target.value);
        const filterData = addData?.cust?.filter(
          (v, i) => v?.customization_category != data?.id
        );
        setAddData({
          ...addData,
          cust: filterData,
        });
      } else {
        const filtData = product?.customizations?.filter(
          (item) => item.id == e.target.value
        )[0];
        if (filtData) {
          const filterData = addData?.cust?.filter(
            (v, i) =>
              v?.customization_category != filtData?.customization_category
          );
          setAddData({
            ...addData,
            cust: [...filterData, filtData],
          });
        }
      }
    } catch (error) {
      console.log("error ===", error);
    }
  };

  const handleChange = (key, value, max = 1) => {
    const regex = new RegExp(`^(0|[1-9]\\d{0,${max}})(\\.\\d{0,2})?$`);
    setMeasurements((prev) => ({
      ...prev,
      [key]: regex.test(value) || value === "" ? value : prev[key],
      [`${key}_error`]: "",
    }));
  };

  const handleSubmitMesur = (errors) => {
    setMeasurements({ ...measurements, ...errors });
    if (Object.keys(errors).length === 0) {
      setIsModalOpen1(false);
    } else if (Object.keys(errors).length > 0) {
      setIsModalOpen1(true);
    }
    return errors;
  };

  const HandleFindPincodeData = async () => {
    try {
      const obj = {
        action: "pincode",
        pincode: addData?.pincode,
      };

      const response = await fetch(base_url + "orders/shipment_charges.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      });

      const result = await response.json();
      console.log("result ===", result);

      if (result.success) {
        toast.success(result.message);
        setAddData({ ...addData, delivery_date: result?.data?.date });
        // navigate("/cart/mycart");
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <>
      <div className="container-fluid my-5 p-5">
        {Object.keys(product).length > 0 ? (
          <div className="row">
            <div className="col-md-5">
              <div className="row">
                <div className="col-3">
                  <img
                    src={product?.image}
                    alt="preview"
                    onClick={() => handleImageClick(product?.image)}
                    className="img-fluid mb-2 rounded"
                    style={{
                      height: "100px",
                      width: "100%",
                      objectFit: "contain",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                  />
                  {product?.images?.map((v, i) => (
                    <img
                      key={i}
                      src={v?.image}
                      alt="preview"
                      onClick={() => handleImageClick(v?.image)}
                      className="img-fluid mb-2 rounded"
                      style={{
                        height: "100px",
                        width: "100%",
                        objectFit: "contain",
                        borderRadius: "10px",
                        cursor: "pointer",
                      }}
                    />
                  ))}

                  {/* Video Thumbnail */}
                  {product?.video && (
                    <div
                      onClick={() => handleImageClick(product?.video)}
                      className="position-relative"
                      style={{ cursor: "pointer" }}
                    >
                      <video
                        src={product?.video}
                        className="img-fluid mb-2 rounded"
                        style={{
                          height: "100px",
                          width: "100%",
                          objectFit: "cover",
                        }}
                        muted
                        playsInline
                        preload="metadata"
                      >
                        {/* Fallback Thumbnail */}
                        <img
                          src="path/to/fallback-thumbnail.jpg"
                          alt="Video Thumbnail"
                          className="img-fluid mb-2 rounded"
                          style={{
                            height: "100px",
                            width: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </video>
                      <span
                        className="position-absolute top-50 start-50 translate-middle"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "25px",
                          width: "25px",
                          fontSize: "18px",
                          color: "#fff",
                          backgroundColor: "rgba(0, 0, 0, 0.6)",
                          borderRadius: "50%",
                        }}
                      >
                        ▶
                      </span>
                    </div>
                  )}
                </div>

                <div className="col-9 d-flex justify-content-center align-items-center">
                  {/* <img className="img-fluid w-100" src={mainImage} alt="Main" /> */}
                  <div className="main-media">
                    {isVideo(mainImage) ? (
                      <video
                        className="img-fluid w-100"
                        src={mainImage}
                        controls={false}
                        autoPlay
                        style={{
                          objectFit: "cover",
                          borderRadius: "5px",
                        }}
                      />
                    ) : (
                      <img
                        className="img-fluid w-100"
                        src={mainImage}
                        alt="Main"
                        style={{
                          objectFit: "cover",
                          borderRadius: "5px",
                        }}
                      />
                    )}
                  </div>
                  {/* <Zoom
              img={mainImage}
              className="img-fluid w-100"
              zoomScale={2}
              width={400}
              height={500}
              alt="shiloh bridals"
            /> */}
                </div>
              </div>
            </div>

            <div className="col-md-7">
              <h4
                className="p-2"
                style={{
                  fontSize: "32px",
                  fontFamily: "playfair display",
                  fontWeight: "500",
                  color: "#121212BF",
                }}
              >
                {product.name} {product.nameitem1} {product.nameitem2}
              </h4>
              <div
                className="p-2"
                style={{
                  fontSize: "20px",
                  fontFamily: "ubuntu",
                  color: "#121212BF",
                  fontWeight: "400",
                }}
              >
                #{product.itemcode}
              </div>
              <StarRating rating={4.5} count={20} />
              <div
                className="my-2 p-2"
                style={{
                  fontSize: "20px",
                  fontFamily: "ubuntu",
                  fontWeight: "500",
                  color: "#121212Bf",
                }}
              >
                <strike
                  className="text-muted"
                  style={{
                    fontSize: "16px",
                    fontFamily: "ubuntu",
                    fontWeight: "400",
                    color: "#888888",
                  }}
                >
                  RS.{" "}
                  {Number(addData?.afterDiscountPrice) +
                    Number(
                      ReduceFunction(addData?.cust, "customization_price")
                    )}
                </strike>{" "}
                RS.{" "}
                {Number(addData?.basicPrice) +
                  Number(ReduceFunction(addData?.cust, "customization_price"))}
              </div>
              <div
                className="my-2 p-2"
                style={{ fontSize: "24px", color: "#121212Bf" }}
              >
                Color :
                <span
                  className="text mx-1"
                  style={{ fontSize: "20px", color: "#8E1336" }}
                >
                  {addData.color_name}
                </span>
                <div className="d-flex gap-2">
                  <>
                    <Tooltip title={product?.color}>
                      <div
                        onClick={() => {
                          setAddData({
                            ...addData,
                            basicPrice: product?.price * addData?.count,
                            basicPriceRow: product?.price,
                            afterDiscountPrice:
                              product?.price * addData?.count -
                              addData?.basicDiscount * addData?.count,
                            color_name: product?.color,
                            color_id: "default",
                          });
                        }}
                        style={{
                          width: "31px",
                          height: "31px",
                          borderRadius: "50%",
                          backgroundColor:
                            product?.color_code || product?.color,
                          cursor: "pointer",
                          marginTop: "5px",
                          border: "1px solid rgb(143, 19, 54)",
                        }}
                      />
                    </Tooltip>
                  </>

                  {product?.colors?.length > 0 && (
                    <>
                      {[...product?.colors]?.map((val, ind) => (
                        <Tooltip key={ind} title={val?.color_name}>
                          <div
                            onClick={() => {
                              setAddData({
                                ...addData,
                                basicPrice: val?.price * addData?.count,
                                basicPriceRow: val?.price,
                                afterDiscountPrice:
                                  val?.price * addData?.count -
                                  addData?.basicDiscount * addData?.count,
                                color_name: val?.color_name,
                                color_id: val?.id,
                              });
                            }}
                            style={{
                              width: "31px",
                              height: "31px",
                              borderRadius: "50%",
                              backgroundColor: val?.color_code,
                              cursor: "pointer",
                              marginTop: "5px",
                              border: "1px solid rgb(143, 19, 54)",
                            }}
                          />
                        </Tooltip>
                      ))}
                    </>
                  )}
                </div>
              </div>

              <div className="d-flex flex-column my-2 p-2">
                {/* <div className="d-flex align-items-center">
              <i
                className="bi bi-ruler"
                style={{ fontSize: "20px", marginRight: "5px" }}
              ></i>
              <label className="me-2">Size:</label>
              <span
                className="ms-2 text-decoration-underline"
                style={{ cursor: "pointer" }}
                onClick={toggleModal}
              >
                Size Guide
              </span>
            </div> */}
                {/* <div className="mt-2">
              <select
                className="form-select "
                style={{ width: "184px" }}
                value={size}
                onChange={(e) => setSize(e.target.value)}
              >
                <option>Choose Your Size</option>
                <option>US 0 / UK 2 / EU 30</option>
                <option>US 2 / UK 6 / EU 32</option>
                <option>US 4 / UK 8 / EU 34</option>
                <option>US 6 / UK 10 / EU 36</option>
                <option>US 8 / UK 12 / EU 38</option>
                <option>US 10 / UK 14 / EU 40</option>
                <option>US 12 / UK 16 / EU 42</option>
                <option>US 14 / UK 18 / EU 44</option>
                <option>US 16 / UK 20 / EU 46</option>
                <option>US 16W / UK 20 / EU 46</option>
                <option>US 18 / UK 22 / EU 48</option>
                <option>US 20 / UK 24 / EU 50</option>

              </select>
            </div> */}
              </div>

              <div className="d-flex align-items-center my-2 p-2">
                <label className="me-2">Quantity:</label>
                <QtyInput
                  count={addData?.count}
                  onChange={(value) => {
                    setAddData({
                      ...addData,
                      count: value,
                      afterDiscountPrice:
                        addData?.basicPriceRow * value -
                        addData?.basicDiscount * value,
                      basicPrice: addData?.basicPriceRow * value,
                    });
                  }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  flexDirection: "column",
                }}
              >
                <h4>Customization:</h4>
                {[{ lable: "Train", id: 8 }].map((category, ind) => (
                  <>
                    {product?.customizations?.filter(
                      (item) => item.customization_category == category?.id
                    )?.length > 0 && (
                      <>
                        <p style={{ fontSize: "16px" }}> {category.lable}*</p>
                        <div className="d-flex gap-2">
                          {product?.customizations
                            ?.filter(
                              (item) =>
                                item.customization_category == category?.id
                            )
                            .map((item, i) => (
                              <OptionComponent
                                key={i}
                                item={item}
                                isSelected={
                                  addData?.cust?.filter(
                                    (v, i) =>
                                      (v?.id == item.id ||
                                        v?.customization_id == item.id) &&
                                      v?.customization_category == category.id
                                  )?.length > 0
                                    ? true
                                    : false
                                }
                                CustChange={TrainChange}
                              />
                            ))}
                        </div>
                      </>
                    )}
                  </>
                ))}
                <div className="grid-container">
                  {[
                    { lable: "Sleeve", id: 1 },
                    { lable: "Neckline", id: 2 },
                    { lable: "Patticoat", id: 3 },
                    { lable: "Back Style", id: 4 },
                    { lable: "Skirt Type", id: 5 },
                    { lable: "Skirt Length", id: 6 },
                  ].map((category, ind) => (
                    <>
                      {product?.customizations?.filter(
                        (item) => item.customization_category == category?.id
                      )?.length > 0 && (
                        <Dropdown
                          value={
                            addData?.cust?.filter(
                              (val, index) =>
                                val?.customization_category == category?.id
                            )?.[0]?.id
                          }
                          key={ind}
                          onChange={CustChange}
                        >
                          <option value={JSON.stringify(category)}>
                            {category.lable}
                          </option>
                          {product?.customizations
                            ?.filter(
                              (item) =>
                                item.customization_category == category?.id
                            )
                            .map((item, i) => (
                              <option key={i} value={item?.id}>
                                0{i + 1} {item.customization_name}{" "}
                              </option>
                            ))}
                        </Dropdown>
                      )}
                    </>
                  ))}
                </div>
              </div>

              {/* <div className="form-check p-2">
          <input
            className="form-check-input"
            type="checkbox"
            id="customMeasurementCheckbox"
            checked={customMeasurement}
            onChange={handleCustomMeasurementChange}
          />
          <label
            className="form-check-label"
            htmlFor="customMeasurementCheckbox"
          >
            Custom Measurement
          </label>
        </div> */}

              {/* {customMeasurement && ( */}
              {/* <div className="row mt-3">
            <div className="col-md-6">
              <h6>
                Fill in your details to get your item tailor-made for you.
                Please note a INR Rs. 20 fee applies.
              </h6>

              <div className="form-group">
                <label htmlFor="chestMeasurement">Bust (Inch)</label>
                <select
                  className="form-select"
                  name="bust"
                  value={formData.bust}
                  onChange={handleFormChange}
                >
                  <option value="">Select Bust</option>
                  <option value="32">32</option>
                  <option value="34">34</option>
                  <option value="36">36</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="waistMeasurement">Waist (Inch)</label>
                <select
                  className="form-select"
                  name="waist"
                  value={formData.waist}
                  onChange={handleFormChange}
                >
                  <option value="">Select Waist</option>
                  <option value="24">24</option>
                  <option value="26">26</option>
                  <option value="28">28</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="hipsMeasurement">Hips (Inch)</label>
                <select
                  className="form-select"
                  name="hips"
                  value={formData.hips}
                  onChange={handleFormChange}
                >
                  <option value="">Select Hips</option>
                  <option value="34">34</option>
                  <option value="36">36</option>
                  <option value="38">38</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="heightMeasurement">Height (Inch)</label>
                <select
                  className="form-select"
                  name="height"
                  value={formData.height}
                  onChange={handleFormChange}
                >
                  <option value="">Select Height</option>
                  <option value="5">5 ft</option>
                  <option value="5.5">5 ft 5 in</option>
                  <option value="6">6 ft</option>
                </select>
              </div>
            </div>
            <div className="col-md-6 text-center">
              <img src={custom} alt="Tailor" className="img-fluid" />
            </div>
          </div> */}
              {/* )} */}

              <div className="d-flex p-2 gap-2 mt-3">
                {/* <button
                  className="btn btn-outline me-2 px-3 py-2"
                  onClick={() => setIsModalOpen1(true)}
                  style={{
                    marginTop: "0px",
                    backgroundColor: "white",
                    color: "#8E1336",
                    borderColor: "#8E1336",
                    opacity: addingToCart ? 0.7 : 1,
                  }}
                >
                  Add Custom Mesurment
                </button> */}
                <Button
                  title={
                    cart_item
                      ? addingToCart
                        ? "Updating..."
                        : "Update Custom Mesurment"
                      : addingToCart
                      ? "Adding..."
                      : "Add Custom Mesurment"
                  }
                  onClick={()=>{
                    setIsModalOpen1(true)
                  }}
                  disabled={addingToCart}
                />
                {/* <button
                  className="btn btn-outline me-2 px-3 py-2"
                  onClick={handleSubmit}
                  disabled={addingToCart}
                  style={{
                    marginTop: "0px",
                    backgroundColor: "white",
                    color: "#8E1336",
                    borderColor: "#8E1336",
                    opacity: addingToCart ? 0.7 : 1,
                  }}
                >
                  {addingToCart ? "Adding..." : "Add to Cart"}
                </button> */}

                <Button
                  // title={addingToCart ? "Adding..." : "Add to Cart"}
                  title={
                    cart_item
                      ? addingToCart
                        ? "Updating..."
                        : "Update Cart"
                      : addingToCart
                      ? "Adding..."
                      : "Add to Cart"
                  }
                  onClick={handleSubmit}
                  disabled={addingToCart}
                />
                <button
                  className="border-0"
                  style={{ background: "none" }}
                  onClick={() => addToWishlist(product.itemcode)}
                >
                  <FontAwesomeIcon
                    icon={
                      wish_list.some(
                        (item) => item.itemcode == product.itemcode
                      )
                        ? solidHeart
                        : regularHeart
                    }
                    style={{
                      color: wish_list.some(
                        (item) => item.itemcode == product.itemcode
                      )
                        ? "red"
                        : "#8E1336",
                      fontSize: "27px",
                    }}
                  />
                </button>
              </div>

              <div className="">
                <h2
                  style={{
                    color: "#121212BF",
                    fontFamily: "ubuntu",
                    fontWeight: "400",
                  }}
                >
                  Enter The Pincode
                </h2>
                {/* <button className="btn btn-outline p-2 m-0">
                  <FontAwesomeIcon icon={faLocationDot} /> Deliver To Xyz -
                  India 012345
                </button> */}
                <InputBox
                  width={"300px"}
                  leftIcon={
                    addData.pincode?.length == 6 ? (
                      <FaSearch
                        cursor={"pointer"}
                        color="rgb(142, 19, 54)"
                        size={20}
                        onClick={HandleFindPincodeData}
                      />
                    ) : (
                      ""
                    )
                  }
                  // label="Neck Girth"
                  placeholder="Deliver To Xyz - India 012345"
                  value={addData.pincode}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
                    console.log("Value updated:", value);
                    if (value.length <= 6) {
                      setAddData({
                        ...addData,
                        pincode: value,
                        delivery_date: "",
                      });
                    }
                  }}
                  error={addData.pincode_error}
                />
              </div>
              {addData?.delivery_date && (
                <div className="delivery-description my-2">
                  Delivery by: <strong>{addData?.delivery_date}</strong>
                </div>
              )}

              <div className="headings-container d-flex flex-column mt-3 gap-3 ">
                <ExpendebleComponent title={"Description"}>
                  <p
                    className="heading-description"
                    dangerouslySetInnerHTML={{ __html: product.description }}
                  ></p>
                </ExpendebleComponent>
                <ExpendebleComponent title={"Specification"}>
                  {product?.specifications?.map((v, i) => (
                    <KeyValueList
                      key={i}
                      title={v?.specification_name}
                      value={v?.specification_option_name}
                    />
                  ))}
                </ExpendebleComponent>
                <ExpendebleComponent title={"Fabric"}>
                  {product?.fabric?.map((v, i) => (
                    <KeyValueList
                      key={i}
                      title={v?.fabric_name}
                      value={v?.fabric_option_name}
                    />
                  ))}
                </ExpendebleComponent>
                {/* <div className="col-md-6 ">
                  <div className="heading-section">
                    <h4 className="heading-title">Product Details</h4>
                    <p className="heading-description">Product Description:</p>
                    <p
                      className="heading-description"
                      style={{ marginTop: "35px" }}
                    >
                      Material & Care:
                    </p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="heading-section">
                    <h4 className="heading-title">Size & Fit</h4>
                    <p
                      className="heading-description"
                      dangerouslySetInnerHTML={{ __html: product.description }}
                    ></p>

                    <p
                      className="heading-description"
                      style={{ marginTop: "35px" }}
                    >
                      Material & Care: Soft Net, Dry Clean Only
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="row">{/* <Related /> */}</div>
          </div>
        ) : (
          "Data Not Found"
        )}
        <div className="col">
          <h2 style={{ marginBottom: "2rem" }}>Related Products</h2>
          <SwiperSlider
            count={
              window.innerWidth <= 760 ? 1 : window.innerWidth <= 100 ? 2 : 4
            }
            mobileCount={1}
            sb={2}
          >
            {related_product_list.map((v, i) => (
              <SwiperSlide
                style={{ display: "flex", justifyContent: "center" }}
                key={i}
              >
                <ProductCardSub
                  // addToWishlist={()=>addToWishlist(product.itemcode)}
                  imgH={"200px"}
                  product={v}
                />
              </SwiperSlide>
            ))}
          </SwiperSlider>
        </div>

        {isModalOpen1 && (
          <CustomModal
            width="100%"
            height="100%"
            open={isModalOpen1}
            handleCancel={() => {
              setIsModalOpen1(false);
              // setMeasurements({});
            }}
          >
            <MesurmentFunction
              measurements={measurements}
              handleSubmit={handleSubmitMesur}
              handleChange={handleChange}
            />
           
          </CustomModal>
        )}

        {isModalOpen && (
          <CustomModal
            open={isModalOpen}
            handleCancel={() => setIsModalOpen(false)}
          >
            <SizeGuide closeModal={toggleModal} />
          </CustomModal>
        )}
      </div>
    </>
  );
}

// const RelatedProductCard = ({})=>{

// }
