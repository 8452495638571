import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faBars } from "@fortawesome/free-solid-svg-icons";
import {
  FaStar,
  FaRegStar,
  FaStarHalfAlt,
  FaFilter,
  FaArrowLeft,
} from "react-icons/fa";
import "./ClotheMenu.css";
import { faHeart as regularHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import { base_url } from "../../utils/data";
import { Filter } from "./Filter";
import { Drawer } from "../../ChildComponent/Drawer/Drawer";
import { useSelector } from "react-redux";
import {
  get_filter_data,
  get_product_list,
  get_wish_list,
  update_wish_list,
} from "../../Redux/Slice/ProductSlice";
import store from "../../Redux/store";
import { toast } from "react-toastify";
import {
  ProductCardSub,
  StarRating,
} from "../../ChildComponent/OtherComponents";

const s1 = "https://shilohbridal.pmpframe.com/api/upload/Silhouette/s1.svg";
const s2 = "https://shilohbridal.pmpframe.com/api/upload/Silhouette/s2.svg";
const s3 = "https://shilohbridal.pmpframe.com/api/upload/Silhouette/s3.svg";
const s4 = "https://shilohbridal.pmpframe.com/api/upload/Silhouette/s4.svg";
const s5 = "https://shilohbridal.pmpframe.com/api/upload/Silhouette/s5.svg";
const s6 = "https://shilohbridal.pmpframe.com/api/upload/Silhouette/s6.svg";

// Train Images
const t1 = "https://shilohbridal.pmpframe.com/api/upload/Train/t1.svg";
const t2 = "https://shilohbridal.pmpframe.com/api/upload/Train/t2.svg";
const t3 = "https://shilohbridal.pmpframe.com/api/upload/Train/t3.svg";
const t4 = "https://shilohbridal.pmpframe.com/api/upload/Train/t4.svg";
const t5 = "https://shilohbridal.pmpframe.com/api/upload/Train/t5.svg";
const t6 = "https://shilohbridal.pmpframe.com/api/upload/Train/t6.svg";
const t7 = "https://shilohbridal.pmpframe.com/api/upload/Train/t7.svg";
const t8 = "https://shilohbridal.pmpframe.com/api/upload/Train/t8.svg";

export default function ClotheMenu({ state }) {
  const [openMenus, setOpenMenus] = useState({});
  const [openDrawer, setOpenDrawer] = useState(false);
  const userId = localStorage.getItem("user_id");
  const { wish_list, product_list_loading, product_list } = useSelector(
    (state) => state.productData
  );
  const [filterData, setFilterData] = useState([]);

  const { filter_data } = useSelector((state) => state.productData);

  const { cat_id, id } = state;

  useEffect(() => {
    const allCheckedData = filterData
      ? filterData
          .map((category) => ({
            label: category.label,
            checkedItems: category.data.filter((item) => item.checked),
          }))
          .filter((category) => category.checkedItems.length > 0) // Remove categories with no checked items
      : [];
    if (allCheckedData?.length > 0) {
      store.dispatch(
        get_product_list({
          ...obj,
          color: allCheckedData?.[1] || {},
          fabric: allCheckedData?.[2] || {},
          size: allCheckedData?.[0] || {},
          price: allCheckedData?.[3] || {},
        })
      );
    }
  }, [filterData]);

  useEffect(() => {
    setFilterData(filter_data);
  }, [filter_data]);

  // const obj = { cat_id: state?.cat_id, id: state?.id };

  const obj = { cat_id, id };

  useEffect(() => {
    store.dispatch(get_wish_list(userId));
  }, [userId]);

  const navigate = useNavigate();

  useEffect(() => {
    store.dispatch(get_product_list(obj));
    store.dispatch(get_filter_data(obj));
  }, [id, cat_id]);

  const handleMenuToggle = (index) => {
    setOpenMenus((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  // if (product_list_loading) {
  //   return <div>Loading...</div>; // Loading state
  // }

  return (
    <div className="container-fluid product_main">
      {/* Content here */}
      <div className="row" style={{ flexDirection: "row" }}>
        {/* <Filter openMenus={openMenus} handleMenuToggle={handleMenuToggle} /> */}

        <div className="filter_desktop col-md-3">
          <Filter
            filterData={filterData}
            setFilterData={setFilterData}
            filter_data={filter_data}
            obj={obj}
            openMenus={openMenus}
            handleMenuToggle={handleMenuToggle}
          />
        </div>
        <div className="filter_menu mb-4 px-3 align-items-center">
          <div className="d-flex gap-2 align-items-center justify-content-center ">
            <FaArrowLeft onClick={() => navigate(-1)} size={20} />
            <p className="mb-0 fs-3">Filter</p>
          </div>
          <FaFilter
            size={20}
            onClick={() => setOpenDrawer(!openDrawer)}
            color={"#8F1336"}
          />
        </div>

        {/* Right Side: Product Display */}
        <div className="col-md-9">
          <div className="row">
            {product_list.map((product, i) => (
              // <div
              //   className={`col-md-4 mb-4 product-card
              //     `}
              //   //   ${
              //   //   hoveredCard === product.id ? "hovered" : ""
              //   // }
              //   // onMouseEnter={() => setHoveredCard(product.id)}
              //   // onMouseLeave={() => setHoveredCard(null)}
              //   key={product.id}
              // >
              //   <div className="card">
              //     <Link
              //       to={`/product/${product.id}`}
              //       style={{ textDecoration: "none", color: "inherit" }}
              //     >
              //       <div
              //         className="image-container p-2 position-relative"
              //         style={{ height: "500px" }}
              //       >
              //         <img
              //           // src={product?.images?.[0]?.image}
              //           src={product?.image}
              //           alt={product.name}
              //           className="card-img-top"
              //           style={{
              //             objectFit: "cover",
              //             height: "300px",
              //             width: "100%",
              //             height: "500px",
              //           }}
              //         />

              //         {/* Conditional Heart Icon */}
              //         <FontAwesomeIcon
              //           icon={
              //             wish_list.some(
              //               (item) => item.itemcode === product.itemcode
              //             )
              //               ? solidHeart
              //               : regularHeart
              //           }
              //           className="heart-icon position-absolute"
              //           style={{
              //             color: wish_list.some(
              //               (item) => item.itemcode === product.itemcode
              //             )
              //               ? "red"
              //               : "#8F1336",
              //             bottom: "10px",
              //             right: "10px",
              //             cursor: "pointer",
              //             fontSize: "24px",
              //           }}
              //           onClick={(e) => {
              //             e.preventDefault();
              //             e.stopPropagation();
              //             addToWishlist(product.itemcode);
              //           }}
              //         />
              //       </div>
              //       <div className="card-body text-center">
              //         <div
              //           className="p-2"
              //           style={{
              //             fontSize: "20px",
              //             fontFamily: "ubuntu",
              //             color: "#121212BF",
              //             fontWeight: "400",
              //           }}
              //         >
              //           #{product.itemcode}
              //         </div>
              //         <h4
              //           className="card-title"
              //           style={{
              //             fontSize: "24px",
              //             color: "#121212BF",
              //             fontFamily: "playfair display",
              //             fontWeight: "400",
              //           }}
              //         >
              //           {product.name}
              //         </h4>
              //         <p className="card-text">
              //           <strike
              //             className="original-price"
              //             style={{
              //               fontSize: "16px",
              //               color: "#888888",
              //               fontFamily: "ubuntu",
              //             }}
              //           >
              //             RS. {product.price}
              //           </strike>
              //           <span
              //             className="dress-card-price"
              //             style={{ fontSize: "20px", color: "#121212BF" }}
              //           >
              //             &ensp; Rs. {product.discount}
              //           </span>
              //         </p>
              //         <div className="d-flex justify-content-center align-items-center">
              //           <StarRating rating={4.5} count={20} />
              //         </div>
              //       </div>
              //     </Link>
              //   </div>
              // </div>
              <ProductCardSub
                key={i}
                product={product}
                // addToWishlist={addToWishlist}
              />
            ))}
          </div>
        </div>

        {openDrawer && (
          <Drawer
            open={openDrawer}
            setOpen={setOpenDrawer}
            title={""}
            size={"350px"}
          >
            <Filter
              filterData={filterData}
              setFilterData={setFilterData}
              filter_data={filter_data}
              obj={obj}
              openMenus={openMenus}
              handleMenuToggle={handleMenuToggle}
            />
          </Drawer>
        )}
      </div>
    </div>
  );
}
