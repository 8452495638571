import Footer from "../Footer/Footer";
import Banner from "../Banner/Banner";
import NavHeading from "../Navheading/NavHeading";
import NavBar from "../Navbar/Navbar";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FaStar, FaRegStar, FaStarHalfAlt } from "react-icons/fa";

import { useNavigate } from "react-router-dom";
import bagImage from "../../assets/emptywishlist.svg";

import "bootstrap/dist/css/bootstrap.css";
import { useSelector } from "react-redux";
import store from "../../Redux/store";
import {
  get_wish_list,
  update_wish_list,
} from "../../Redux/Slice/ProductSlice";
import { toast } from "react-toastify";
import { StarRating } from "../../ChildComponent/OtherComponents";
import { base_url } from "../../utils/data";

export default function Wishlist() {
  const [cards, setCards] = useState([]);
  const [hoveredCard, setHoveredCard] = useState(null);
  const navigate = useNavigate();
  const userId = localStorage.getItem("user_id");
  const { wish_list } = useSelector((state) => state.productData);
  console.log("wish_list ===",wish_list);
  

  useEffect(() => {
    store.dispatch(get_wish_list(userId));
  }, []);

  const removeFromWishlist = async (itemCode) => {
    const userId = localStorage.getItem("user_id");
    if (!userId) {
      alert("Please login to remove items");
      return;
    }

    store
      .dispatch(
        update_wish_list({
          id: userId,
          code: itemCode,
          action: wish_list?.some((item) => item.itemcode == itemCode)
            ? "wishlist_remove"
            : "wishlist_add",
        })
      )
      .then((data) => {
        const newData = data.payload;
        if (newData?.success) {
          toast.success(newData.message);
          store.dispatch(get_wish_list(userId));
        }
      })
      .catch((err) => {
        console.log("error === ", err);
      });
  };

  return (
    <div>
      <div className="container mt-5">
        <h2
          className="wishlist-heading text-left mb-4"
          style={{ fontSize: "30px" }}
        >
          My Wishlist
        </h2>
        <div className="row">
          {wish_list?.length === 0 ? (
            <div
              className="container d-flex flex-column justify-content-center align-items-center"
              style={{ height: "50vh" }} // Center entire content vertically
            >
              {/* Bag Image */}
              <img
                src={bagImage}
                alt="Bag"
                className="position-relative"
                style={{
                  width: "150px",
                  height: "auto",
                  zIndex: "2",
                }}
              />

              <div className="text-center mt-5">
                <p className="fs-5" style={{ color: "#1d1716" }}>
                  Your wishlist is empty, but your dream dress is waiting to be
                  found!
                </p>
                <button
                  className="btn"
                  style={{
                    border: "2px solid #8F1336",
                    color: "#8F1336",
                    transition: "all 0.3s ease",
                    padding: "8px 24px",
                  }}
                  // onClick={() => navigate("/ShopByCategories")}
                  onMouseOver={(e) => {
                    e.target.style.backgroundColor = "#8F1336";
                    e.target.style.color = "white";
                  }}
                  onMouseOut={(e) => {
                    e.target.style.backgroundColor = "transparent";
                    e.target.style.color = "#8F1336";
                  }}
                >
                  Start Shopping
                </button>
              </div>
            </div>
          ) : (
            wish_list?.map((item, index) => (
              <div className="col-md-3 mb-4" key={index}>
                <div className="card dress-card shadow-sm border-light">
                  <div
                    className="card-img-top position-relative"
                    onMouseEnter={() => setHoveredCard(index)}
                    onMouseLeave={() => setHoveredCard(null)}
                  >
                    <img
                      className="w-100"
                      // src={hoveredCard === index ? item.image : item.image}
                      src={hoveredCard == index ?  item?.images?.[0]?.image : base_url + item?.image}
                      alt={item.title}
                      style={{
                        transition: "opacity 0.3s ease",
                        height: "500px",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                    <FontAwesomeIcon
                      icon={faHeart}
                      className="position-absolute heart-icon"
                      style={{
                        bottom: "10px",
                        right: "10px",
                        color: "red",
                        cursor: "pointer",
                        fontSize: "24px",
                        zIndex: 1,
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        removeFromWishlist(item.itemcode);
                      }}
                    />
                  </div>
                  <div className="card-body text-center">
                    <h5 className="card-title">
                      <Link
                        to={`/product/${item.id}`}
                        className="text-dark"
                        style={{ textDecoration: "none" }}
                      >
                        {item.name}
                      </Link>
                    </h5>
                    <p className="card-text" style={{ marginBottom: "6px" }}>
                      <strike className="text-muted">Rs. {item.price}</strike>
                      <span className="ml-2 font-weight-bold">
                        {" "}
                        Rs. {item.discount}
                      </span>
                    </p>
                    <div className="d-flex justify-content-center align-items-center">
                      <StarRating rating={4.5} count={22} />
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <Footer />
      <Banner />
    </div>
  );
}
