import React from "react";

export const Button = ({ title, icon, onClick }) => {
  return (
    <button
      type="button"
      className="btn"
      style={{
        fontSize: "16px",
        padding: "7px 18px",
        backgroundColor: "#ffffff",
        color: "#8F1336",
        fontFamily: "Ubuntu",
        borderColor: "#8F1336",
        borderStyle: "solid",
        borderRadius: "6px",
        fontWeight: "700",
      }}
      onClick={onClick}
    >
      {title} {icon ? icon : ""}
      {/* View All  */}
      {/* <span style={{ fontSize: "25px" }}>→</span> */}
    </button>
  );
};
