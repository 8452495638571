import NavBar from '../components/Navbar/Navbar';
import React from 'react';
// import shiloh from '../assets/shiloh.svg'; // Adjust the path accordingly
// import { Link } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

import Footer from '../components/Footer/Footer';
import ProductDetails from '../components/ProductDetail/ProductDetails';
import Banner from '../components/Banner/Banner';
import NavHeading from '../components/Navheading/NavHeading';
// import Hero from '../components/Hero/Hero';



export default function Product() {
return (
<div>

     
  <ProductDetails />

<Footer />
<Banner />
</div>
)
};

