import {
  FaAngleDown,
  FaChevronDown,
  FaChevronUp,
  FaEye,
  FaRegStar,
  FaStar,
  FaStarHalfAlt,
} from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import QtyInput from "../components/Quantity/Quantity";
import { Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as regularHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { Carousel } from "react-bootstrap"; // Ensure Bootstrap CSS is imported in your project
import { useRef, useState } from "react";
import { CiCircleChevLeft, CiCircleChevRight } from "react-icons/ci";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
  addToWishlist,
  handleSubmitMesurment,
} from "../utils/Hook/OtherFunction";
import { CheckBox, InputBox } from "./Input/Inputs";
import { MdMoveUp } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import { CustomModal } from "./Modal/Modal";
import { Button } from "./Button/Button";
import { base_url } from "../utils/data";
import { toast } from "react-toastify";
import { get_cart_list } from "../Redux/Slice/ProductSlice";
import store from "../Redux/store";
const custom =
  "https://shilohbridal.pmpframe.com/api/upload/custom_measurment/custom measurements.svg";

// import required modules

const arrow = "https://shilohbridal.pmpframe.com/api/upload/arrow.png";
const uplevel = "https://shilohbridal.pmpframe.com/api/upload/uplevel.png";
const measure = "https://shilohbridal.pmpframe.com/api/upload/measure.png";
const sizes = [
  "US 0 / UK 2 / EU 30",
  "US 2 / UK 6 / EU 32",
  "US 4 / UK 8 / EU 34",
  "US 6 / UK 10 / EU 36",
  "US 8 / UK 12 / EU 38",
  "US 10 / UK 14 / EU 40",
  "US 12 / UK 16 / EU 42",
  "US 14 / UK 18 / EU 44",
  "US 16 / UK 20 / EU 46",
  "US 16W / UK 20 / EU 46",
  "US 18 / UK 22 / EU 48",
  "US 20 / UK 24 / EU 50",
];

export const StarRating = ({ rating, size = "16px", count }) => {
  const totalStar = 5;
  const fullStar = Math.floor(rating);
  const halfStar = rating % 1 !== 0;

  return (
    <div
      className="Star"
      style={{ display: "flex", alignItems: "center", fontSize: size }}
    >
      {[...Array(fullStar)].map((_, index) => (
        <FaStar key={index} />
      ))}
      {halfStar && <FaStarHalfAlt />}
      {[...Array(totalStar - fullStar - (halfStar ? 1 : 0))].map((_, index) => (
        <FaRegStar key={index} />
      ))}
      <p
        style={{
          fontSize: size,
          color: "#121212",
          marginLeft: "5px",
          marginBottom: "0px",
        }}
      >
        ( {count} )
      </p>
    </div>
  );
};

export const OptionComponent = ({ item, isSelected, CustChange }) => {
  const image =
    item?.image ||
    "https://shilohbridal.pmpframe.com/api/upload/product/123412341234-ay5prExjYq.png";

  // console.log("item ===", item);

  return (
    <div
      style={{
        border: `1px solid ${isSelected ? "black" : "#DADADA"}`,
        padding: "5px",
        width: "60px",
        height: "60px",
        borderRadius: "50%",
      }}
    >
      <Tooltip
        title={
          <div className="d-flex flex-column gap-3 justify-content-center">
            <p className="text-center">{item?.customization_name}</p>
            <img
              src={image}
              className=""
              style={{
                maxHeight: "150px",
                objectFit: "cover",
              }}
            />
          </div>
        }
      >
        <div
          className={`hulk_po_radio hulkapps_option_child ${
            isSelected ? "swatch_selected" : ""
          }`}
          style={{
            width: "50px",
            height: "50px",
            backgroundImage: `url(${image})`,
            overflow: "hidden",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            cursor: "pointer",
          }}
          onClick={() => {
            CustChange(item);
          }}
        >
          <input
            type="radio"
            name={`customization_${item.customization_category}`}
            value={item}
            checked={isSelected}
            onChange={(e) => CustChange(e)}
            style={{ display: "none" }}
          />
        </div>
      </Tooltip>
    </div>
  );
};

export const CartCard = ({
  item,
  handleDelete,
  MoveToWishList,
  QTYChange,
  SizeChange,
  toggleModal,
  CheckValue,
  CheckOption,
}) => {
  // console.log("item ===", item);
  const { user_id } = useSelector((state) => state.userData);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [type, setType] = useState("");
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const handleChange = (key, value, max = 1) => {
    const regex = new RegExp(`^(0|[1-9]\\d{0,${max}})(\\.\\d{0,2})?$`);
    setData((prev) => ({
      ...prev,
      [key]: regex.test(value) || value === "" ? value : prev[key],
      [`${key}_error`]: "",
    }));
  };
  const handleSubmitMesur = async (errors) => {
    if (Object.keys(errors).length === 0) {
      try {
        const body = new FormData();
        body.append("action", "update_measurement");
        body.append("id", data?.id);
        body.append("user_id", user_id);
        body.append("neck_girth", data.neck_girth);
        body.append("upper_bust", data.upper_bust);
        body.append("medium_bust", data.medium_bust);
        body.append("lower_bust", data.lower_bust);
        body.append("waist", data.Waist);
        body.append("hips", data.Hips);
        body.append("height", data.Height);
        body.append("waist_to_floor", data.waist_to_floor);
        body.append("waist_to_knee", data.waist_to_knee);
        body.append("shoulder_span", data.shoulder_span);
        body.append("bust_height", data.bust_height);
        body.append("front_corset", data.front_corset);
        body.append("back_corset", data.back_corset);
        body.append("corset_side", data.corset_side);
        body.append("arm_girth", data.arm_girth);
        body.append("sleeve_length", data.sleeve_length);
        body.append("wrist_girth", data.wrist_girth);
        const response = await fetch(base_url + "cart.php", {
          method: "POST",
          body: body,
        });
        const res = await response.json();
        if (res.success) {
          setIsModalOpen(false);
          toast.success(res.message);
          store.dispatch(get_cart_list(user_id));
        } else {
          toast.error(res.message);
        }
      } catch (error) {
        console.log(error);
      }
      // update_measurement( id,neck_girth,upper_bust,medium_bust,lower_bust,waist_to_floor,waist_to_knee,shoulder_span,bust_height,front_corset,back_corset,corset_side,arm_girth,sleeve_length,wrist_girth)
    } else if (Object.keys(errors).length > 0) {
      setData({ ...data, ...errors });
    }
    return errors;
  };

  return (
    <div className="card my-3">
      <div className="row g-0">
        <div
          className="col-md-4"
          style={{
            position: "relative",
          }}
        >
          <img
            src={item?.image}
            className="img-fluid rounded-start p-3"
            alt={item?.itemcode}
            style={{
              width: "100%", // Ensure the width fits the container
              height: "250px", // Set a fixed height
              objectFit: "contain", // Use 'contain' to fit without cropping
              cursor: "pointer",
            }}
            onClick={() =>
              navigate(`/product/${item?.product_id}`, { state: item })
            }
          />
        </div>

        <div className="col-md-8 position-relative">
          <Tooltip title={"Move to Wish list"}>
            <MdMoveUp
              cursor={"pointer"}
              size={25}
              color="gray"
              style={{ right: "30px" }}
              className=" position-absolute top-0  m-2"
              onClick={() => {
                MoveToWishList(item?.id);
              }}
            />
          </Tooltip>
          <button
            type="button"
            className="btn-close position-absolute top-0 end-0 m-2"
            aria-label="Close"
            onClick={() => {
              handleDelete(item?.id);
            }}
          ></button>

          <div className="card-body">
            <Link
              to={`/product/${item?.itemcode}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <h5 className="card-title">
                {item?.name}{" "}
                <img
                  src={uplevel}
                  alt="uplevel"
                  className="ms-2"
                  style={{ verticalAlign: "middle" }}
                />
              </h5>
            </Link>

            <p className="mb-3">
              <strike className="text-muted">RS. {item?.price}</strike>
              <span className="ms-2 text-danger">Rs. {item?.discount}</span>
            </p>
            {/* <p
              className="text-primary mb-3"
              style={{ cursor: "pointer" }}
              onClick={toggleModal}
            >
              <img
                src={measure}
                alt="size guide"
                className="me-2"
                style={{ width: "24px", height: "17px" }}
              />
              Size Guide
            </p>
              */}
            <div className="d-flex justify-content-between">
              {/* <div
                className="d-flex align-items-center"
                style={{ width: "100%" }}
              >
                <label htmlFor={`size-select-${item?.id}`} className="me-2">
                  Size:
                </label>
                <select
                  id={`size-select-${item?.id}`}
                  className="form-select"
                  value={item?.Size}
                  onChange={(e) => SizeChange(item?.id, e.target.value)}
                >
                  {sizes.map((size, i) => (
                    <option key={i} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
              </div> */}
              <QtyInput
                count={item?.count}
                onChange={(newCount) => QTYChange(item?.id, newCount)}
              />
            </div>
            <div
              onClick={() => setOpen(!open)}
              className="d-flex justify-content-start align-items-center gap-3 my-3"
            >
              <div
                style={{
                  color: "#8F1336",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
              >
                Customization & Measuring Details
              </div>
              <FaChevronDown
                style={{
                  transform: open ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "transform 0.3s ease",
                }}
                cursor={"pointer"}
                color="#8F1336"
              />
            </div>
            {open && (
              <div
                className="d-flex justify-content-center flex-column gap-2"
                style={{
                  boxShadow: "0px 0px 4px 0px #0000003D",
                  borderRadius: "5px",
                  padding: "16px 15px",
                  width: "100%",
                }}
              >
                <div className="d-flex w-100 flex-column  gap-2">
                  <div className="d-flex justify-content-between w-100 ">
                    <div className="fw-bold">Customization</div>
                    <div>
                      {/* <FiEdit color="#8F1336" cursor={"pointer"} /> */}
                    </div>
                  </div>
                  <div className="d-flex justify-content-start flex-column gap-2">
                    <div className="d-flex ">
                      <div style={{ flex: 1 }} className=" text-start">
                        Color :
                      </div>
                      <div style={{ flex: 1 }} className=" text-start">
                        {item?.color}
                      </div>
                    </div>
                    {item?.customization?.map((v, i) => (
                      <div className="d-flex " key={i}>
                        <div style={{ flex: 1 }} className=" text-start">
                          {v?.customization_category_name} :
                        </div>
                        <div style={{ flex: 1 }} className=" text-start">
                          {v?.customization_name}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="d-flex w-100 flex-column  gap-2">
                  <div className="d-flex justify-content-between w-100 ">
                    <div className="fw-bold">Your Measurements</div>
                    <div className="d-flex gap-2">
                      <Tooltip title={"View"}>
                        <FaEye
                          onClick={() => {
                            setType("view");
                            setIsModalOpen(true);
                            setData(item);
                          }}
                          size={25}
                          cursor={"pointer"}
                        />
                      </Tooltip>
                      <Tooltip title={"Edit"}>
                        <BiEdit
                          onClick={() => {
                            setType("edit");
                            setIsModalOpen(true);
                            setData(item);
                          }}
                          size={25}
                          cursor={"pointer"}
                        />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <p className="mt-3">
              <img src={arrow} alt="arrow" /> 14 Days return available
            </p>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <CustomModal
          title={
            type == "edit" ? "Edit Custom Mesurement" : "View Custom Mesurement"
          }
          width="100%"
          height="100%"
          open={isModalOpen}
          handleCancel={() => {
            setIsModalOpen(false);
          }}
        >
          <MesurmentFunction
            disabled={type == "view" ? true : false}
            measurements={data}
            handleSubmit={handleSubmitMesur}
            handleChange={handleChange}
          />
        </CustomModal>
      )}
    </div>
  );
};

// Upper Bust :
// Medium Bust :
// Lower Bust :
// Waist :
// Hips :
// Height :

export const VideoCard = ({ url, title, onClick, maxW }) => {
  console.log("url ===", url);

  return (
    <div
      className="video-card position-relative"
      style={{
        position: "relative",
        paddingTop: "120%", // 16:26 aspect ratio
        width: "100%",
        overflow: "hidden",
        cursor: "pointer",
        maxWidth: maxW,
      }}
      onClick={onClick}
    >
      <video
        src={url}
        className="img-fluid mb-2 rounded"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
        controls
        muted={false}
        playsInline={false}
        preload="metadata"
        onClick={onClick}
      >
        {/* Fallback Thumbnail */}
        <img
          src="path/to/fallback-thumbnail.jpg"
          alt="Video Thumbnail"
          className="img-fluid mb-2 rounded"
          style={{
            height: "100px",
            width: "100%",
            objectFit: "cover",
            maxWidth: maxW,
          }}
          onClick={onClick}
        />
      </video>
    </div>
  );
};

export const ProductCardSub = ({ product, imgH, cardW }) => {
  const { wish_list } = useSelector((state) => state.productData);
  return (
    <div
      className={`col-md-4 mb-4 product-card 
      `}
      //   ${
      //   hoveredCard === product.id ? "hovered" : ""
      // }
      // onMouseEnter={() => setHoveredCard(product.id)}
      // onMouseLeave={() => setHoveredCard(null)}
      style={{ minWidth: cardW, width: cardW, maxWidth: cardW }}
      key={product.id}
    >
      <div className="card">
        <Link
          to={`/product/${product.id}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <div
            className="image-container p-2 position-relative"
            style={{ height: imgH ? imgH : "500px" }}
          >
            <img
              src={product?.image}
              alt={product.name}
              className="card-img-top"
              style={{
                objectFit: imgH ? "contain" : "cover",
                width: "100%",
                height: imgH ? imgH : "500px",
              }}
            />

            <FontAwesomeIcon
              icon={
                wish_list.some((item) => item.itemcode === product.itemcode)
                  ? solidHeart
                  : regularHeart
              }
              className="heart-icon position-absolute"
              style={{
                color: wish_list.some(
                  (item) => item.itemcode === product.itemcode
                )
                  ? "red"
                  : "#8F1336",
                bottom: "10px",
                right: "10px",
                cursor: "pointer",
                fontSize: "24px",
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                addToWishlist(product.itemcode);
              }}
            />
          </div>
          <div className="card-body text-center">
            <div
              className="p-2"
              style={{
                fontSize: "20px",
                fontFamily: "ubuntu",
                color: "#121212BF",
                fontWeight: "400",
              }}
            >
              #{product.itemcode}
            </div>
            <h4
              className="card-title"
              style={{
                fontSize: "24px",
                color: "#121212BF",
                fontFamily: "playfair display",
                fontWeight: "400",
              }}
            >
              {product.name}
            </h4>
            <p className="card-text">
              <strike
                className="original-price"
                style={{
                  fontSize: "16px",
                  color: "#888888",
                  fontFamily: "ubuntu",
                }}
              >
                RS. {product.price}
              </strike>
              <span
                className="dress-card-price"
                style={{ fontSize: "20px", color: "#121212BF" }}
              >
                &ensp; Rs. {product.discount}
              </span>
            </p>
            <div className="d-flex justify-content-center align-items-center">
              <StarRating rating={4.5} count={20} />
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export const SwiperSlider = ({ children, count, sb, mobileCount }) => {
  const swiperRef = useRef(null);
  const handleScroll = (direction) => {
    if (swiperRef.current) {
      if (direction === -1) {
        swiperRef.current.swiper.slidePrev();
      } else {
        swiperRef.current.swiper.slideNext();
      }
    }
  };

  return (
    <div
      className="d-flex flex-column align-items-center overflow-auto position-relative"
      style={{ flex: 7 }}
    >
      <div className="w-100 d-flex gap-3 justify-content-end">
        <Swiper
          ref={swiperRef}
          slidesPerView={count}
          centeredSlides={true}
          spaceBetween={sb}
          loop={true} // Enable looping
          pagination={{
            type: "fraction",
          }}
          navigation={false}
          modules={[Navigation]}
          className="mySwiper"
        >
          {children}
        </Swiper>
      </div>

      {/* Navigation Buttons */}
      <div className="w-100 d-flex gap-2 justify-content-center mt-4">
        <CiCircleChevLeft
          // className="cursor-pointer"
          style={{
            cursor: "pointer",
          }}
          onClick={() => handleScroll(-1)}
          size={40}
        />
        <CiCircleChevRight
          // className="cursor-pointer"
          style={{
            cursor: "pointer",
          }}
          onClick={() => handleScroll(1)}
          size={40}
        />
      </div>
    </div>
  );
};

export const ExpendebleComponent = ({ children, title }) => {
  const [open, setOpen] = useState(false);

  return (
    <div
      style={{
        border: "1px solid #ebe2e2",
        padding: "1rem 2rem",
        display: "flex",
        flexDirection: "column",
        transition: "all 0.3s ease",
      }}
    >
      <div className="d-flex justify-content-between">
        <div className="fw-bold fs-5">{title}</div>
        <div>
          <FaChevronUp
            style={{
              cursor: "pointer",
              transform: !open ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease",
            }}
            onClick={() => setOpen(!open)}
          />
        </div>
      </div>
      <div
        style={{
          maxHeight: open ? "1000px" : "0",
          overflow: "hidden",
          transition: "max-height 0.3s ease",
        }}
      >
        {open && (
          <div
            style={{
              marginTop: "1rem",
              opacity: open ? 1 : 0,
              transition: "opacity 0.3s ease",
            }}
          >
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

export const KeyValueList = ({ title, value }) => {
  console.log("title, value ===", title, value);

  return (
    <div
      className="d-flex justify-content-between "
      style={{
        borderBottom: "1px solid #ebe2e2",
      }}
    >
      <div className="px-3 py-1">{title}</div>
      <div className="px-3 py-1">{value}</div>
    </div>
  );
};

export const MesurmentFunction = ({
  measurements,
  handleChange,
  handleSubmit,
  disabled = false,
}) => {
  const handleSubmitMesu = () => {
    const data = handleSubmitMesurment(measurements);
    handleSubmit(data);
  };

  return (
    <>
      <div className="row mt-3">
        <div className="col-md-8">
          <h6>
            Fill in your details to get your item tailor-made for you. Please
            note a INR Rs. 20 fee applies.{" "}
            <span style={{ color: "red" }}>
              (All Values in the inch format)
            </span>
          </h6>

          <div
            className="grid-container"
            style={{
              display: "grid",
              gap: "16px",
              gridTemplateColumns: "repeat(auto-fit, minmax(160px, 1fr))",
            }}
          >
            <InputBox
              disabled={disabled}
              label="Neck Girth"
              placeholder="Neck Girth"
              value={measurements.neck_girth || ""}
              onChange={(e) => handleChange("neck_girth", e.target.value)}
              error={measurements.neck_girth_error || ""}
            />
            <InputBox
              disabled={disabled}
              label="Upper Bust"
              placeholder="Upper Bust"
              value={measurements.upper_bust || ""}
              onChange={(e) => handleChange("upper_bust", e.target.value)}
              error={measurements.upper_bust_error || ""}
            />
            <InputBox
              disabled={disabled}
              label="Medium Bust"
              placeholder="Medium Bust"
              value={measurements.medium_bust || ""}
              onChange={(e) => handleChange("medium_bust", e.target.value)}
              error={measurements.medium_bust_error || ""}
            />
            <InputBox
              disabled={disabled}
              label="Lower Bust"
              placeholder="Lower Bust"
              value={measurements.lower_bust || ""}
              onChange={(e) => handleChange("lower_bust", e.target.value)}
              error={measurements.lower_bust_error || ""}
            />
            <InputBox
              disabled={disabled}
              label="Waist"
              placeholder="Waist"
              value={measurements.Waist || ""}
              onChange={(e) => handleChange("Waist", e.target.value)}
              error={measurements.Waist_error || ""}
            />
            <InputBox
              disabled={disabled}
              label="Hips"
              placeholder="Hips"
              value={measurements.Hips || ""}
              onChange={(e) => handleChange("Hips", e.target.value)}
              error={measurements.Hips_error || ""}
            />
            <InputBox
              disabled={disabled}
              label="Height"
              placeholder="Height"
              value={measurements.Height || ""}
              onChange={(e) => handleChange("Height", e.target.value)}
              error={measurements.Height_error || ""}
            />
            <InputBox
              disabled={disabled}
              label="Waist to Floor"
              placeholder="Waist to Floor"
              value={measurements.waist_to_floor || ""}
              onChange={(e) => handleChange("waist_to_floor", e.target.value)}
              error={measurements.waist_to_floor_error || ""}
            />
            <InputBox
              disabled={disabled}
              label="Waist to Knee"
              placeholder="Waist to Knee"
              value={measurements.waist_to_knee || ""}
              onChange={(e) => handleChange("waist_to_knee", e.target.value)}
              error={measurements.waist_to_knee_error || ""}
            />
            <InputBox
              disabled={disabled}
              label="Shoulder Span"
              placeholder="Shoulder Span"
              value={measurements.shoulder_span || ""}
              onChange={(e) => handleChange("shoulder_span", e.target.value)}
              error={measurements.shoulder_span_error || ""}
            />
            <InputBox
              disabled={disabled}
              label="Bust Height"
              placeholder="Bust Height"
              value={measurements.bust_height || ""}
              onChange={(e) => handleChange("bust_height", e.target.value)}
              error={measurements.bust_height_error || ""}
            />
            <InputBox
              disabled={disabled}
              label="Front Corset Height"
              placeholder="Front Corset Height"
              value={measurements.front_corset || ""}
              onChange={(e) => handleChange("front_corset", e.target.value)}
              error={measurements.front_corset_error || ""}
            />
            <InputBox
              disabled={disabled}
              label="Back Corset Height"
              placeholder="Back Corset Height"
              value={measurements.back_corset || ""}
              onChange={(e) => handleChange("back_corset", e.target.value)}
              error={measurements.back_corset_error || ""}
            />
            <InputBox
              disabled={disabled}
              label="Corset Side"
              placeholder="Corset Side"
              value={measurements.corset_side || ""}
              onChange={(e) => handleChange("corset_side", e.target.value)}
              error={measurements.corset_side_error || ""}
            />
            <InputBox
              disabled={disabled}
              label="Arm Girth"
              placeholder="Arm Girth"
              value={measurements.arm_girth || ""}
              onChange={(e) => handleChange("arm_girth", e.target.value)}
              error={measurements.arm_girth_error || ""}
            />
            <InputBox
              disabled={disabled}
              label="Sleeve Length"
              placeholder="Sleeve Length"
              value={measurements.sleeve_length || ""}
              onChange={(e) => handleChange("sleeve_length", e.target.value)}
              error={measurements.sleeve_length_error || ""}
            />
            <InputBox
              disabled={disabled}
              label="Wrist Girth"
              placeholder="Wrist Girth"
              value={measurements.wrist_girth || ""}
              onChange={(e) => handleChange("wrist_girth", e.target.value)}
              error={measurements.wrist_girth_error || ""}
            />
          </div>
        </div>
        <div className="col-md-4 text-center">
          <img src={custom} alt="Tailor" className="img-fluid" />
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center mt-3">
        <Button title={"Submit"} onClick={() => handleSubmitMesu("submit")} />
      </div>
    </>
  );
};
