import React, { useState } from "react";
import { FaFacebook, FaGoogle } from "react-icons/fa";
import { Link, useNavigate, useLocation } from "react-router-dom";
import store from "../../Redux/store";
import { get_user_data, get_user_id } from "../../Redux/Slice/UserSlice";
import { get_wish_list } from "../../Redux/Slice/ProductSlice";

export default function Login() {
  const [emailOrMobile, setEmailOrMobile] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const logo = "https://shilohbridal.pmpframe.com/api/upload/logo.svg";

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isEmail = emailOrMobile.includes("@");

    const formData = new FormData();
    formData.append(isEmail ? "email" : "mobile", emailOrMobile);
    formData.append("password", password);

    try {
      const response = await fetch(
        "https://shilohbridal.pmpframe.com/api/login.php",
        {
          method: "POST",
          body: formData,
        }
      );

      console.log("Response Status:", response.status);

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Error response:", errorText);
        setError(`Error: ${response.status} - ${errorText}`);
        return;
      }

      const data = await response.json();
      if (data.success) {
        alert("Login successful!");
        localStorage.setItem("user_id", data.id);
        store.dispatch(get_user_data(data));
        store.dispatch(get_wish_list(data?.id));
        // store.dispatch(get_user_id(data.id));
        navigate("/");
      } else {
        setError(data.msg || "Login failed. Please check your credentials.");
      }
    } catch (error) {
      console.error("Catch block error:", error);
      setError("An error occurred. Please try again later.");
    }
  };

  return (
    <div
      className="container-fluid d-flex align-items-center min-vh-100"
      style={{
        backgroundImage:
          "url('https://shilohbridal.pmpframe.com/api/upload/login_banner.jpeg')",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-12 col-sm-10 col-md-8 col-lg-6 bg-white p-4 rounded shadow">
            <div className="text-center mb-3">
              <Link to="/">
                <img
                  src={logo}
                  className="img-fluid"
                  alt="Shiloh Bridal Logo"
                  style={{ maxWidth: "150px", cursor: "pointer" }}
                />
              </Link>
            </div>
            <h2 className="text-center mb-3" style={{ fontSize: "24px" }}>
              Sign In
            </h2>
            {error && (
              <p
                className="text-danger text-center small mb-3"
                style={{ fontSize: "16px" }}
              >
                {error}
              </p>
            )}
            <form onSubmit={handleSubmit} className="px-3">
              <div className="mb-3">
                <label
                  htmlFor="emailOrMobile"
                  className="form-label small mb-1"
                  style={{ fontSize: "16px" }}
                >
                  Email or Phone number
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="emailOrMobile"
                  name="emailOrMobile"
                  value={emailOrMobile}
                  onChange={(e) => setEmailOrMobile(e.target.value)}
                  required
                  style={{ height: "40px", fontSize: "16px" }}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="password"
                  className="form-label small mb-1"
                  style={{ fontSize: "16px" }}
                >
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  style={{ height: "40px", fontSize: "16px" }}
                />
              </div>
              <div className="mb-3 d-flex justify-content-between align-items-center">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="rememberMe"
                    style={{ width: "16px", height: "16px" }}
                  />
                  <label
                    className="form-check-label ms-2"
                    style={{ fontSize: "16px" }}
                    htmlFor="rememberMe"
                  >
                    Remember me
                  </label>
                </div>
                <Link
                  to="/forgot-password-new"
                  className="text-decoration-none"
                  style={{ fontSize: "16px" }}
                >
                  Forgot Password?
                </Link>
              </div>
              <button
                type="submit"
                className="btn btn-primary w-100 mb-3"
                style={{
                  background: "#902637",
                  border: "none",
                  height: "40px",
                  fontSize: "20px",
                }}
              >
                Sign In
              </button>
              <div className="text-center mb-3">
                <h6 className="mb-2" style={{ fontSize: "16px" }}>
                  OR
                </h6>
                <div>
                  <Link
                    to="/"
                    className="btn mx-2 p-2"
                    style={{
                      backgroundColor: "#8F1336",
                      width: "35px",
                      height: "35px",
                      fontSize: "16px",
                      color: "#ffffff",
                    }}
                  >
                    <FaGoogle />
                  </Link>
                  <Link
                    to="/"
                    className="btn mx-2 p-2"
                    style={{
                      backgroundColor: "#8F1336",
                      width: "35px",
                      height: "35px",
                      fontSize: "16px",
                      color: "#ffffff",
                    }}
                  >
                    <FaFacebook />
                  </Link>
                </div>
              </div>
              <p className="text-center mb-0" style={{ fontSize: "16px" }}>
                Don't have an account?{" "}
                <Link to="/Signup" className="text-decoration-none">
                  Sign Up
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
