import React from 'react';
import ClotheMenu from '../components/ClotheMenu/ClotheMenu';
import Footer from '../components/Footer/Footer';
import Banner from '../components/Banner/Banner';
import NavHeading from '../components/Navheading/NavHeading';
import NavBar from '../components/Navbar/Navbar';
import { useLocation, useParams } from 'react-router-dom';
// import Hero from '../components/Hero/Hero';

export default function ShopByCategories() {

  const {id,cat_id} = useParams()

  console.log("id,cat_id ===",id,cat_id);
  

  const {state} = useLocation();
  console.log("state ==",state);
  
  
  return (
    <div>
   
     {/* <Hero />  */}
     <ClotheMenu state={{id,cat_id}} />
     <Footer />
     <Banner />
    </div>
  );

};