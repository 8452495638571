import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faBars } from "@fortawesome/free-solid-svg-icons";
import { faHeart as regularHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
//icons are in local host
import user from "../../assets/icons/user.png";
import shoppingcart from "../../assets/icons/shoppingcart.png";
import indiaLanguage from "../../assets/icons/india.svg";
import { useSelector } from "react-redux";
import { Drawer } from "../../ChildComponent/Drawer/Drawer";
import store from "../../Redux/store";
import { get_cart_list, get_wish_list } from "../../Redux/Slice/ProductSlice";

 const base_url = "https://shilohbridal.pmpframe.com/api/upload/";
const logo = base_url + "logo.svg";
const india = base_url + "india.svg";

const shopping = base_url + "shopping-cart.png";

const Navbar = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchResults, setSearchResults] = useState([]); // Store search results
  const [timeoutId, setTimeoutId] = useState(null);
  const { wish_list, cart_list } = useSelector((state) => state.productData);

  const userId = localStorage.getItem("user_id");

  const { category_list } = useSelector((state) => state.otherData);

  const navigate = useNavigate();

  useEffect(() => {
    if (userId) {
      store.dispatch(get_cart_list(userId));
      store.dispatch(get_wish_list(userId));
    }
  }, []);

  const handleMouseEnter = useCallback(
    (index) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        setTimeoutId(null);
      }
      setActiveIndex(index);
    },
    [timeoutId]
  );

  const handleMouseLeave = useCallback(() => {
    const id = setTimeout(() => {
      setActiveIndex(null);
    }, 300);
    setTimeoutId(id);
  }, []);

  // Handle search input change

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    if (event.target.value) {
      fetchSearchResults(event.target.value);
    } else {
      setSearchResults([]); // Clear results if the search term is empty
    }
  };

  // Fetch search results from the API
  const fetchSearchResults = async (keyword) => {
    try {
      const formData = new FormData();
      formData.append("action", "search");
      formData.append("keyword", keyword);

      const response = await fetch(
        "https://shilohbridal.pmpframe.com/api/product_category.php",
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json(); // Parse the JSON response

      if (data.success) {
        setSearchResults(data.data); // Set the search results
      } else {
        console.error("Failed to fetch search results:", data.message);
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    console.log("Search Term:", searchTerm);
  };

  const toggleDropdown = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const handleUserDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleLogout = () => {
    localStorage.removeItem("user_id");
    navigate("/");
  };

  return (
    <>
      <nav className="d-lg-none">
        <div className="container-fluid px-3">
          <div className="d-flex justify-content-between align-items-center w-100">
            <a className="navbar-brand" href="/" onClick={() => navigate("/")}>
              <img src={logo} alt="Shiloh Bridal Logo" width="80" height="80" />
            </a>
            <button
              className="navbar-toggler border-0"
              type="button"
              onClick={() => setIsActive(!isActive)}
            >
              <FontAwesomeIcon
                icon={faBars}
                style={{ fontSize: "24px", color: "#8F1336" }}
              />
            </button>
          </div>

          {/* Mobile Menu - Slides from right */}

          <Drawer open={isActive} setOpen={setIsActive} title={"Menu"}>
            {/* Mobile Search */}
            <form onSubmit={handleSearchSubmit} className="mb-4">
              <div
                className="input-group"
                style={{ border: "1px solid #121212BF", borderRadius: "4px" }}
              >
                <span className="input-group-text bg-white">
                  <FaSearch style={{ color: "#8F1336", fontSize: "1.2rem" }} />
                </span>
                <input
                  type="text"
                  className="form-control border-0"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>
            </form>

            {/* Mobile Categories */}
            <div className="mobile-categories">
              {/* All Category */}

              {category_list?.map((v, i) => (
                <div className="category-item mb-3" key={i}>
                  <div
                    className="d-flex justify-content-between align-items-center py-2"
                    onClick={() => toggleDropdown(i)}
                    style={{ cursor: "pointer" }}
                  >
                    <span style={{ fontSize: "16px", color: "#121212BF" }}>
                      {v?.category}
                    </span>
                    {v?.subCategories.length > 0 && (
                      <FontAwesomeIcon
                        icon={faAngleDown}
                        className={activeIndex === i ? "rotate" : ""}
                        style={{
                          transition: "transform 0.3s ease",
                          transform:
                            activeIndex === i
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                        }}
                      />
                    )}
                  </div>
                  {activeIndex === i && (
                    <ul className="list-unstyled ps-3 mt-2">
                      {v?.subCategories?.map((item, index) => (
                        <li key={index} className="py-2">
                          <div
                            onClick={() => {
                              navigate(
                                `/ShopByCategories/${item?.id}/${v?.id}`,
                                {
                                  state: {
                                    data: { id: item?.id, cat_id: v?.id },
                                  },
                                }
                              );
                              setIsActive(false);
                            }}
                            className="text-decoration-none"
                            style={{ color: "#4A5568", cursor: "pointer" }}
                          >
                            {item.name}
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>

            {/* Mobile User Actions */}
            <div className="mobile-user-actions mt-4">
              <div className="d-flex justify-content-around align-items-center">
                <div
                  onClick={() => {
                    setIsActive(false);
                    navigate("/wishlist");
                  }}
                  className="text-decoration-none"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon
                    icon={wish_list?.length > 0 ? solidHeart : regularHeart}
                    style={{ color: "#8F1336", fontSize: "20px" }}
                  />
                </div>
                {userId ? (
                  <div className="position-relative">
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        width: "25px",
                        height: "25px",
                        cursor: "pointer",
                      }}
                      onClick={handleUserDropdown}
                    >
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 24 24"
                        fill="#8F1336"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V20H20V18C20 15.34 14.67 14 12 14Z" />
                      </svg>
                    </div>
                    {showDropdown && (
                      <ul
                        className="dropdown-menu show p-1"
                        style={{
                          position: "absolute",
                          right: 0,
                          top: "100%",
                          marginTop: "5px",
                          zIndex: 1000,
                        }}
                      >
                        <li>
                          <button
                            onClick={handleLogout}
                            className="dropdown-item"
                          >
                            Logout
                          </button>
                        </li>
                        <li>
                          <button
                            onClick={() => navigate("/dashboard/account")}
                            className="dropdown-item"
                          >
                            Dashboard
                          </button>
                        </li>
                      </ul>
                    )}
                  </div>
                ) : (
                  <Link to="/Signin" className="text-decoration-none">
                    <img src={base_url + "user.png"} alt="User" width="20" />
                  </Link>
                )}
                <div
                  onClick={() => {
                    setIsActive(false);
                    navigate("/cart/mycart");
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                  className="text-decoration-none position-relative"
                >
                  <img src={shopping} alt="Cart" width="20" />
                  {cart_list?.length > 0 && (
                    <span
                      className="badge rounded-pill"
                      style={{
                        position: "absolute",
                        top: "-8px",
                        right: "-8px",
                        backgroundColor: "#8F1336",
                        fontSize: "10px",
                      }}
                    >
                      {cart_list?.length}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </Drawer>
        </div>
      </nav>

      <nav className="navbar navbar-expand-lg navbar-light bg-white d-none d-lg-block">
        <div className="container-fluid px-5">
          <button
            className="navbar-toggler"
            type="button"
            onClick={() => setIsActive(!isActive)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <a
            className="navbar-brand mx-lg-0 mx-auto"
            href="/"
            onClick={() => navigate("/")}
          >
            <img src={logo} alt="Shiloh Bridal Logo" width="100" height="100" />
          </a>

          <div className="collapse navbar-collapse">
            <div className="d-flex align-items-center w-100">
              <form
                onSubmit={handleSearchSubmit}
                className="d-none d-lg-flex ms-lg-0"
                style={{ margin: "0px" }}
              >
                <div
                  className="input-group mx-5"
                  style={{ border: "1px solid #121212BF", borderRadius: "4px" }}
                >
                  <span className="input-group-text bg-white">
                    <FaSearch
                      style={{ color: "#8F1336", fontSize: "1.5rem" }}
                    />
                  </span>
                  <input
                    type="text"
                    className="form-control border-0"
                    placeholder="Search"
                    aria-label="Search"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    style={{
                      width: "200px",
                      textAlign: "left",
                      fontFamily: "ubuntu",
                      fontWeight: "400",
                      fontSize: "16px",
                      color: "#121212BF",
                    }}
                  />
                </div>
              </form>
              {/* Display search results */}
              {searchTerm && (
                <ul
                  className="dropdown-menu show p-2 mx-5"
                  style={{ position: "absolute", top: "79px", zIndex: "999" }}
                >
                  {searchResults.length > 0 ? (
                    searchResults.map((item, index) => (
                      <li key={index}>
                        <Link
                          to={`/product/${item.id}`}
                          className="text-dark text-decoration-none"
                          onClick={() => setSearchTerm("")}
                        >
                          {item.name}
                        </Link>
                      </li>
                    ))
                  ) : (
                    <li>No results found</li>
                  )}
                </ul>
              )}
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0 d-flex align-items-center">
                <li className="nav-item d-flex align-items-center me-3">
                  <img
                    src={indiaLanguage}
                    className="img-fluid me-3"
                    alt="India"
                    width="30"
                    height="30"
                  />
                  <span
                    style={{
                      wordSpacing: "0.5rem",
                      color: "#121212BF",
                      fontSize: "16px",
                      fontFamily: "ubuntu",
                      fontWeight: "700",
                    }}
                  >
                    EN | INR
                  </span>
                </li>
                <li className="nav-item me-3">
                  <Link
                    className="nav-link"
                    to="/wishlist"
                    onClick={() => navigate("/wishlist")}
                  >
                    <FontAwesomeIcon
                      icon={wish_list?.length > 0 ? solidHeart : regularHeart}
                      size="md"
                      style={{ color: "#8F1336", fontSize: "25px" }}
                    />
                  </Link>
                </li>
                {/* {userId ? (
                  <li className="nav-item me-3 dropdown">
                    <div
                      className="nav-link d-flex align-items-center justify-content-center rounded-circle  text-white"
                      style={{
                        width: "25px",
                        height: "25px",
                        cursor: "pointer",
                        backgroundColor: "#8F1336",
                      }}
                      onClick={handleUserDropdown}
                    >
                      {userName.charAt(0).toUpperCase()}
                    </div>
                    {showDropdown && (
                      <ul
                        className="dropdown-menu show p-1"
                        style={{ position: "absolute", marginLeft: "-49px" }}
                      >
                        <li>
                          <button
                            onClick={handleLogout}
                            className="dropdown-item"
                          >
                            Logout
                          </button>
                        </li>
                        <li>
                          <button
                            onClick={handleAccount}
                            className="dropdown-item"
                          >
                            Dashboard
                          </button>
                        </li>
                      </ul>
                    )}
                  </li>
                ) : (
                  <li className="nav-item me-3">
                    <Link className="nav-link" to="/Signin" onClick={handleLogin}>
                      <img src={base_url + "user.png"} alt="User" width="24" />
                    </Link>
                  </li>
                )} */}
                {userId ? (
                  <li className="nav-item me-3 dropdown">
                    <div
                      className="nav-link d-flex align-items-center justify-content-center"
                      style={{
                        width: "50px", // Increased from 24px
                        height: "50px", // Increased from 24px
                        cursor: "pointer",
                      }}
                      onClick={handleUserDropdown}
                    >
                      <svg
                        width="50" // Increased from 24
                        height="50" // Increased from 24
                        viewBox="0 0 24 24"
                        fill="#8F1336"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V20H20V18C20 15.34 14.67 14 12 14Z" />
                      </svg>
                    </div>
                    {showDropdown && (
                      <ul
                        className="dropdown-menu show p-1"
                        style={{ position: "absolute", marginLeft: "-49px" }}
                      >
                        <li>
                          <button
                            onClick={handleLogout}
                            className="dropdown-item"
                          >
                            Logout
                          </button>
                        </li>
                        <li>
                          <button
                            onClick={() => navigate("/dashboard/account")}
                            className="dropdown-item"
                          >
                            Dashboard
                          </button>
                        </li>
                      </ul>
                    )}
                  </li>
                ) : (
                  <li className="nav-item me-3">
                    <Link
                      className="nav-link"
                      to="/Signin"
                      onClick={() => navigate("/Signin")}
                    >
                      {/* <img 
        src={base_url + "user.png"} 
        alt="User" 
        width="24" 
      /> */}
                      <img src={user} alt="User" width="24" />
                    </Link>
                  </li>
                )}
                <li className="nav-item position-relative">
                  <Link
                    className="nav-link"
                    to="/cart/mycart"
                    onClick={() => navigate("/cart/mycart")}
                  >
                    {/* <img src={shopping} alt="Cart" width="24" /> */}
                    <img src={shoppingcart} alt="Cart" width="24" />

                    {cart_list?.length > 0 && (
                      <span
                        className="badge rounded-pill"
                        style={{
                          position: "absolute",
                          top: "-5px",
                          right: "-10px",
                          backgroundColor: "#8F1336",
                        }}
                      >
                        {cart_list?.length}
                      </span>
                    )}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <div className="container-fluid px-5 py-3 d-none d-lg-block">
        <div className="row justify-content-between align-items-center">
          {/* All Menu  */}

          {category_list?.map((v, i) => (
            <div
              key={i}
              className="col-auto"
              onMouseEnter={() => handleMouseEnter(i)}
              onMouseLeave={handleMouseLeave}
              style={{ position: "relative" }}
            >
              <h2
                className="d-flex align-items-center m-0"
                style={{
                  cursor: "default",
                  fontSize: "18px",
                  fontWeight: "400",
                  color: "#121212BF",
                  cursor: "pointer",
                }}
              >
                {/* Bridal Collection */}
                {v?.category}
                {v?.subCategories.length > 0 && (
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className={`ms-2 ${activeIndex === i ? "rotate" : ""}`}
                    style={{
                      transition: "transform 0.3s ease",
                      transform:
                        activeIndex === i ? "rotate(180deg)" : "rotate(0deg)",
                    }}
                  />
                )}
              </h2>
              {v?.subCategories.length > 0 && (
                <ul
                  style={
                    activeIndex === i
                      ? dropdownStyles.menu
                      : dropdownStyles.menuHidden
                  }
                >
                  {v?.subCategories.length > 0 ? (
                    v?.subCategories.map((subCategory, index) => (
                      <li key={index} style={dropdownStyles.item}>
                        <div
                          // to={`/ShopByCategories`}
                          onClick={() =>
                            navigate(
                              `/ShopByCategories/${subCategory?.id}/${v?.id}`,
                              {
                                state: {
                                  data: { id: subCategory?.id, cat_id: v?.id },
                                },
                              }
                            )
                          }
                          style={dropdownStyles.link}
                          onMouseEnter={(e) => {
                            e.target.style.backgroundColor = "#F7FAFC";
                            e.target.style.color = "#718096";
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.backgroundColor = "transparent";
                            e.target.style.color = "#4A5568";
                          }}
                        >
                          {subCategory.name}
                        </div>
                      </li>
                    ))
                  ) : (
                    <li style={dropdownStyles.item}>
                      No subcategories available
                    </li>
                  )}
                </ul>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
const dropdownStyles = {
  menu: {
    position: "absolute",
    top: "100%",
    left: "0",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    boxShadow:
      "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    minWidth: "200px",
    zIndex: 1000,
    marginTop: "0.5rem",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    padding: "0.5rem 0",
    opacity: 1,
    visibility: "visible",
    transform: "translateY(0)",
    transition: "all 0.3s ease-in-out",
    pointerEvents: "auto",
  },
  menuHidden: {
    position: "absolute",
    top: "100%",
    left: "0",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1)",
    minWidth: "200px",
    zIndex: 1000,
    marginTop: "0.5rem",
    padding: "0.5rem 0",
    opacity: 0,
    visibility: "hidden",
    transform: "translateY(-10px)",
    transition: "all 0.3s ease-in-out",
    pointerEvents: "none",
  },
  item: {
    padding: "8px 16px",
    listStyle: "none",
    transition: "background-color 0.2s ease",
    cursor: "pointer",
  },
  link: {
    color: "#4A5568",
    textDecoration: "none",
    display: "block",
    fontSize: "14px",
    fontWeight: "400",
    padding: "8px 16px",
    transition: "all 0.2s ease",
  },
  dropdownContainer: {
    position: "relative",
    paddingBottom: "0.5rem",
  },
};

export default Navbar;
