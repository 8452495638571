import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BlogCard } from "../components/Blog/BlogCard";
import { FaArrowLeft } from "react-icons/fa";

const AllBlog = () => {
  const { blog } = useSelector((state) => state.otherData);
  const navigate = useNavigate();

  return (
    <div>
      <div className="container-fluid my-5 px-5">
        <h2
          className=" mb-8"
          style={{
            fontSize: "40px",
            fontFamily: "Playfair Display",
            color: "#121212BF",
            fontWeight: "600",
            display:"flex",
            alignItems:"center",
            gap:"1rem",
            cursor:"default",
            marginBottom:'2rem'
          }}
        >
          <FaArrowLeft onClick={()=>navigate(-1)} fontSize={29} cursor={"pointer"} />
           Our Blog
        </h2>
        <div className="row row-cols-1 row-cols-md-2 g-4">
          {blog.map((v, i) => (
            <BlogCard
              key={i}
              image={v?.image}
              id={v?.id}
              title={v?.title}
              data={v?.date}
              onClick={() => navigate("/blog-details", { state: v })}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AllBlog;
