import React from "react";
import { Navbar, Nav, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import store from "../../Redux/store";
import { get_user_id } from "../../Redux/Slice/UserSlice";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current URL path

  // Define a mapping of tab names to routes
  const menuItems = [
    { name: "Overview", path: "/dashboard/account" },
    { name: "Addresses", path: "/dashboard/address" },
    { name: "Orders", path: "/dashboard/orders" },
    { name: "Payment Methods", path: "/dashboard/PaymentMethods" },
    { name: "Personal Details", path: "/dashboard/PersonalDetails" },
    { name: "Log Out", path: "/Logout" },
  ];

  // Function to handle navigation
  const handleItemClick = (item) => {
    if (item.name === "Log Out") {
      // Add logout logic here
      // 
      localStorage.clear();
      store.dispatch(get_user_id(""))
      navigate('/')
      return;
    }
    navigate(item.path);
  };

  return (
    <Navbar
      expand="lg"
      className="flex-column p-0"
      style={{ padding: "0px 92px" }}
    >
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="flex-column">
          {menuItems.map((item, index) => (
            <Button
              key={index}
              variant="outline"
              className="my-2"
              style={{
                width: "187px",
                height: "51px",
                fontSize: "17px",
                border: "1px solid #000000",
                backgroundColor:
                  location.pathname === item.path ? "#8E1336" : "#ffffff", // Highlight active page
                color: location.pathname === item.path ? "#ffffff" : "#000000", // Text color for active page
              }}
              onClick={() => handleItemClick(item)}
            >
              {item.name}
            </Button>
          ))}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Sidebar;
