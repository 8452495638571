import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { base_url } from "../../utils/data";

// export const get_product_data = createAsyncThunk("user_id", async (data) => {
//   return data;
// });
export const get_wish_list = createAsyncThunk("wish_list", async (id) => {
  const body = new FormData();
  body.append("action", "wishlist_list");
  body.append("userid", id);
  const response = await fetch(base_url + "wishlist.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  return res;
});
export const get_product = createAsyncThunk(
  "product",
  async ({ id, userId }) => {
    const body = new FormData();
    body.append("action", "listbyProduct");
    body.append("id", id);
    body.append("user_id", userId || "");
    const response = await fetch(base_url + "product.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    return res;
  }
);

export const update_wish_list = createAsyncThunk(
  "update_wishlist",
  async ({ id, code, action }) => {
    const body = new FormData();
    body.append("userid", id);
    body.append("itemcode", code);
    body.append("action", "wishlist_add");
    // body.append("action", action );
    const response = await fetch(base_url + "wishlist.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    return res;
  }
);

export const get_product_list = createAsyncThunk(
  "product_list",
  async (data) => {
    const body = new FormData();
    body.append("action", "listbySubCategory");
    body.append("category", data?.cat_id);
    body.append("sub_category", data?.id);
    if (data?.size) {
      data?.size?.checkedItems?.forEach((val, ind) => {
        body.append("size[]", val?.label);
      });
    }
    if (data?.color) {
      data?.color?.checkedItems?.forEach((val, ind) => {
        body.append("color_name[]", val?.label);
      });
    }
    if (data?.fabric) {
      data?.fabric?.checkedItems?.forEach((val, ind) => {
        body.append("fabric_name[]", val?.label);
      });
    }
    if (data?.price) {
      data?.price?.checkedItems?.forEach((val, ind) => {
        body.append("price_id[]", val?.id);
      });
    }

    // listbySubCategory(category,sub_category,color_name[],size[],price_id[],fabric_name[])
    const response = await fetch(base_url + "product.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    return res;
  }
);

export const get_filter_data = createAsyncThunk("filter_data", async () => {
  const body = new FormData();
  body.append("action", "filter");
  const response = await fetch(base_url + "product.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  return res;
});

export const get_cart_list = createAsyncThunk("get_cart_list", async (id) => {
  const body = new FormData();
  body.append("action", "cart_list");
  body.append("user_id", id);

  const response = await fetch(base_url + "cart.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  return res;
});

export const delete_cart_item = createAsyncThunk(
  "delete_cart_item",
  async ({ userId, itemId, action }) => {
    const body = new FormData();
    body.append("action", "delete");
    body.append("user_id", userId);
    body.append("id", itemId);

    const response = await fetch(base_url + "cart.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    return res;
  }
);

// move_to_wishlist( id )
export const move_to_wish_list = createAsyncThunk(
  "move_to_wish_list",
  async (id) => {
    const body = new FormData();
    body.append("action", "move_to_wishlist");
    body.append("id", id);

    const response = await fetch(base_url + "cart.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    return res;
  }
);

export const apply_promo_code = createAsyncThunk(
  "apply_promo_code",
  async ({ userId, promocode, pamt }) => {
    const body = new FormData();
    body.append("action", "applypromocode");
    body.append("user_id", userId);
    body.append("promocode", promocode);
    body.append("product_amount", pamt);

    const response = await fetch(base_url + "promocode.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    return res;
  }
);

export const update_selected_cart_product = createAsyncThunk(
  "update_selected_cart_product",
  async (data) => {
    return data;
  }
);

const initialState = {
  wish_list: [],
  product: {},
  cart_item: {},
  related_product_list: [],
  product_list: [],
  product_list_loading: false,
  filter_data: [],
  product_loading: false,
  cart_list: [],
  cart_loading: false,
  selected_card_product: [],
};

const productDataSlice = createSlice({
  name: "productData",
  initialState: initialState,
  extraReducers: (builder) => {
    // Get Wish List

    builder.addCase(get_wish_list.fulfilled, (state, action) => {
      const data = action.payload;
      if (data.success) {
        state.wish_list = data?.data;
      } else {
        state.wish_list = [];
      }
    });

    // Get Single Product

    builder.addCase(get_product.pending, (state, action) => {
      state.product_loading = true;
    });
    builder.addCase(get_product.rejected, (state, action) => {
      state.product_loading = false;
    });

    builder.addCase(get_product.fulfilled, (state, action) => {
      state.product_loading = false;
      const data = action.payload;
      if (data.success) {
        state.product = data?.data;
        state.cart_item = data?.cart_item;
        state.related_product_list = data?.related_products;
      } else {
        state.product = [];
        state.cart_item = [];
        state.related_product_list = [];
      }
    });

    // Get Product List

    builder.addCase(get_product_list.pending, (state, action) => {
      state.product_list_loading = true;
    });
    builder.addCase(get_product_list.rejected, (state, action) => {
      state.product_list_loading = false;
    });

    builder.addCase(get_product_list.fulfilled, (state, action) => {
      state.product_list_loading = false;
      const data = action.payload;
      if (data.success) {
        state.product_list = data?.data;
      } else {
        state.product_list = [];
      }
    });
    builder.addCase(get_filter_data.fulfilled, (state, action) => {
      const data = action.payload;
      if (data.success) {
        state.filter_data = data?.filterdata;
      } else {
        state.filter_data = [];
      }
    });

    // Get Cart List

    builder.addCase(get_cart_list.pending, (state, action) => {
      state.cart_loading = true;
    });
    builder.addCase(get_cart_list.rejected, (state, action) => {
      state.cart_loading = false;
    });

    builder.addCase(get_cart_list.fulfilled, (state, action) => {
      state.cart_loading = false;
      const data = action.payload;
      if (data.success) {
        state.cart_list = data?.cart;
        // if( state.selected_card_product?.length  == 0){
        state.selected_card_product = data?.cart;
        // }
      } else {
        state.cart_list = [];
        state.selected_card_product = [];
      }
    });

    builder.addCase(update_selected_cart_product.fulfilled, (state, action) => {
      const data = action.payload;
      console.log("data ===", data);

      if (data.type == "all") {
        if (state.selected_card_product?.length > 0) {
          state.selected_card_product = [];
        } else if (state.selected_card_product?.length == 0) {
          state.selected_card_product = state.cart_list;
        }
      } else if (data.type == "single") {
        const filterData = state.selected_card_product?.find(
          (v, i) => v.id == data?.id
        );
        if (filterData) {
          const updatedData = state.selected_card_product?.filter(
            (v, i) => v.id != data?.id
          );
          state.selected_card_product = updatedData;
        } else if (!filterData) {
          const updatedData = state.cart_list?.filter(
            (v, i) => v.id == data?.id
          );
          state.selected_card_product = [
            ...state.selected_card_product,
            ...updatedData,
          ];
        }
      }
    });
  },
});

export default productDataSlice.reducer;
