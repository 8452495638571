import React from 'react';

// import ResetPassword from '../components/ForgotPassword/PasswordReset';
import ForgetPass from '../components/ForgotPass/ForgotPass';


export default function ForgotPassWordNew() {
  return (
    <div>
   
    <ForgetPass />
    
    {/* <ResetPassword /> */}
      
    </div>
  )
}
