import { configureStore } from "@reduxjs/toolkit";
import userDataSlice from "./Slice/UserSlice.jsx";
import productDataSlice from "./Slice/ProductSlice.jsx";
import otherDataSlice from "./Slice/OtherSlice.jsx";

const store = configureStore({
  reducer: {
    userData: userDataSlice,
    productData: productDataSlice,
    otherData: otherDataSlice,
  },
});

export default store;
