import React from 'react';
import Sidebar from '../Sidebar/Sidebar';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import NavHeading from '../Navheading/NavHeading';
import NavBar from '../Navbar/Navbar';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

export default function Upi() {
  const navigate = useNavigate();

  const handleUpi = () => {
    // Navigate to the UPI ID page
    navigate('/UPI');
  };

  const handleBackClick = () => {
    // Navigate to the previous page
    navigate(-1); // This will navigate to the previous page in the browser history
  };

  return (
    <div>
      <Container fluid className="dashboard-container">
        <h1 className="py-4" style={{ textAlign: "center", color: "#121212BF" }}>My Account</h1>
        <Row className="dashboard">
          <Col md={2}>
            <Sidebar />
          </Col>
          <Col md={6}>
            <Row className="g-4 px-5">
              <Col md={12} className="d-flex justify-content-between align-items-center">
                <h2 style={{ color: "#121212BF" }}>Payment Method</h2>
              </Col>

              {/* Main Card */}
              <Col md={12}>
                <Card className="d-flex p-4 px-5 shadow-sm">
                  <div className="d-flex justify-content-between w-100 align-items-center py-3">
                    <h4 style={{ color: "#121212BF" }}>
                      <FontAwesomeIcon
                        icon={faArrowLeft}
                        className="me-2"
                        style={{ cursor: "pointer", color: "#121212BF" }}
                        onClick={handleBackClick}
                      />
                      Add New UPI ID
                    </h4>
                  </div>

                  {/* Form for UPI ID */}
                  <Form>
                    <Row className="mb-3">
                      <Col md={12}>
                        <Form.Group controlId="formUpiId">
                          <Form.Label>Enter your UPI ID</Form.Label>
                          <Form.Control type="text" placeholder="Enter UPI ID" />
                        </Form.Group>
                      </Col>
                    </Row>
                    
                    <div className="d-flex justify-content-center">
                      <Button
                        variant="outline-secondary"
                        style={{ backgroundColor: "#8E1336", border: "2px solid #8E1336", color: "#ffffff" }}
                        onClick={handleUpi}
                      >
                        Add UPI ID
                      </Button>
                    </div>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
