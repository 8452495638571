import React, { useState, useEffect } from "react";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode, Pagination } from "swiper/modules";

// Reusable CustomerCard component
const CustomerCard = ({ image }) => {
  return (
    <div
      className="customer-card text-center"
      style={{ transition: "transform 0.3s ease" }}
    >
      <img
        src={image}
        alt="Customer"
        className="rounded-circle mb-2"
        style={{ width: "100px", height: "100px" }}
      />
    </div>
  );
};

const CardName = ({ name }) => {
  return (
    <div className="customer-name">
      <h5
        className=""
        style={{ fontSize: "24px", fontWeight: "700", fontFamily: "ubuntu" }}
      >
        {name}
      </h5>
    </div>
  );
};

// Reusable StarRating component
const StarRating = ({ rating }) => {
  const fullStars = Math.floor(rating);
  const halfStar = rating % 1 !== 0;
  return (
    <div className="stars" style={{ color: "#8E764F" }}>
      {[...Array(fullStars)].map((_, index) => (
        <FaStar key={index} />
      ))}
      {halfStar && <FaStarHalfAlt />}
    </div>
  );
};

const TestimonialCom = ({ isMobile, image, rating, name, date, text }) => {
  return (
    <div className="col-12 my-4 ">
      <div
        className="slide p-3 border rounded shadow-sm"
        style={{
          minWidth: isMobile ? "auto" : "30%",
          padding: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          margin: "6px",
          transition: "transform 0.3s ease",
        }}
        onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
        onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
      >
        <CustomerCard image={image} />
        <StarRating rating={rating} />
        <div className="testimonial-detail mt-3" style={{ color: "#121212BF" }}>
          <p>{text}</p>
          <CardName name={name} />
          <p>Married on {date}</p>
        </div>
      </div>
    </div>
  );
};

// Main Customer component
const Customer = () => {
  const { testimonial } = useSelector((state) => state.otherData);
  const customers = [
    {
      image: "https://shilohbridal.pmpframe.com/api/upload/Lorem.svg",
      name: "Lorem ipsum",
      rating: 4.5,
    },
    {
      image: "https://shilohbridal.pmpframe.com/api/upload/Lorem2.svg",
      name: "Lorem ipsum 2",
      rating: 5,
    },
    {
      image: "https://shilohbridal.pmpframe.com/api/upload/Lorem3.svg",
      name: "Lorem ipsum 3",
      rating: 4.5,
    },
    {
      image: "https://shilohbridal.pmpframe.com/api/upload/Lorem.svg",
      name: "Lorem ipsum",
      rating: 4.5,
    },
    {
      image: "https://shilohbridal.pmpframe.com/api/upload/Lorem2.svg",
      name: "Lorem ipsum 2",
      rating: 5,
    },
    {
      image: "https://shilohbridal.pmpframe.com/api/upload/Lorem3.svg",
      name: "Lorem ipsum 3",
      rating: 4.5,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [firstDotIndex, setFirstDotIndex] = useState(0);
  const [secondDotIndex, setSecondDotIndex] = useState(1);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const cardsPerPage = isMobile ? 1 : 3;
  const totalSlides = isMobile ? 2 : Math.ceil(customers.length / 3);

  const handleNext = (index) => {
    if (isMobile) {
      if (index === 0) {
        // For first dot in mobile view
        setFirstDotIndex((prev) => (prev + 1) % customers.length);
        setCurrentIndex(0);
      } else {
        // For second dot in mobile view
        setSecondDotIndex((prev) => (prev + 1) % customers.length);
        setCurrentIndex(1);
      }
    } else {
      setCurrentIndex(index);
    }
  };

  const getTransformValue = () => {
    if (isMobile) {
      // For mobile view
      const displayIndex = currentIndex === 0 ? firstDotIndex : secondDotIndex;
      return `translateX(-${displayIndex * 100}%)`;
    } else {
      // For desktop view - unchanged
      const transformPercentage = currentIndex * 100;
      const maxTransform = (customers.length / 3 - 1) * 100;
      return `translateX(-${Math.min(transformPercentage, maxTransform)}%)`;
    }
  };

  return (
    <div className="testimonials-section container-fluid text-center my-5 ">
      <h2
        className="mb-4"
        style={{
          textAlign: "left",
          fontSize: "40px",
          color: "#121212BF",
          fontFamily: "playfair display",
          fontWeight: "600",
        }}
      >
        Hear From Our Customers
      </h2>
      <div className="testimonials-container overflow-hidden">
        <div
          className="row flex-nowrap"
          style={{
            transform: getTransformValue(),
            transition: "transform 0.5s ease-in-out",
          }}
        >
          {/* 
          {customers.map((customer, index) => (
            <div key={index} className="col-12 col-md-4 mb-4">
              <div
                className="slide p-3 border rounded shadow-sm"
                style={{
                  minWidth: isMobile ? "100%" : "32.33%",
                  padding: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  margin: "6px",
                  transition: "transform 0.3s ease",
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.transform = "scale(1.05)")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.transform = "scale(1)")
                }
              >
                <CustomerCard image={customer.image} />
                <StarRating rating={customer.rating} />
                <div
                  className="testimonial-detail mt-3"
                  style={{ color: "#121212BF" }}
                >
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Magna sit cursus sed
                    tellus mauris. Metus cras molestie sapien in bibendum
                    malesuada.
                  </p>
                  <CardName name={customer.name} />
                  <p>Married on 01/01/2024</p>
                </div>
              </div>
            </div>
          ))} 
           */}
          <>
            <Swiper
              // slidesPerView={3} // Default number of slides for larger screens
              spaceBetween={10}
              freeMode={true}
              pagination={{
                clickable: true,
              }}
              modules={[FreeMode, Pagination]}
              breakpoints={{
                // when the window width is >= 640px
                640: {
                  slidesPerView: 1, // 1 slide on mobile screens
                },
                // when the window width is >= 768px
                768: {
                  slidesPerView: 2, // 2 slides for medium screens
                },
                // when the window width is >= 1024px
                1024: {
                  slidesPerView: 3, // 3 slides for larger screens
                },
              }}
              className="mySwiper"
            >
              {testimonial.map((v, index) => (
                <SwiperSlide key={index}>
                  <TestimonialCom
                    text={v?.description}
                    isMobile={isMobile}
                    image={v?.image}
                    rating={v?.rating}
                    name={v?.name}
                    date={v?.marriage_date}
                  />

                  {/* <div className="col-12 my-4 ">
                    <div
                      className="slide p-3 border rounded shadow-sm"
                      style={{
                        minWidth: isMobile ? "auto" : "30%",
                        padding: "8px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        margin: "6px",
                        transition: "transform 0.3s ease",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.transform = "scale(1.05)")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.transform = "scale(1)")
                      }
                    >
                      <CustomerCard image={customer.image} />
                      <StarRating rating={customer.rating} />
                      <div
                        className="testimonial-detail mt-3"
                        style={{ color: "#121212BF" }}
                      >
                        <p>
                          Lorem ipsum dolor sit amet consectetur. Magna sit
                          cursus sed tellus mauris. Metus cras molestie sapien
                          in bibendum malesuada.
                        </p>
                        <CardName name={customer.name} />
                        <p>Married on 01/01/2024</p>
                      </div>
                    </div>
                  </div> */}
                </SwiperSlide>
              ))}
            </Swiper>
          </>
        </div>
      </div>
      {/* <div className="carousel-buttons d-flex justify-content-center mt-1">
        {[...Array(totalSlides)].map((_, index) => (
          <button
            key={index}
            className={`carousel-btn btn rounded-circle mx-1 p-2 ${
              currentIndex === index ? "bg-danger" : "bg-secondary"
            }`}
            style={{
              border: "none",
              cursor: "pointer",
              transition: "background-color 0.3s ease",
            }}
            onClick={() => handleNext(index)}
          />
        ))}
      </div> */}
    </div>
  );
};

export default Customer;
