import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import { Container, Row, Col, Card, Button, Image, Form, InputGroup } from 'react-bootstrap';
import NavHeading from '../Navheading/NavHeading';
import NavBar from '../Navbar/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faSearch, faTruck } from '@fortawesome/free-solid-svg-icons';

export default function Orders() {
  const [orders, setOrders] = useState([]); // State to hold orders
  const [searchTerm, setSearchTerm] = useState(''); // State to hold search term
  const userId = localStorage.getItem('user_id'); // Get user ID from local storage

  // Function to fetch orders
  const fetchOrders = async (search = '') => {
    const formData = new FormData();
    formData.append('action', 'all_orders');
    formData.append('userid', userId);
    if (search) {
      formData.append('search', search);
    }

    try {
      const response = await fetch('https://shilohbridal.pmpframe.com/api/user.php', {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      if (data.success) {
        setOrders(data.message); // Set orders in state
      } else {
        console.error('Error fetching orders:', data.message);
        setOrders([]); // Clear orders if there's an error
      }
    } catch (error) {
      console.error('Fetch error:', error);
      setOrders([]); // Clear orders on fetch error
    }
  };

  // Fetch orders on component mount and when search term changes
  useEffect(() => {
    fetchOrders(searchTerm);
  }, [searchTerm]);

  return (
    <Row className="g-4 px-5">
              {/* All Orders Heading, Filter Button, and Search Input */}
              <Col md={12} className="d-flex justify-content-between align-items-center">
                <h2 style={{ color: "#121212BF" }}>All Orders</h2>
                <div className="d-flex align-items-center">
                  <InputGroup style={{ width: "276px", marginRight: "10px", marginTop: "0px" }}>
                    <InputGroup.Text style={{ backgroundColor: "#ffffff", color: "#8E1336" }}>
                      <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Search Orders"
                      style={{ height: "43px" }}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)} // Update search term
                    />
                  </InputGroup>
                  <Button
                    variant="outline px-5 py-2"
                    style={{
                      backgroundColor: "#ffffff",
                      border: "2px solid #8E1336",
                      color: "#8E1336",
                      fontSize: "15px",
                      marginBottom: "0px"
                    }}
                    onClick={() => fetchOrders(searchTerm)} // Refetch orders on button click
                  >
                    <FontAwesomeIcon icon={faFilter} className="me-2" /> Filter
                  </Button>
                </div>
              </Col>

              {/* Order Cards */}
              {orders.length > 0 ? (
                orders.map(order => (
                  <Col md={12} key={order.orderid}>
                    <Card className="d-flex p-4 px-5 shadow-sm">
                      <div className="d-flex justify-content-between w-100 align-items-center">
                        <h4 style={{ color: "#121212BF" }}>
                          <FontAwesomeIcon icon={faTruck} className="me-2" style={{ color: "#8E1336" }} />
                          On the way
                        </h4>
                      </div>
                      <div className='px-5'>
                        <p style={{ color: "#121212BF" }}>
                          Delivery by: {new Date(order.datetime).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit' })}
                        </p>
                      </div>

                      {/* Nested Product Card */}
                      <Card className="mt-3 p-3">
                        <div className="d-flex align-items-center">
                          <Image
                            src={`https://shilohbridal.pmpframe.com/api/upload/${order.image}`} // Ensure this URL is correct
                            alt="Product Image"
                            style={{ width: '104px', height: '135px', objectFit: 'cover', marginRight: '15px' }}
                          />
                          <div>
                            <h6 style={{ color: "#121212BF" }}>{order.name}</h6>
                            <p style={{ color: "#121212BF", margin: "0px" }}>{order.itemcode}</p>
                            <p style={{ color: "#121212BF", marginBottom: "0px" }}>Size: {order.size || 'N/A'}</p>
                            <p style={{ color: "#121212BF", marginBottom: "0px" }}>Quantity: {order.count}</p>
                            <p style={{ color: "#121212BF" }}>Price: Rs.{order.price}</p>
                          </div>
                        </div>
                      </Card>
                    </Card>
                  </Col>
                ))
              ) : (
                <Col md={12} className="text-center py-4">
                  <h4 style={{ color: "#121212BF" }}>No data found</h4>
                </Col>
              )}
            </Row>
    // <div>
   
    //   <Container fluid className="dashboard-container">
    //     <h1 className="py-4" style={{ textAlign: "center", color: "#121212BF" }}>My Account</h1>
    //     <Row className="dashboard">
    //       <Col md={2}>
    //         <Sidebar />
    //       </Col>
    //       <Col md={10}>
            
    //       </Col>
    //     </Row>
    //   </Container>
    // </div>
  );
}
