import React from "react";
import { Modal } from "antd";

export const CustomModal = ({
  open,
  children,
  handleCancel,
  title,
  width = "fit-content",
  height = "fit-content",
}) => {
  return (
    <Modal
      width={width}
      height={height}
      title={title}
      open={open}
      onCancel={handleCancel}
      footer={null}
    >
      {children}
    </Modal>
  );
};
