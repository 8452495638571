
// import React, { useState, useRef } from 'react';
// import { ArrowLeft } from 'lucide-react';
// import { useNavigate } from 'react-router-dom';

// const Password = () => {
//   const navigate = useNavigate();
//   const [input, setInput] = useState('');
//   const [error, setError] = useState('');
//   const [isSubmitted, setIsSubmitted] = useState(false);
//   const [code, setCode] = useState(['', '', '', '']);
//   const inputRefs = [
//     useRef(null),
//     useRef(null),
//     useRef(null),
//     useRef(null)
//   ];

//   const validateInput = (value) => {
//     const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     const phonePattern = /^\+?[\d\s-]{10,}$/;

//     if (!value.trim()) {
//       return 'This field is required';
//     }
//     if (!emailPattern.test(value) && !phonePattern.test(value)) {
//       return 'Please enter a valid email or phone number';
//     }
//     return '';
//   };

 
//   const handleChange = (index, value) => {
//     if (value.match(/^[0-9]$/)) {
//       const newCode = [...code];
//       newCode[index] = value;
//       setCode(newCode);
      
//       // Move to next input if available
//       if (index < 3 && value !== '') {
//         inputRefs[index + 1].current.focus();
//       }
//     }
//   };

//   const handleKeyDown = (index, e) => {
//     if (e.key === 'Backspace' && !code[index] && index > 0) {
//       // Move to previous input on backspace if current input is empty
//       const newCode = [...code];
//       newCode[index - 1] = '';
//       setCode(newCode);
//       inputRefs[index - 1].current.focus();
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const validationError = validateInput(input);
//     setError(validationError);

//     if (!validationError) {
//       try {
//         const formData = new FormData();
//         formData.append('email', input);

//         const response = await fetch("https://shilohbridal.pmpframe.com/api/login.php", {
//           method: "POST",
//           body: formData,
//         });

//         if (response.ok) {
//           setIsSubmitted(true);
//         } else {
//           setError('An error occurred. Please try again.');
//         }
//       } catch (err) {
//         setError('An error occurred. Please try again.');
//       }
//     }
//   };

//   const handleVerificationSubmit = (e) => {
//     e.preventDefault();
//     // Add your submission logic here
//     console.log('Submitted code:', code.join(''));
//   };

//   if (isSubmitted) {
//     return (
//       <div className="container-fluid d-flex align-items-center min-vh-100" 
//         style={{ 
//           backgroundImage: "url('https://shilohbridal.pmpframe.com/api/upload/login_banner.jpeg')", 
//           backgroundSize: "cover", 
//           backgroundPosition: "center", 
//         }}
//       >


// <div className="container">
//       <div className="row justify-content-center">
//         <div className="col-md-6">
//           <div className="card mt-5">
//             <div className="card-body">
//               <div className="d-flex align-items-center mb-3">
//                 <ArrowLeft className="me-2" size={28} />
//                 <h2 className="mb-0 fs-3">Submit Your Code</h2>
//               </div>
              
//               <p className="text-muted mb-4 fs-6">
//                 Please Enter The 4-Digit Code Sent To {input}
//               </p>

//               <form onSubmit={handleVerificationSubmit}>
//                 <div className="d-flex  gap-3 mb-4">
//                   {code.map((digit, index) => (
//                     <input
//                       key={index}
//                       ref={inputRefs[index]}
//                       type="text"
//                       maxLength={1}
//                       value={digit}
//                       onChange={(e) => handleChange(index, e.target.value)}
//                       onKeyDown={(e) => handleKeyDown(index, e)}
//                       className="form-control text-center"
//                       style={{
//                         width: '3rem',
//                         height: '3rem',
//                         fontSize: '1.5rem'
//                       }}
//                     />
//                   ))}
//                 </div>

//                 <button
//                   type="button"
//                   className="btn text-secondary fs-6 p-0 mb-3 border-0"
//                   style={{ background: 'none' }}
//                 >
//                   Resend Code
//                 </button>

//                 <button
//                   type="submit"
//                   className="btn w-100 py-2 mb-3 fs-5 text-white"
//                   style={{
//                     backgroundColor: '#902637',
//                     borderColor: '#902637'
//                   }}
//                 >
//                   Submit
//                 </button>

//                 <button
//                   type="button"
//                   onClick={() => {
//                     setIsSubmitted(false);
//                     setInput('');
//                   }}
//                   className="btn text-secondary fs-6 p-0 border-0"
//                   style={{ background: 'none' }}
//                 >
//                   Change Email Or Phone Number
//                 </button>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//       </div>
//     );
//   }

//   return (
//     <div className="container-fluid d-flex align-items-center min-vh-100" 
//       style={{ 
//         backgroundImage: "url('https://shilohbridal.pmpframe.com/api/upload/login_banner.jpeg')", 
//         backgroundSize: "cover", 
//         backgroundPosition: "center", 
//       }}
//     >
//       <div className="container">
//         <div className="row justify-content-center">
//           <div className="col-md-6">
//             <div className="card mt-5">
//               <div className="card-body">
//                 <div className="mb-4">
//                   <div 
//                     className="d-flex align-items-center" 
//                     onClick={() => navigate('/signin')} 
//                     style={{ cursor: 'pointer' }}
//                   >
//                     <ArrowLeft size={28} className="me-2" />
//                     <span className="fs-5">Forgot Password</span>
//                   </div>
//                 </div>

//                 <p className="text-muted mb-4">
//                   Please Enter Your Email Address or Mobile number To Receive A Verification Code
//                 </p>

//                 <form onSubmit={handleSubmit}>
//                   <div className="mb-3">
//                     <label
//                       htmlFor="emailOrPhone"
//                       className="form-label fw-medium"
//                     >
//                       Email or Phone Number
//                     </label>
//                     <input
//                       id="emailOrPhone"
//                       type="text"
//                       value={input}
//                       onChange={(e) => {
//                         setInput(e.target.value);
//                         if (error) setError('');
//                       }}
//                       className={`form-control ${error ? 'is-invalid' : ''}`}
//                       placeholder="Enter email or phone number"
//                     />
//                     {error && (
//                       <div className="invalid-feedback">{error}</div>
//                     )}
//                   </div>

//                   <button
//                     type="submit"
//                     className="btn btn-danger btn-sm w-100 py-2 fs-6"
//                     style={{ 
//                       backgroundColor: '#902637',
//                       borderColor: '#902637'
//                     }}
//                   >
//                     Submit
//                   </button>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Password;



// import React, { useState, useRef } from 'react';
// import { ArrowLeft, Eye, EyeOff } from 'lucide-react';
// import { useNavigate } from 'react-router-dom';

// const Password = () => {
//   const navigate = useNavigate();
//   const [input, setInput] = useState('');
//   const [error, setError] = useState('');
//   const [isSubmitted, setIsSubmitted] = useState(false);
//   const [code, setCode] = useState(['', '', '', '']);
//   const [newPassword, setNewPassword] = useState('');
//   const [confirmPassword, setConfirmPassword] = useState('');
//   const [showNewPassword, setShowNewPassword] = useState(false);
//   const [showConfirmPassword, setShowConfirmPassword] = useState(false);
//   const inputRefs = [
//     useRef(null),
//     useRef(null),
//     useRef(null),
//     useRef(null)
//   ];

//   const validateInput = (value) => {
//     const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     const phonePattern = /^\+?[\d\s-]{10,}$/;

//     if (!value.trim()) {
//       return 'This field is required';
//     }
//     if (!emailPattern.test(value) && !phonePattern.test(value)) {
//       return 'Please enter a valid email or phone number';
//     }
//     return '';
//   };

//   const handleChange = (index, value) => {
//     if (value.match(/^[0-9]$/)) {
//       const newCode = [...code];
//       newCode[index] = value;
//       setCode(newCode);
      
//       // Move to next input if available
//       if (index < 3 && value !== '') {
//         inputRefs[index + 1].current.focus();
//       }
//     }
//   };

//   const handleKeyDown = (index, e) => {
//     if (e.key === 'Backspace') {
//       e.preventDefault();
//       const newCode = [...code];
      
//       if (code[index]) {
//         // If current input has a value, clear it
//         newCode[index] = '';
//         setCode(newCode);
//       } else if (index > 0) {
//         // If current input is empty and not first input, move to previous and clear it
//         newCode[index - 1] = '';
//         setCode(newCode);
//         inputRefs[index - 1].current.focus();
//       }
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const validationError = validateInput(input);
//     setError(validationError);

//     if (!validationError) {
//       try {
//         const formData = new FormData();
//         formData.append('email', input);

//         const response = await fetch("https://shilohbridal.pmpframe.com/api/login.php", {
//           method: "POST",
//           body: formData,
//         });

//         if (response.ok) {
//           setIsSubmitted(true);
//         } else {
//           setError('An error occurred. Please try again.');
//         }
//       } catch (err) {
//         setError('An error occurred. Please try again.');
//       }
//     }
//   };

//   const handleVerificationSubmit = (e) => {
//     e.preventDefault();
//     // Add your submission logic here
//     console.log('Submitted code:', code.join(''));
//   };

//   if (isSubmitted) {
//     return (
//       <div className="container-fluid d-flex align-items-center min-vh-100" 
//         style={{ 
//           backgroundImage: "url('https://shilohbridal.pmpframe.com/api/upload/login_banner.jpeg')", 
//           backgroundSize: "cover", 
//           backgroundPosition: "center", 
//         }}
//       >
//         <div className="container">
//           <div className="row justify-content-center">
//             <div className="col-md-6">
//               <div className="card mt-5">
//                 <div className="card-body">
//                   <div className="d-flex align-items-center mb-3">
//                     <ArrowLeft className="me-2" size={28} />
//                     <h2 className="mb-0 fs-3">Submit Your Code</h2>
//                   </div>
                  
//                   <p className="text-muted mb-4 fs-6">
//                     Please Enter The 4-Digit Code Sent To {input}
//                   </p>

//                   <form onSubmit={handleVerificationSubmit}>
//                     <div className="d-flex gap-3 mb-4">
//                       {code.map((digit, index) => (
//                         <input
//                           key={index}
//                           ref={inputRefs[index]}
//                           type="text"
//                           maxLength={1}
//                           value={digit}
//                           onChange={(e) => handleChange(index, e.target.value)}
//                           onKeyDown={(e) => handleKeyDown(index, e)}
//                           className="form-control text-center"
//                           style={{
//                             width: '3rem',
//                             height: '3rem',
//                             fontSize: '1.5rem'
//                           }}
//                         />
//                       ))}
//                     </div>

//                     <div className="mb-3">
//                       <label htmlFor="newPassword" className="form-label fw-medium">
//                         New Password
//                       </label>
//                       <div className="input-group">
//                         <input
//                           type={showNewPassword ? "text" : "password"}
//                           className="form-control"
//                           id="newPassword"
//                           value={newPassword}
//                           onChange={(e) => setNewPassword(e.target.value)}
//                         />
//                         <button
//                           className="btn btn-outline-secondary"
//                           type="button"
//                           onClick={() => setShowNewPassword(!showNewPassword)}
//                         >
//                           {showNewPassword ? <EyeOff size={20} /> : <Eye size={20} />}
//                         </button>
//                       </div>
//                     </div>

//                     <div className="mb-4">
//                       <label htmlFor="confirmPassword" className="form-label fw-medium">
//                         Confirm Password
//                       </label>
//                       <div className="input-group">
//                         <input
//                           type={showConfirmPassword ? "text" : "password"}
//                           className="form-control"
//                           id="confirmPassword"
//                           value={confirmPassword}
//                           onChange={(e) => setConfirmPassword(e.target.value)}
//                         />
//                         <button
//                           className="btn btn-outline-secondary"
//                           type="button"
//                           onClick={() => setShowConfirmPassword(!showConfirmPassword)}
//                         >
//                           {showConfirmPassword ? <EyeOff size={20} /> : <Eye size={20} />}
//                         </button>
//                       </div>
//                     </div>

//                     <button
//                       type="button"
//                       className="btn text-secondary fs-6 p-0 mb-3 border-0"
//                       style={{ background: 'none' }}
//                     >
//                       Resend Code
//                     </button>

//                     <button
//                       type="submit"
//                       className="btn w-100 py-2 mb-3 fs-5 text-white"
//                       style={{
//                         backgroundColor: '#902637',
//                         borderColor: '#902637'
//                       }}
//                     >
//                       Submit
//                     </button>

//                     <button
//                       type="button"
//                       onClick={() => {
//                         setIsSubmitted(false);
//                         setInput('');
//                       }}
//                       className="btn text-secondary fs-6 p-0 border-0"
//                       style={{ background: 'none' }}
//                     >
//                       Change Email Or Phone Number
//                     </button>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }

//   return (
//     <div className="container-fluid d-flex align-items-center min-vh-100" 
//       style={{ 
//         backgroundImage: "url('https://shilohbridal.pmpframe.com/api/upload/login_banner.jpeg')", 
//         backgroundSize: "cover", 
//         backgroundPosition: "center", 
//       }}
//     >
//       <div className="container">
//         <div className="row justify-content-center">
//           <div className="col-md-6">
//             <div className="card mt-5">
//               <div className="card-body">
//                 <div className="mb-4">
//                   <div 
//                     className="d-flex align-items-center" 
//                     onClick={() => navigate('/signin')} 
//                     style={{ cursor: 'pointer' }}
//                   >
//                     <ArrowLeft size={28} className="me-2" />
//                     <span className="fs-5">Forgot Password</span>
//                   </div>
//                 </div>

//                 <p className="text-muted mb-4">
//                   Please Enter Your Email Address or Mobile number To Receive A Verification Code
//                 </p>

//                 <form onSubmit={handleSubmit}>
//                   <div className="mb-3">
//                     <label
//                       htmlFor="emailOrPhone"
//                       className="form-label fw-medium"
//                     >
//                       Email or Phone Number
//                     </label>
//                     <input
//                       id="emailOrPhone"
//                       type="text"
//                       value={input}
//                       onChange={(e) => {
//                         setInput(e.target.value);
//                         if (error) setError('');
//                       }}
//                       className={`form-control ${error ? 'is-invalid' : ''}`}
//                       placeholder="Enter email or phone number"
//                     />
//                     {error && (
//                       <div className="invalid-feedback">{error}</div>
//                     )}
//                   </div>

//                   <button
//                     type="submit"
//                     className="btn btn-danger btn-sm w-100 py-2 fs-6"
//                     style={{ 
//                       backgroundColor: '#902637',
//                       borderColor: '#902637'
//                     }}
//                   >
//                     Submit
//                   </button>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Password;




// import React, { useState, useRef } from 'react';
// import { ArrowLeft, Eye, EyeOff } from 'lucide-react';
// import { useNavigate } from 'react-router-dom';

// const Password = () => {
//   const navigate = useNavigate();
//   const [input, setInput] = useState('');
//   const [error, setError] = useState('');
//   const [isSubmitted, setIsSubmitted] = useState(false);
//   const [code, setCode] = useState(['', '', '', '']);
//   const [newPassword, setNewPassword] = useState('');
//   const [confirmPassword, setConfirmPassword] = useState('');
//   const [showNewPassword, setShowNewPassword] = useState(false);
//   const [showConfirmPassword, setShowConfirmPassword] = useState(false);
//   const inputRefs = [
//     useRef(null),
//     useRef(null),
//     useRef(null),
//     useRef(null)
//   ];

//   const validateInput = (value) => {
//     const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     const phonePattern = /^\+?[\d\s-]{10,}$/;

//     if (!value.trim()) {
//       return 'This field is required';
//     }
//     if (!emailPattern.test(value) && !phonePattern.test(value)) {
//       return 'Please enter a valid email or phone number';
//     }
//     return '';
//   };

//   const handleChange = (index, value) => {
//     if (value.match(/^[0-9]$/)) {
//       const newCode = [...code];
//       newCode[index] = value;
//       setCode(newCode);
      
//       // Move to next input if available
//       if (index < 3 && value !== '') {
//         inputRefs[index + 1].current.focus();
//       }
//     }
//   };

//   const handleKeyDown = (index, e) => {
//     if (e.key === 'Backspace') {
//       e.preventDefault();
//       const newCode = [...code];
      
//       if (code[index]) {
//         // If current input has a value, clear it
//         newCode[index] = '';
//         setCode(newCode);
//       } else if (index > 0) {
//         // If current input is empty and not first input, move to previous and clear it
//         newCode[index - 1] = '';
//         setCode(newCode);
//         inputRefs[index - 1].current.focus();
//       }
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const validationError = validateInput(input);
//     setError(validationError);

//     if (!validationError) {
//       try {
//         const formData = new FormData();
//         formData.append('email_mobile', input);
//         formData.append('action', 'online');

//         const response = await fetch("https://shilohbridal.pmpframe.com/api/forgot_password.php", {
//           method: "POST",
//           body: formData,
//         });

//         const data = await response.json();
        
//         if (response.ok) {
//           setIsSubmitted(true);
//         } else {
//           setError(data.message || 'An error occurred. Please try again.');
//         }
//       } catch (err) {
//         setError('An error occurred. Please try again.');
//       }
//     }
//   };

//   const handleVerificationSubmit = (e) => {
//     e.preventDefault();
//     // Add your submission logic here
//     console.log('Submitted code:', code.join(''));
//   };

//   if (isSubmitted) {
//     return (
//       <div className="container-fluid d-flex align-items-center min-vh-100" 
//         style={{ 
//           backgroundImage: "url('https://shilohbridal.pmpframe.com/api/upload/login_banner.jpeg')", 
//           backgroundSize: "cover", 
//           backgroundPosition: "center", 
//         }}
//       >
//         <div className="container">
//           <div className="row justify-content-center">
//             <div className="col-md-6">
//               <div className="card mt-5">
//                 <div className="card-body">
//                   <div className="d-flex align-items-center mb-3">
//                     <ArrowLeft className="me-2" size={28} />
//                     <h2 className="mb-0 fs-3">Submit Your Code</h2>
//                   </div>
                  
//                   <p className="text-muted mb-4 fs-6">
//                     Please Enter The 4-Digit Code Sent To {input}
//                   </p>

//                   <form onSubmit={handleVerificationSubmit}>
//                     <div className="d-flex gap-3 mb-4">
//                       {code.map((digit, index) => (
//                         <input
//                           key={index}
//                           ref={inputRefs[index]}
//                           type="text"
//                           maxLength={1}
//                           value={digit}
//                           onChange={(e) => handleChange(index, e.target.value)}
//                           onKeyDown={(e) => handleKeyDown(index, e)}
//                           className="form-control text-center"
//                           style={{
//                             width: '3rem',
//                             height: '3rem',
//                             fontSize: '1.5rem'
//                           }}
//                         />
//                       ))}
//                     </div>

//                     <div className="mb-3">
//                       <label htmlFor="newPassword" className="form-label fw-medium">
//                         New Password
//                       </label>
//                       <div className="input-group">
//                         <input
//                           type={showNewPassword ? "text" : "password"}
//                           className="form-control"
//                           id="newPassword"
//                           value={newPassword}
//                           onChange={(e) => setNewPassword(e.target.value)}
//                         />
//                         <button
//                           className="btn btn-outline-secondary"
//                           type="button"
//                           onClick={() => setShowNewPassword(!showNewPassword)}
//                         >
//                           {showNewPassword ? <EyeOff size={20} /> : <Eye size={20} />}
//                         </button>
//                       </div>
//                     </div>

//                     <div className="mb-4">
//                       <label htmlFor="confirmPassword" className="form-label fw-medium">
//                         Confirm Password
//                       </label>
//                       <div className="input-group">
//                         <input
//                           type={showConfirmPassword ? "text" : "password"}
//                           className="form-control"
//                           id="confirmPassword"
//                           value={confirmPassword}
//                           onChange={(e) => setConfirmPassword(e.target.value)}
//                         />
//                         <button
//                           className="btn btn-outline-secondary"
//                           type="button"
//                           onClick={() => setShowConfirmPassword(!showConfirmPassword)}
//                         >
//                           {showConfirmPassword ? <EyeOff size={20} /> : <Eye size={20} />}
//                         </button>
//                       </div>
//                     </div>

//                     <button
//                       type="button"
//                       className="btn text-secondary fs-6 p-0 mb-3 border-0"
//                       style={{ background: 'none' }}
//                     >
//                       Resend Code
//                     </button>

//                     <button
//                       type="submit"
//                       className="btn w-100 py-2 mb-3 fs-5 text-white"
//                       style={{
//                         backgroundColor: '#902637',
//                         borderColor: '#902637'
//                       }}
//                     >
//                       Submit
//                     </button>

//                     <button
//                       type="button"
//                       onClick={() => {
//                         setIsSubmitted(false);
//                         setInput('');
//                       }}
//                       className="btn text-secondary fs-6 p-0 border-0"
//                       style={{ background: 'none' }}
//                     >
//                       Change Email Or Phone Number
//                     </button>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }

//   return (
//     <div className="container-fluid d-flex align-items-center min-vh-100" 
//       style={{ 
//         backgroundImage: "url('https://shilohbridal.pmpframe.com/api/upload/login_banner.jpeg')", 
//         backgroundSize: "cover", 
//         backgroundPosition: "center", 
//       }}
//     >
//       <div className="container">
//         <div className="row justify-content-center">
//           <div className="col-md-6">
//             <div className="card mt-5">
//               <div className="card-body">
//                 <div className="mb-4">
//                   <div 
//                     className="d-flex align-items-center" 
//                     onClick={() => navigate('/signin')} 
//                     style={{ cursor: 'pointer' }}
//                   >
//                     <ArrowLeft size={28} className="me-2" />
//                     <span className="fs-5">Forgot Password</span>
//                   </div>
//                 </div>

//                 <p className="text-muted mb-4">
//                   Please Enter Your Email Address or Mobile number To Receive A Verification Code
//                 </p>

//                 <form onSubmit={handleSubmit}>
//                   <div className="mb-3">
//                     <label
//                       htmlFor="emailOrPhone"
//                       className="form-label fw-medium"
//                     >
//                       Email or Phone Number
//                     </label>
//                     <input
//                       id="emailOrPhone"
//                       type="text"
//                       value={input}
//                       onChange={(e) => {
//                         setInput(e.target.value);
//                         if (error) setError('');
//                       }}
//                       className={`form-control ${error ? 'is-invalid' : ''}`}
//                       placeholder="Enter email or phone number"
//                     />
//                     {error && (
//                       <div className="invalid-feedback">{error}</div>
//                     )}
//                   </div>

//                   <button
//                     type="submit"
//                     className="btn btn-danger btn-sm w-100 py-2 fs-6"
//                     style={{ 
//                       backgroundColor: '#902637',
//                       borderColor: '#902637'
//                     }}
//                   >
//                     Submit
//                   </button>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Password;




// import React, { useState, useRef } from 'react';
// import { ArrowLeft, Eye, EyeOff } from 'lucide-react';
// import { useNavigate } from 'react-router-dom';

// const Password = () => {
//   const navigate = useNavigate();
//   const [resendDisabled, setResendDisabled] = useState(false);
//   const [resendTimer, setResendTimer] = useState(0);
//   const [input, setInput] = useState('');
//   const [error, setError] = useState('');
//   const [isSubmitted, setIsSubmitted] = useState(false);
//   const [code, setCode] = useState(['', '', '', '']);
//   const [newPassword, setNewPassword] = useState('');
//   const [confirmPassword, setConfirmPassword] = useState('');
//   const [showNewPassword, setShowNewPassword] = useState(false);
//   const [showConfirmPassword, setShowConfirmPassword] = useState(false);
//   const inputRefs = [
//     useRef(null),
//     useRef(null),
//     useRef(null),
//     useRef(null)
//   ];

//   const validateInput = (value) => {
//     const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     const phonePattern = /^\+?[\d\s-]{10,}$/;

//     if (!value.trim()) {
//       return 'This field is required';
//     }
//     if (!emailPattern.test(value) && !phonePattern.test(value)) {
//       return 'Please enter a valid email or phone number';
//     }
//     return '';
//   };

//   const handleChange = (index, value) => {
//     if (value.match(/^[0-9]$/)) {
//       const newCode = [...code];
//       newCode[index] = value;
//       setCode(newCode);
      
//       // Move to next input if available
//       if (index < 3 && value !== '') {
//         inputRefs[index + 1].current.focus();
//       }
//     }
//   };

//   const handleKeyDown = (index, e) => {
//     if (e.key === 'Backspace') {
//       e.preventDefault();
//       const newCode = [...code];
      
//       if (code[index]) {
//         // If current input has a value, clear it
//         newCode[index] = '';
//         setCode(newCode);
//       } else if (index > 0) {
//         // If current input is empty and not first input, move to previous and clear it
//         newCode[index - 1] = '';
//         setCode(newCode);
//         inputRefs[index - 1].current.focus();
//       }
//     }
//   };

//   const handleResendCode = async () => {
//     try {
//       setResendDisabled(true);
//       setResendTimer(30); // Start 30 second timer

//       const formData = new FormData();
//       formData.append('email_mobile', input);
//       formData.append('action', 'online');

//       const response = await fetch("https://shilohbridal.pmpframe.com/api/forgot_password.php", {
//         method: "POST",
//         body: formData,
//       });

//       const data = await response.json();
      
//       if (!response.ok) {
//         setError(data.message || 'Failed to resend code. Please try again.');
//       }

//       // Start countdown timer
//       const timer = setInterval(() => {
//         setResendTimer((prev) => {
//           if (prev <= 1) {
//             clearInterval(timer);
//             setResendDisabled(false);
//             return 0;
//           }
//           return prev - 1;
//         });
//       }, 1000);

//     } catch (err) {
//       setError('Failed to resend code. Please try again.');
//       setResendDisabled(false);
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const validationError = validateInput(input);
//     setError(validationError);

//     if (!validationError) {
//       try {
//         const formData = new FormData();
//         formData.append('email_mobile', input);
//         formData.append('action', 'online');

//         const response = await fetch("https://shilohbridal.pmpframe.com/api/forgot_password.php", {
//           method: "POST",
//           body: formData,
//         });

//         const data = await response.json();
        
//         if (response.ok) {
//           setIsSubmitted(true);
//         } else {
//           setError(data.message || 'An error occurred. Please try again.');
//         }
//       } catch (err) {
//         setError('An error occurred. Please try again.');
//       }
//     }
//   };

//   const handleVerificationSubmit = (e) => {
//     e.preventDefault();
//     // Add your submission logic here
//     console.log('Submitted code:', code.join(''));
//   };

//   if (isSubmitted) {
//     return (
//       <div className="container-fluid d-flex align-items-center min-vh-100" 
//         style={{ 
//           backgroundImage: "url('https://shilohbridal.pmpframe.com/api/upload/login_banner.jpeg')", 
//           backgroundSize: "cover", 
//           backgroundPosition: "center", 
//         }}
//       >
//         <div className="container">
//           <div className="row justify-content-center">
//             <div className="col-md-6">
//               <div className="card mt-5">
//                 <div className="card-body">
//                   <div className="d-flex align-items-center mb-3">
//                     <ArrowLeft className="me-2" size={28} />
//                     <h2 className="mb-0 fs-3">Submit Your Code</h2>
//                   </div>
                  
//                   <p className="text-muted mb-4 fs-6">
//                     Please Enter The 4-Digit Code Sent To {input}
//                   </p>

//                   <form onSubmit={handleVerificationSubmit}>
//                     <div className="d-flex gap-3 mb-4">
//                       {code.map((digit, index) => (
//                         <input
//                           key={index}
//                           ref={inputRefs[index]}
//                           type="text"
//                           maxLength={1}
//                           value={digit}
//                           onChange={(e) => handleChange(index, e.target.value)}
//                           onKeyDown={(e) => handleKeyDown(index, e)}
//                           className="form-control text-center"
//                           style={{
//                             width: '3rem',
//                             height: '3rem',
//                             fontSize: '1.5rem'
//                           }}
//                         />
//                       ))}
//                     </div>

//                     <div className="mb-3">
//                       <label htmlFor="newPassword" className="form-label fw-medium">
//                         New Password
//                       </label>
//                       <div className="input-group">
//                         <input
//                           type={showNewPassword ? "text" : "password"}
//                           className="form-control"
//                           id="newPassword"
//                           value={newPassword}
//                           onChange={(e) => setNewPassword(e.target.value)}
//                         />
//                         <button
//                           className="btn btn-outline-secondary"
//                           type="button"
//                           onClick={() => setShowNewPassword(!showNewPassword)}
//                         >
//                           {showNewPassword ? <EyeOff size={20} /> : <Eye size={20} />}
//                         </button>
//                       </div>
//                     </div>

//                     <div className="mb-4">
//                       <label htmlFor="confirmPassword" className="form-label fw-medium">
//                         Confirm Password
//                       </label>
//                       <div className="input-group">
//                         <input
//                           type={showConfirmPassword ? "text" : "password"}
//                           className="form-control"
//                           id="confirmPassword"
//                           value={confirmPassword}
//                           onChange={(e) => setConfirmPassword(e.target.value)}
//                         />
//                         <button
//                           className="btn btn-outline-secondary"
//                           type="button"
//                           onClick={() => setShowConfirmPassword(!showConfirmPassword)}
//                         >
//                           {showConfirmPassword ? <EyeOff size={20} /> : <Eye size={20} />}
//                         </button>
//                       </div>
//                     </div>

//                     <button
//                       type="button"
//                       onClick={handleResendCode}
//                       disabled={resendDisabled}
//                       className="btn text-secondary fs-6 p-0 mb-3 border-0"
//                       style={{ background: 'none' }}
//                     >
//                       {resendTimer > 0 ? `Resend Code (${resendTimer}s)` : 'Resend Code'}
//                     </button>

//                     <button
//                       type="submit"
//                       className="btn w-100 py-2 mb-3 fs-5 text-white"
//                       style={{
//                         backgroundColor: '#902637',
//                         borderColor: '#902637'
//                       }}
//                     >
//                       Submit
//                     </button>

//                     <button
//                       type="button"
//                       onClick={() => {
//                         setIsSubmitted(false);
//                         setInput('');
//                       }}
//                       className="btn text-secondary fs-6 p-0 border-0"
//                       style={{ background: 'none' }}
//                     >
//                       Change Email Or Phone Number
//                     </button>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }

//   return (
//     <div className="container-fluid d-flex align-items-center min-vh-100" 
//       style={{ 
//         backgroundImage: "url('https://shilohbridal.pmpframe.com/api/upload/login_banner.jpeg')", 
//         backgroundSize: "cover", 
//         backgroundPosition: "center", 
//       }}
//     >
//       <div className="container">
//         <div className="row justify-content-center">
//           <div className="col-md-6">
//             <div className="card mt-5">
//               <div className="card-body">
//                 <div className="mb-4">
//                   <div 
//                     className="d-flex align-items-center" 
//                     onClick={() => navigate('/signin')} 
//                     style={{ cursor: 'pointer' }}
//                   >
//                     <ArrowLeft size={28} className="me-2" />
//                     <span className="fs-5">Forgot Password</span>
//                   </div>
//                 </div>

//                 <p className="text-muted mb-4">
//                   Please Enter Your Email Address or Mobile number To Receive A Verification Code
//                 </p>

//                 <form onSubmit={handleSubmit}>
//                   <div className="mb-3">
//                     <label
//                       htmlFor="emailOrPhone"
//                       className="form-label fw-medium"
//                     >
//                       Email or Phone Number
//                     </label>
//                     <input
//                       id="emailOrPhone"
//                       type="text"
//                       value={input}
//                       onChange={(e) => {
//                         setInput(e.target.value);
//                         if (error) setError('');
//                       }}
//                       className={`form-control ${error ? 'is-invalid' : ''}`}
//                       placeholder="Enter email or phone number"
//                     />
//                     {error && (
//                       <div className="invalid-feedback">{error}</div>
//                     )}
//                   </div>

//                   <button
//                     type="submit"
//                     className="btn btn-danger btn-sm w-100 py-2 fs-6"
//                     style={{ 
//                       backgroundColor: '#902637',
//                       borderColor: '#902637'
//                     }}
//                   >
//                     Submit
//                   </button>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Password;




// import React, { useState, useRef } from 'react';
// import { ArrowLeft, Eye, EyeOff } from 'lucide-react';
// import { useNavigate } from 'react-router-dom';

// const Password = () => {
//   const navigate = useNavigate();
//   const [submitError, setSubmitError] = useState('');
//   const [passwordError, setPasswordError] = useState('');
//   const [isLoading, setIsLoading] = useState(false);
//   const [resendDisabled, setResendDisabled] = useState(false);
//   const [resendTimer, setResendTimer] = useState(0);
//   const [input, setInput] = useState('');
//   const [error, setError] = useState('');
//   const [isSubmitted, setIsSubmitted] = useState(false);
//   const [code, setCode] = useState(['', '', '', '']);
//   const [newPassword, setNewPassword] = useState('');
//   const [confirmPassword, setConfirmPassword] = useState('');
//   const [showNewPassword, setShowNewPassword] = useState(false);
//   const [showConfirmPassword, setShowConfirmPassword] = useState(false);
//   const inputRefs = [
//     useRef(null),
//     useRef(null),
//     useRef(null),
//     useRef(null)
//   ];

//   const validateInput = (value) => {
//     const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     const phonePattern = /^\+?[\d\s-]{10,}$/;

//     if (!value.trim()) {
//       return 'This field is required';
//     }
//     if (!emailPattern.test(value) && !phonePattern.test(value)) {
//       return 'Please enter a valid email or phone number';
//     }
//     return '';
//   };

//   const handleChange = (index, value) => {
//     if (value.match(/^[0-9]$/)) {
//       const newCode = [...code];
//       newCode[index] = value;
//       setCode(newCode);
      
//       // Move to next input if available
//       if (index < 3 && value !== '') {
//         inputRefs[index + 1].current.focus();
//       }
//     }
//   };

//   const handleKeyDown = (index, e) => {
//     if (e.key === 'Backspace') {
//       e.preventDefault();
//       const newCode = [...code];
      
//       if (code[index]) {
//         // If current input has a value, clear it
//         newCode[index] = '';
//         setCode(newCode);
//       } else if (index > 0) {
//         // If current input is empty and not first input, move to previous and clear it
//         newCode[index - 1] = '';
//         setCode(newCode);
//         inputRefs[index - 1].current.focus();
//       }
//     }
//   };

//   const handleResendCode = async () => {
//     try {
//       setResendDisabled(true);
//       setResendTimer(30); // Start 30 second timer

//       const formData = new FormData();
//       formData.append('email_mobile', input);
//       formData.append('action', 'online');

//       const response = await fetch("https://shilohbridal.pmpframe.com/api/forgot_password.php", {
//         method: "POST",
//         body: formData,
//       });

//       const data = await response.json();
      
//       if (!response.ok) {
//         setError(data.message || 'Failed to resend code. Please try again.');
//       }

//       // Start countdown timer
//       const timer = setInterval(() => {
//         setResendTimer((prev) => {
//           if (prev <= 1) {
//             clearInterval(timer);
//             setResendDisabled(false);
//             return 0;
//           }
//           return prev - 1;
//         });
//       }, 1000);

//     } catch (err) {
//       setError('Failed to resend code. Please try again.');
//       setResendDisabled(false);
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const validationError = validateInput(input);
//     setError(validationError);

//     if (!validationError) {
//       try {
//         const formData = new FormData();
//         formData.append('email_mobile', input);
//         formData.append('action', 'online');

//         const response = await fetch("https://shilohbridal.pmpframe.com/api/forgot_password.php", {
//           method: "POST",
//           body: formData,
//         });

//         const data = await response.json();
        
//         if (response.ok) {
//           setIsSubmitted(true);
//         } else {
//           setError(data.message || 'An error occurred. Please try again.');
//         }
//       } catch (err) {
//         setError('An error occurred. Please try again.');
//       }
//     }
//   };

//   const validatePasswords = () => {
//     if (!newPassword) {
//       setPasswordError('New password is required');
//       return false;
//     }
//     if (!confirmPassword) {
//       setPasswordError('Confirm password is required');
//       return false;
//     }
//     if (newPassword !== confirmPassword) {
//       setPasswordError('Passwords do not match');
//       return false;
//     }
//     if (newPassword.length < 6) {
//       setPasswordError('Password should be at least 6 characters long');
//       return false;
//     }
//     return true;
//   };

//   const handleVerificationSubmit = async (e) => {
//     e.preventDefault();
//     setSubmitError('');
//     setPasswordError('');

//     // Validate OTP
//     const otp = code.join('');
//     if (otp.length !== 4) {
//       setSubmitError('Please enter the complete 4-digit code');
//       return;
//     }

//     // Validate passwords
//     if (!validatePasswords()) {
//       return;
//     }

//     setIsLoading(true);

//     try {
//       const formData = new FormData();
//       formData.append('email_mobile', input);
//       formData.append('otp', otp);
//       formData.append('action', 'update_password');
//       formData.append('new_password', newPassword);
//       formData.append('confirm_password', confirmPassword);

//       const response = await fetch("https://shilohbridal.pmpframe.com/api/update_password.php", {
//         method: "POST",
//         body: formData,
//       });

//       const data = await response.json();

//       if (data.success) {
//         // Password updated successfully
//         navigate('/signin'); // Redirect to signin page
//       } else {
//         setSubmitError(data.message || 'Failed to update password. Please try again.');
//       }
//     } catch (err) {
//       setSubmitError('An error occurred. Please try again.');
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   if (isSubmitted) {
//     return (
//       <div className="container-fluid d-flex align-items-center min-vh-100" 
//         style={{ 
//           backgroundImage: "url('https://shilohbridal.pmpframe.com/api/upload/login_banner.jpeg')", 
//           backgroundSize: "cover", 
//           backgroundPosition: "center", 
//         }}
//       >
//         <div className="container">
//           <div className="row justify-content-center">
//             <div className="col-md-6">
//               <div className="card mt-5">
//                 <div className="card-body">
//                   <div className="d-flex align-items-center mb-3">
//                     <ArrowLeft className="me-2" size={28} />
//                     <h2 className="mb-0 fs-3">Submit Your Code</h2>
//                   </div>
                  
//                   <p className="text-muted mb-4 fs-6">
//                     Please Enter The 4-Digit Code Sent To {input}
//                   </p>

//                   <form onSubmit={handleVerificationSubmit}>
//                     <div className="d-flex gap-3 mb-4">
//                       {code.map((digit, index) => (
//                         <input
//                           key={index}
//                           ref={inputRefs[index]}
//                           type="text"
//                           maxLength={1}
//                           value={digit}
//                           onChange={(e) => handleChange(index, e.target.value)}
//                           onKeyDown={(e) => handleKeyDown(index, e)}
//                           className={`form-control text-center ${submitError ? 'is-invalid' : ''}`}
//                           style={{
//                             width: '3rem',
//                             height: '3rem',
//                             fontSize: '1.5rem'
//                           }}
//                         />
//                       ))}
//                     </div>
//                     {submitError && (
//                       <div className="alert alert-danger py-2 mb-3">{submitError}</div>
//                     )}

//                     <div className="mb-3">
//                       <label htmlFor="newPassword" className="form-label fw-medium">
//                         New Password
//                       </label>
//                       <div className="input-group">
//                         <input
//                           type={showNewPassword ? "text" : "password"}
//                           className={`form-control ${passwordError ? 'is-invalid' : ''}`}
//                           id="newPassword"
//                           value={newPassword}
//                           onChange={(e) => {
//                             setNewPassword(e.target.value);
//                             setPasswordError('');
//                           }}
//                         />
//                         <button
//                           className="btn btn-outline-secondary"
//                           type="button"
//                           onClick={() => setShowNewPassword(!showNewPassword)}
//                         >
//                           {showNewPassword ? <EyeOff size={20} /> : <Eye size={20} />}
//                         </button>
//                       </div>
//                     </div>

//                     <div className="mb-4">
//                       <label htmlFor="confirmPassword" className="form-label fw-medium">
//                         Confirm Password
//                       </label>
//                       <div className="input-group">
//                         <input
//                           type={showConfirmPassword ? "text" : "password"}
//                           className={`form-control ${passwordError ? 'is-invalid' : ''}`}
//                           id="confirmPassword"
//                           value={confirmPassword}
//                           onChange={(e) => {
//                             setConfirmPassword(e.target.value);
//                             setPasswordError('');
//                           }}
//                         />
//                         <button
//                           className="btn btn-outline-secondary"
//                           type="button"
//                           onClick={() => setShowConfirmPassword(!showConfirmPassword)}
//                         >
//                           {showConfirmPassword ? <EyeOff size={20} /> : <Eye size={20} />}
//                         </button>
//                       </div>
//                       {passwordError && (
//                         <div className="invalid-feedback d-block">{passwordError}</div>
//                       )}
//                     </div>

//                     <button
//                       type="button"
//                       onClick={handleResendCode}
//                       disabled={resendDisabled}
//                       className="btn text-secondary fs-6 p-0 mb-3 border-0"
//                       style={{ background: 'none' }}
//                     >
//                       {resendTimer > 0 ? `Resend Code (${resendTimer}s)` : 'Resend Code'}
//                     </button>

//                     <button
//                       type="submit"
//                       className="btn w-100 py-2 mb-3 fs-5 text-white"
//                       style={{
//                         backgroundColor: '#902637',
//                         borderColor: '#902637'
//                       }}
//                       disabled={isLoading}
//                     >
//                       {isLoading ? 'Updating...' : 'Submit'}
//                     </button>

//                     <button
//                       type="button"
//                       onClick={() => {
//                         setIsSubmitted(false);
//                         setInput('');
//                       }}
//                       className="btn text-secondary fs-6 p-0 border-0"
//                       style={{ background: 'none' }}
//                     >
//                       Change Email Or Phone Number
//                     </button>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }

//   return (
//     <div className="container-fluid d-flex align-items-center min-vh-100" 
//       style={{ 
//         backgroundImage: "url('https://shilohbridal.pmpframe.com/api/upload/login_banner.jpeg')", 
//         backgroundSize: "cover", 
//         backgroundPosition: "center", 
//       }}
//     >
//       <div className="container">
//         <div className="row justify-content-center">
//           <div className="col-md-6">
//             <div className="card mt-5">
//               <div className="card-body">
//                 <div className="mb-4">
//                   <div 
//                     className="d-flex align-items-center" 
//                     onClick={() => navigate('/signin')} 
//                     style={{ cursor: 'pointer' }}
//                   >
//                     <ArrowLeft size={28} className="me-2" />
//                     <span className="fs-5">Forgot Password</span>
//                   </div>
//                 </div>

//                 <p className="text-muted mb-4">
//                   Please Enter Your Email Address or Mobile number To Receive A Verification Code
//                 </p>

//                 <form onSubmit={handleSubmit}>
//                   <div className="mb-3">
//                     <label
//                       htmlFor="emailOrPhone"
//                       className="form-label fw-medium"
//                     >
//                       Email or Phone Number
//                     </label>
//                     <input
//                       id="emailOrPhone"
//                       type="text"
//                       value={input}
//                       onChange={(e) => {
//                         setInput(e.target.value);
//                         if (error) setError('');
//                       }}
//                       className={`form-control ${error ? 'is-invalid' : ''}`}
//                       placeholder="Enter email or phone number"
//                     />
//                     {error && (
//                       <div className="invalid-feedback">{error}</div>
//                     )}
//                   </div>

//                   <button
//                     type="submit"
//                     className="btn btn-danger btn-sm w-100 py-2 fs-6"
//                     style={{ 
//                       backgroundColor: '#902637',
//                       borderColor: '#902637'
//                     }}
//                   >
//                     Submit
//                   </button>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Password;



// import React, { useState, useRef } from 'react';
// import { ArrowLeft, Eye, EyeOff } from 'lucide-react';
// import { useNavigate } from 'react-router-dom';

// const Password = () => {
//   const navigate = useNavigate();
//   const [submitError, setSubmitError] = useState('');
//   const [passwordError, setPasswordError] = useState('');
//   const [isLoading, setIsLoading] = useState(false);
//   const [resendDisabled, setResendDisabled] = useState(false);
//   const [resendTimer, setResendTimer] = useState(0);
//   const [input, setInput] = useState('');
//   const [error, setError] = useState('');
//   const [isSubmitted, setIsSubmitted] = useState(false);
//   const [code, setCode] = useState(['', '', '', '']);
//   const [newPassword, setNewPassword] = useState('');
//   const [confirmPassword, setConfirmPassword] = useState('');
//   const [showNewPassword, setShowNewPassword] = useState(false);
//   const [showConfirmPassword, setShowConfirmPassword] = useState(false);

//   const [isOtpVerified, setIsOtpVerified] = useState(false); 
//   const inputRefs = [
//     useRef(null),
//     useRef(null),
//     useRef(null),
//     useRef(null)
//   ];

//   const validateInput = (value) => {
//     const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     const phonePattern = /^\+?[\d\s-]{10,}$/;

//     if (!value.trim()) {
//       return 'This field is required';
//     }
//     if (!emailPattern.test(value) && !phonePattern.test(value)) {
//       return 'Please enter a valid email or phone number';
//     }
//     return '';
//   };

//   const handleChange = (index, value) => {
//     if (value.match(/^[0-9]$/)) {
//       const newCode = [...code];
//       newCode[index] = value;
//       setCode(newCode);
      
//       if (index < 3 && value !== '') {
//         inputRefs[index + 1].current.focus();
//       }
//     }
//   };

//   const handleKeyDown = (index, e) => {
//     if (e.key === 'Backspace') {
//       e.preventDefault();
//       const newCode = [...code];
      
//       if (code[index]) {
//         newCode[index] = '';
//         setCode(newCode);
//       } else if (index > 0) {
//         newCode[index - 1] = '';
//         setCode(newCode);
//         inputRefs[index - 1].current.focus();
//       }
//     }
//   };

//   const handleResendCode = async () => {
//     try {
//       setResendDisabled(true);
//       setResendTimer(30);

//       const formData = new FormData();
//       formData.append('email_mobile', input);
//       formData.append('action', 'online');

//       const response = await fetch("https://shilohbridal.pmpframe.com/api/forgot_password.php", {
//         method: "POST",
//         body: formData,
//       });

//       const data = await response.json();
      
//       if (!response.ok) {
//         setError(data.message || 'Failed to resend code. Please try again.');
//       }

//       const timer = setInterval(() => {
//         setResendTimer((prev) => {
//           if (prev <= 1) {
//             clearInterval(timer);
//             setResendDisabled(false);
//             return 0;
//           }
//           return prev - 1;
//         });
//       }, 1000);

//     } catch (err) {
//       setError('Failed to resend code. Please try again.');
//       setResendDisabled(false);
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const validationError = validateInput(input);
//     setError(validationError);

//     if (!validationError) {
//       try {
//         const formData = new FormData();
//         formData.append('email_mobile', input);
//         formData.append('action', 'online');

//         const response = await fetch("https://shilohbridal.pmpframe.com/api/forgot_password.php", {
//           method: "POST",
//           body: formData,
//         });

//         const data = await response.json();
        
//         if (response.ok) {
//           setIsSubmitted(true);
//         } else {
//           setError(data.message || 'An error occurred. Please try again.');
//         }
//       } catch (err) {
//         setError('An error occurred. Please try again.');
//       }
//     }
//   };

//   const validatePasswords = () => {
//     if (!newPassword) {
//       setPasswordError('New password is required');
//       return false;
//     }
//     if (!confirmPassword) {
//       setPasswordError('Confirm password is required');
//       return false;
//     }
//     if (newPassword !== confirmPassword) {
//       setPasswordError('Passwords do not match');
//       return false;
//     }
//     if (newPassword.length < 6) {
//       setPasswordError('Password should be at least 6 characters long');
//       return false;
//     }
//     return true;
//   };


//   const verifyOtp = async (otp) => {
//     try {
//       const formData = new FormData();
//       formData.append('email_mobile', input);
//       formData.append('otp', otp);
//       formData.append('action', 'verify_otp');

//       const response = await fetch("https://shilohbridal.pmpframe.com/api/update_password.php", {
//         method: "POST",
//         body: formData,
//       });

//       const data = await response.json();
      
//       if (data.success) {
//         setIsOtpVerified(true);
//         setSubmitError('');
//         return true;
//       } else {
//         setSubmitError(data.message || 'Invalid OTP. Please try again.');
//         return false;
//       }
//     } catch (err) {
//       setSubmitError('Failed to verify OTP. Please try again.');
//       return false;
//     }
//   };

//   const handleVerificationSubmit = async (e) => {
//     e.preventDefault();
//     setSubmitError('');
//     setPasswordError('');

//     const otp = code.join('');
//     if (otp.length !== 4) {
//       setSubmitError('Please enter the complete 4-digit code');
//       return;
//     }

//     if (!isOtpVerified) {
//       const otpVerified = await verifyOtp(otp);
//       if (!otpVerified) return;
//     } else {
//       if (!validatePasswords()) return;

//       setIsLoading(true);
//       try {
//         const formData = new FormData();
//         formData.append('email_mobile', input);
//         formData.append('otp', otp);
//         formData.append('action', 'update_password');
//         formData.append('new_password', newPassword);
//         formData.append('confirm_password', confirmPassword);

//         const response = await fetch("https://shilohbridal.pmpframe.com/api/update_password.php", {
//           method: "POST",
//           body: formData,
//         });

//         const data = await response.json();

//         if (data.success) {
//           navigate('/signin');
//         } else {
//           setSubmitError(data.message || 'Failed to update password. Please try again.');
//         }
//       } catch (err) {
//         setSubmitError('An error occurred. Please try again.');
//       } finally {
//         setIsLoading(false);
//       }
//     }
//   };

//   if (isSubmitted) {
//     return (
//       <div className="container-fluid d-flex align-items-center min-vh-100" 
//         style={{ 
//           backgroundImage: "url('https://shilohbridal.pmpframe.com/api/upload/login_banner.jpeg')", 
//           backgroundSize: "cover", 
//           backgroundPosition: "center", 
//         }}
//       >
//         <div className="container">
//           <div className="row justify-content-center">
//             <div className="col-md-6">
//               <div className="card mt-5">
//                 <div className="card-body">
//                   <div className="d-flex align-items-center mb-3">
//                     <ArrowLeft className="me-2" size={28} />
//                     <h2 className="mb-0 fs-3">Submit Your Code</h2>
//                   </div>
                  
//                   <p className="text-muted mb-4 fs-6">
//                     Please Enter The 4-Digit Code Sent To {input}
//                   </p>

//                   <form onSubmit={handleVerificationSubmit}>
//                     <div className="d-flex gap-3 mb-4">
//                       {code.map((digit, index) => (
//                         <input
//                           key={index}
//                           ref={inputRefs[index]}
//                           type="text"
//                           maxLength={1}
//                           value={digit}
//                           onChange={(e) => handleChange(index, e.target.value)}
//                           onKeyDown={(e) => handleKeyDown(index, e)}
//                           className="form-control text-center"
//                           style={{
//                             width: '3rem',
//                             height: '3rem',
//                             fontSize: '1.5rem'
//                           }}
//                         />
//                       ))}
//                     </div>
//                     {submitError && (
//                       <div className="alert alert-danger py-2 mb-3">{submitError}</div>
//                     )}

//                     {isOtpVerified && (
//                       <>
//                         <div className="mb-3">
//                           <label htmlFor="newPassword" className="form-label fw-medium">
//                             New Password
//                           </label>
//                           <div className="input-group">
//                             <input
//                               type={showNewPassword ? "text" : "password"}
//                               className={`form-control ${passwordError ? 'is-invalid' : ''}`}
//                               id="newPassword"
//                               value={newPassword}
//                               onChange={(e) => {
//                                 setNewPassword(e.target.value);
//                                 setPasswordError('');
//                               }}
//                             />
//                             <button
//                               className="btn btn-outline-secondary"
//                               type="button"
//                               onClick={() => setShowNewPassword(!showNewPassword)}
//                             >
//                               {showNewPassword ? <EyeOff size={20} /> : <Eye size={20} />}
//                             </button>
//                           </div>
//                         </div>

//                         <div className="mb-4">
//                           <label htmlFor="confirmPassword" className="form-label fw-medium">
//                             Confirm Password
//                           </label>
//                           <div className="input-group">
//                             <input
//                               type={showConfirmPassword ? "text" : "password"}
//                               className={`form-control ${passwordError ? 'is-invalid' : ''}`}
//                               id="confirmPassword"
//                               value={confirmPassword}
//                               onChange={(e) => {
//                                 setConfirmPassword(e.target.value);
//                                 setPasswordError('');
//                               }}
//                             />
//                             <button
//                               className="btn btn-outline-secondary"
//                               type="button"
//                               onClick={() => setShowConfirmPassword(!showConfirmPassword)}
//                             >
//                               {showConfirmPassword ? <EyeOff size={20} /> : <Eye size={20} />}
//                             </button>
//                           </div>
//                           {passwordError && (
//                             <div className="invalid-feedback d-block">{passwordError}</div>
//                           )}
//                         </div>
//                       </>
//                     )}

//                     <button
//                       type="button"
//                       onClick={handleResendCode}
//                       disabled={resendDisabled}
//                       className="btn text-secondary fs-6 p-0 mb-3 border-0"
//                       style={{ background: 'none' }}
//                     >
//                       {resendTimer > 0 ? `Resend Code (${resendTimer}s)` : 'Resend Code'}
//                     </button>

//                     <button
//                       type="submit"
//                       className="btn w-100 py-2 mb-3 fs-5 text-white"
//                       style={{
//                         backgroundColor: '#902637',
//                         borderColor: '#902637'
//                       }}
//                       disabled={isLoading}
//                     >
//                       {isLoading ? 'Updating...' : isOtpVerified ? 'Update Password' : 'Verify OTP'}
//                     </button>

//                     <button
//                       type="button"
//                       onClick={() => {
//                         setIsSubmitted(false);
//                         setInput('');
//                         setIsOtpVerified(false);
//                       }}
//                       className="btn text-secondary fs-6 p-0 border-0"
//                       style={{ background: 'none' }}
//                     >
//                       Change Email Or Phone Number
//                     </button>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }

//   return (
//     <div className="container-fluid d-flex align-items-center min-vh-100" 
//       style={{ 
//         backgroundImage: "url('https://shilohbridal.pmpframe.com/api/upload/login_banner.jpeg')", 
//         backgroundSize: "cover", 
//         backgroundPosition: "center", 
//       }}
//     >
//       <div className="container">
//         <div className="row justify-content-center">
//           <div className="col-md-6">
//             <div className="card mt-5">
//               <div className="card-body">
//                 <div className="mb-4">
//                   <div 
//                     className="d-flex align-items-center" 
//                     onClick={() => navigate('/signin')} 
//                     style={{ cursor: 'pointer' }}
//                   >
//                     <ArrowLeft size={28} className="me-2" />
//                     <span className="fs-5">Forgot Password</span>
//                   </div>
//                 </div>

//                 <p className="text-muted mb-4">
//                   Please Enter Your Email Address or Mobile number To Receive A Verification Code
//                 </p>

//                 <form onSubmit={handleSubmit}>
//                   <div className="mb-3">
//                     <label
//                       htmlFor="emailOrPhone"
//                       className="form-label fw-medium"
//                     >
//                       Email or Phone Number
//                     </label>
//                     <input
//                       id="emailOrPhone"
//                       type="text"
//                       value={input}
//                       onChange={(e) => {
//                         setInput(e.target.value);
//                         if (error) setError('');
//                       }}
//                       className={`form-control ${error ? 'is-invalid' : ''}`}
//                       placeholder="Enter email or phone number"
//                     />
//                     {error && (
//                       <div className="invalid-feedback">{error}</div>
//                     )}
//                   </div>

//                   <button
//                     type="submit"
//                     className="btn btn-danger btn-sm w-100 py-2 fs-6"
//                     style={{ 
//                       backgroundColor: '#902637',
//                       borderColor: '#902637'
//                     }}
//                   >
//                     Submit
//                   </button>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Password;





import React, { useState, useRef } from 'react';
import { ArrowLeft, Eye, EyeOff } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const Password = () => {
  const navigate = useNavigate();
  const [submitError, setSubmitError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);
  const [input, setInput] = useState('');
  const [error, setError] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [code, setCode] = useState(['', '', '', '']);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ];

  const validateInput = (value) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^\+?[\d\s-]{10,}$/;

    if (!value.trim()) {
      return 'This field is required';
    }
    if (!emailPattern.test(value) && !phonePattern.test(value)) {
      return 'Please enter a valid email or phone number';
    }
    return '';
  };

  const handleChange = (index, value) => {
    if (value.match(/^[0-9]$/)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);
      
      if (index < 3 && value !== '') {
        inputRefs[index + 1].current.focus();
      }
    }
  };

 
  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace') {
      e.preventDefault();
      const newCode = [...code];
      
      if (code[index]) {
        newCode[index] = '';
        setCode(newCode);
      } else if (index > 0) {
        newCode[index - 1] = '';
        setCode(newCode);
        inputRefs[index - 1].current.focus();
      }
    }
  };


  const handleResendCode = async () => {
    try {
      setResendDisabled(true);
      setResendTimer(30);

      const formData = new FormData();
      formData.append('email_mobile', input);
      formData.append('action', 'online');

      const response = await fetch("https://shilohbridal.pmpframe.com/api/forgot_password.php", {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      
      if (!response.ok) {
        setError(data.message || 'Failed to resend code. Please try again.');
      }

      const timer = setInterval(() => {
        setResendTimer((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            setResendDisabled(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

    } catch (err) {
      setError('Failed to resend code. Please try again.');
      setResendDisabled(false);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationError = validateInput(input);
    setError(validationError);

    if (!validationError) {
      try {
        const formData = new FormData();
        formData.append('email_mobile', input);
        formData.append('action', 'online');

        const response = await fetch("https://shilohbridal.pmpframe.com/api/forgot_password.php", {
          method: "POST",
          body: formData,
        });

        const data = await response.json();
        
        if (response.ok) {
          setIsSubmitted(true);
        } else {
          setError(data.message || 'An error occurred. Please try again.');
        }
      } catch (err) {
        setError('An error occurred. Please try again.');
      }
    }
  };

  const validatePasswords = () => {
    if (!newPassword) {
      setPasswordError('New password is required');
      return false;
    }
    if (!confirmPassword) {
      setPasswordError('Confirm password is required');
      return false;
    }
    if (newPassword !== confirmPassword) {
      setPasswordError('Passwords do not match');
      return false;
    }
    if (newPassword.length < 6) {
      setPasswordError('Password should be at least 6 characters long');
      return false;
    }
    return true;
  };

  const verifyOtp = async (otp) => {
    try {
      const formData = new FormData();
      formData.append('email_mobile', input);
      formData.append('otp', otp);
      formData.append('action', 'verify_otp');

      const response = await fetch("https://shilohbridal.pmpframe.com/api/update_password.php", {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      
      if (data.success) {
        setIsOtpVerified(true);
        setSubmitError('');
        return true;
      } else {
        setSubmitError(data.message || 'Invalid OTP. Please try again.');
        return false;
      }
    } catch (err) {
      setSubmitError('Failed to verify OTP. Please try again.');
      return false;
    }
  };

  const handleVerificationSubmit = async (e) => {
    e.preventDefault();
    setSubmitError('');
    setPasswordError('');

    const otp = code.join('');
    if (otp.length !== 4) {
      setSubmitError('Please enter the complete 4-digit code');
      return;
    }

    if (!isOtpVerified) {
      const otpVerified = await verifyOtp(otp);
      if (!otpVerified) return;
    } else {
      if (!validatePasswords()) return;

      setIsLoading(true);
      try {
        const formData = new FormData();
        formData.append('email_mobile', input);
        formData.append('otp', otp);
        formData.append('action', 'update_password');
        formData.append('new_password', newPassword);
        formData.append('confirm_password', confirmPassword);

        const response = await fetch("https://shilohbridal.pmpframe.com/api/update_password.php", {
          method: "POST",
          body: formData,
        });

        const data = await response.json();

        if (data.success) {
          navigate('/signin');
        } else {
          setSubmitError(data.message || 'Failed to update password. Please try again.');
        }
      } catch (err) {
        setSubmitError('An error occurred. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  if (isSubmitted) {
    return (
      <div className="container-fluid d-flex align-items-center min-vh-100" 
        style={{ 
          backgroundImage: "url('https://shilohbridal.pmpframe.com/api/upload/login_banner.jpeg')", 
          backgroundSize: "cover", 
          backgroundPosition: "center", 
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="card mt-5">
                <div className="card-body">
                  <div className="d-flex align-items-center mb-3">
                    <ArrowLeft className="me-2" size={28} />
                    <h2 className="mb-0 fs-3">
                      {isOtpVerified ? 'Reset Password' : 'Submit Your Code'}
                    </h2>
                  </div>
                  
                  {!isOtpVerified && (
                    <p className="text-muted mb-4 fs-6">
                      Please Enter The 4-Digit Code Sent To {input}
                    </p>
                  )}

                  <form onSubmit={handleVerificationSubmit}>
                    {!isOtpVerified && (
                      <>
                        <div className="d-flex gap-3 mb-4">
                          {code.map((digit, index) => (
                            <input
                              key={index}
                              ref={inputRefs[index]}
                              type="text"
                              maxLength={1}
                              value={digit}
                              onChange={(e) => handleChange(index, e.target.value)}
                              onKeyDown={(e) => handleKeyDown(index, e)}
                              className="form-control text-center"
                              style={{
                                width: '3rem',
                                height: '3rem',
                                fontSize: '1.5rem'
                              }}
                            />
                          ))}
                        </div>

                        <button
                          type="button"
                          onClick={handleResendCode}
                          disabled={resendDisabled}
                          className="btn text-secondary fs-6 p-0 mb-3 border-0"
                          style={{ background: 'none' }}
                        >
                          {resendTimer > 0 ? `Resend Code (${resendTimer}s)` : 'Resend Code'}
                        </button>
                      </>
                    )}

                    {submitError && (
                      <div className="alert alert-danger py-2 mb-3">{submitError}</div>
                    )}

                    {isOtpVerified && (
                      <>
                        <div className="mb-3">
                          <label htmlFor="newPassword" className="form-label fw-medium">
                            New Password
                          </label>
                          <div className="input-group">
                            <input
                              type={showNewPassword ? "text" : "password"}
                              className={`form-control ${passwordError ? 'is-invalid' : ''}`}
                              id="newPassword"
                              value={newPassword}
                              onChange={(e) => {
                                setNewPassword(e.target.value);
                                setPasswordError('');
                              }}
                            />
                            <button
                              className="btn btn-outline-secondary"
                              type="button"
                              onClick={() => setShowNewPassword(!showNewPassword)}
                            >
                              {showNewPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                            </button>
                          </div>
                        </div>

                        <div className="mb-4">
                          <label htmlFor="confirmPassword" className="form-label fw-medium">
                            Confirm Password
                          </label>
                          <div className="input-group">
                            <input
                              type={showConfirmPassword ? "text" : "password"}
                              className={`form-control ${passwordError ? 'is-invalid' : ''}`}
                              id="confirmPassword"
                              value={confirmPassword}
                              onChange={(e) => {
                                setConfirmPassword(e.target.value);
                                setPasswordError('');
                              }}
                            />
                            <button
                              className="btn btn-outline-secondary"
                              type="button"
                              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            >
                              {showConfirmPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                            </button>
                          </div>
                          {passwordError && (
                            <div className="invalid-feedback d-block">{passwordError}</div>
                          )}
                        </div>
                      </>
                    )}

                    <button
                      type="submit"
                      className="btn w-100 py-2 mb-3 fs-5 text-white"
                      style={{
                        backgroundColor: '#902637',
                        borderColor: '#902637'
                      }}
                      disabled={isLoading}
                    >
                      {isLoading ? 'Updating...' : isOtpVerified ? 'Update Password' : 'Verify OTP'}
                    </button>

                    {!isOtpVerified && (
                      <button
                        type="button"
                        onClick={() => {
                          setIsSubmitted(false);
                          setInput('');
                          setIsOtpVerified(false);
                        }}
                        className="btn text-secondary fs-6 p-0 border-0"
                        style={{ background: 'none' }}
                      >
                        Change Email Or Phone Number
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid d-flex align-items-center min-vh-100" 
      style={{ 
        backgroundImage: "url('https://shilohbridal.pmpframe.com/api/upload/login_banner.jpeg')", 
        backgroundSize: "cover", 
        backgroundPosition: "center", 
      }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card mt-5">
              <div className="card-body">
                <div className="mb-4">
                  <div 
                    className="d-flex align-items-center" 
                    onClick={() => navigate('/signin')} 
                    style={{ cursor: 'pointer' }}
                  >
                    <ArrowLeft size={28} className="me-2" />
                    <span className="fs-5">Forgot Password</span>
                  </div>
                </div>

                <p className="text-muted mb-4">
                  Please Enter Your Email Address or Mobile number To Receive A Verification Code
                </p>

                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label
                      htmlFor="emailOrPhone"
                      className="form-label fw-medium"
                    >
                      Email or Phone Number
                    </label>
                    <input
                      id="emailOrPhone"
                      type="text"
                      value={input}
                      onChange={(e) => {
                        setInput(e.target.value);
                        if (error) setError('');
                      }}
                      className={`form-control ${error ? 'is-invalid' : ''}`}
                      placeholder="Enter email or phone number"
                    />
                    {error && (
                      <div className="invalid-feedback">{error}</div>
                    )}
                  </div>

                  <button
                    type="submit"
                    className="btn btn-danger btn-sm w-100 py-2 fs-6"
                    style={{ 
                      backgroundColor: '#902637',
                      borderColor: '#902637'
                    }}
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Password;



// import React, { useState } from 'react';
// import { Eye, EyeOff } from 'lucide-react';

// const CreateNewPassword = () => {
//   const [formData, setFormData] = useState({
//     newPassword: '',
//     confirmPassword: ''
//   });
  
//   const [errors, setErrors] = useState({
//     newPassword: '',
//     confirmPassword: ''
//   });
  
//   const [showPassword, setShowPassword] = useState({
//     new: false,
//     confirm: false
//   });

//   // Validate password requirements
//   const validatePassword = (password) => {
//     if (password.length < 8) {
//       return 'Password must be at least 8 characters long';
//     }
//     return '';
//   };

//   // Handle input changes
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData(prev => ({
//       ...prev,
//       [name]: value
//     }));
    
//     // Validate on change
//     if (name === 'newPassword') {
//       const error = validatePassword(value);
//       setErrors(prev => ({
//         ...prev,
//         newPassword: error
//       }));
//     } else if (name === 'confirmPassword') {
//       setErrors(prev => ({
//         ...prev,
//         confirmPassword: value !== formData.newPassword ? 'Passwords do not match' : ''
//       }));
//     }
//   };

//   // Handle form submission
//   const handleSubmit = (e) => {
//     e.preventDefault();
    
//     // Final validation before submission
//     const newPasswordError = validatePassword(formData.newPassword);
//     const confirmPasswordError = formData.newPassword !== formData.confirmPassword 
//       ? 'Passwords do not match' 
//       : '';
    
//     if (newPasswordError || confirmPasswordError) {
//       setErrors({
//         newPassword: newPasswordError,
//         confirmPassword: confirmPasswordError
//       });
//       return;
//     }
    
//     // If validation passes, you can submit the form
//     console.log('Form submitted:', formData);
//     // Add your submission logic here
//   };

//   return (
//     <div className="container">
//       <div className="row justify-content-center">
//         <div className="col-md-6">
//           <div className="card mt-4">
//             <div className="card-body">
//               <div className="d-flex align-items-center mb-4">
//                 <button 
//                   onClick={() => window.history.back()} 
//                   className="btn btn-link text-secondary fs-4"
//                 >
//                   &#8592;
//                 </button>
//                 <h1 className="h4 mb-0 ms-2">Create New Password</h1>
//               </div>

//               <p className="text-secondary mb-4">
//                 Your New Password Must Be Different From Previously Used Password
//               </p>

//               <form onSubmit={handleSubmit}>
//                 <div className="mb-3">
//                   <label className="form-label">New Password</label>
//                   <div className="input-group">
//                     <input
//                       type={showPassword.new ? 'text' : 'password'}
//                       name="newPassword"
//                       value={formData.newPassword}
//                       onChange={handleChange}
//                       className="form-control"
//                       placeholder="At least 8 characters"
//                     />
//                     <button
//                       type="button"
//                       onClick={() => setShowPassword(prev => ({ ...prev, new: !prev.new }))}
//                       className="btn btn-outline-secondary"
//                     >
//                       {showPassword.new ? <EyeOff size={20} /> : <Eye size={20} />}
//                     </button>
//                   </div>
//                   {errors.newPassword && (
//                     <div className="text-danger small mt-1">{errors.newPassword}</div>
//                   )}
//                 </div>

//                 <div className="mb-4">
//                   <label className="form-label">Confirm New Password</label>
//                   <div className="input-group">
//                     <input
//                       type={showPassword.confirm ? 'text' : 'password'}
//                       name="confirmPassword"
//                       value={formData.confirmPassword}
//                       onChange={handleChange}
//                       className="form-control"
//                     />
//                     <button
//                       type="button"
//                       onClick={() => setShowPassword(prev => ({ ...prev, confirm: !prev.confirm }))}
//                       className="btn btn-outline-secondary"
//                     >
//                       {showPassword.confirm ? <EyeOff size={20} /> : <Eye size={20} />}
//                     </button>
//                   </div>
//                   {errors.confirmPassword && (
//                     <div className="text-danger small mt-1">{errors.confirmPassword}</div>
//                   )}
//                 </div>

//                 <button
//                   type="submit"
//                   className="btn btn-danger w-100"
//                   disabled={!!errors.newPassword || !!errors.confirmPassword}
//                 >
//                   Submit
//                 </button>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CreateNewPassword;