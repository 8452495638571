import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

// Import required packages and components
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Outlet,
} from "react-router-dom";

// Import pages and components for routing
import Home from "./pages/Home";
import ShopByCategories from "./pages/ShopByCategories";
import Product from "./pages/Product";
import MyCart from "./pages/MyCart";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import ForgotPasswordNew from "./pages/ForgotPasswordNew";
import NewPassword from "./components/ForgotPassword/NewPassword";
import Wishlist from "./components/Wishlist/Wishlist";
import Account from "./pages/Account";
import Address from "./components/Address/Address";
import Orders from "./components/Orders/Orders";
// import ForgotPass from './components/ForgotPass/ForgotPass';
import PersonalDetails from "./components/PersonalDetails/PersonalDetails";
import PaymentMethods from "./components/Payment/PaymentMethods";
import CreditDebit from "./components/Payment/CreditDebit";
import Upi from "./components/Payment/Upi";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import store from "./Redux/store";
import { get_user_id } from "./Redux/Slice/UserSlice";
import {
  get_blog_list,
  get_bridal_collection,
  get_category_list,
  get_gallary_list,
  get_shop_by_category,
  get_state_list,
  get_testimonial_list,
} from "./Redux/Slice/OtherSlice";
// import Layout from "./components/Layout/Layout";
import AllBlog from "./pages/AllBlog";
import ScrollToTop from "./utils/Hook/ScrollToTop";
import BlogDetails from "./pages/BlogDetails";
import { get_wish_list } from "./Redux/Slice/ProductSlice";
import Layout from "./ChildComponent/Layout/Layout";
import CartLayout from "./ChildComponent/Layout/CartLayout";
import Cart from "./components/Cart/Cart";
import CheckoutSummary from "./components/Cart/CheckoutSummary";
import ProfileLayout from "./ChildComponent/Layout/ProfileLayout";
// import NotFound from './pages/NotFound';

function App() {
  const { user_id } = useSelector((state) => state.userData);
  const navigate = useNavigate();

  const userId = localStorage.getItem("user_id");

  console.log("user_id ====", user_id);

  useEffect(() => {
    store.dispatch(get_blog_list());
    store.dispatch(get_testimonial_list());
    store.dispatch(get_category_list());
    store.dispatch(get_gallary_list());
    store.dispatch(get_shop_by_category());
    store.dispatch(get_bridal_collection());
    store.dispatch(get_state_list());
    if (userId) {
      store.dispatch(get_user_id(userId));
    }
  }, []);

  // useEffect(() => {
  //   if (user_id) {
  //     store.dispatch(get_wish_list(user_id));
  //     navigate("/");
  //   }
  // }, []);

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/signin" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password-new" element={<ForgotPasswordNew />} />
        <Route path="/new-password" element={<NewPassword />} />

        <Route element={<Layout />}>
          <Route path="/" element={<Outlet />}>
            <Route path="/" element={<Home />} />
            <Route path="/blog" element={<AllBlog />} />
            <Route path="/blog-details" element={<BlogDetails />} />
            <Route path="/shopbycategories" element={<ShopByCategories />} />
            <Route
              path="/shopbycategories/:id/:cat_id"
              element={<ShopByCategories />}
            />
            <Route path="/product/:id" element={<Product />} />
            {/* <Route path="/MyCart" element={<MyCart />} /> */}
            <Route element={<CartLayout />}>
              <Route path="/cart" element={<Outlet />}>
                <Route path="/cart/mycart" element={<Cart />} />
                <Route path="/cart/address" element={<CheckoutSummary />} />
              </Route>
            </Route>

            <Route path="/wishlist" element={<Wishlist />} />
            <Route element={<ProfileLayout />}>
              <Route path="/dashboard" element={<Outlet />}>
                {/* <Route path="/cart/mycart" element={<Cart />} />
                <Route path="/cart/address" element={<CheckoutSummary />} /> */}
                <Route path="/dashboard/account" element={<Account />} />
                <Route path="/dashboard/address" element={<Address />} />
                <Route path="/dashboard/orders" element={<Orders />} />
                <Route
                  path="/dashboard/PersonalDetails"
                  element={<PersonalDetails />}
                />
                <Route
                  path="/dashboard/PaymentMethods"
                  element={<PaymentMethods />}
                />
                <Route
                  path="/dashboard/CreditDebit"
                  element={<CreditDebit />}
                />
              </Route>
            </Route>
            <Route path="/Upi" element={<Upi />} />
          </Route>
        </Route>

        {/* <Route path="*" element={<NotFound />} />  */}
      </Routes>
    </>
  );
}

export default App;
