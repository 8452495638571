import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector } from "react-redux";
import { BlogCard } from "./BlogCard";
import { useNavigate } from "react-router-dom";
import { Button } from "../../ChildComponent/Button/Button";

export default function Blog() {
  const { blog } = useSelector((state) => state.otherData);
  const navigate = useNavigate();
  return (
    <div className="container-fluid my-5 px-5">
      <h2
        className=" mb-4"
        style={{
          fontSize: "40px",
          fontFamily: "Playfair Display",
          color: "#121212BF",
          fontWeight: "600",
        }}
      >
        Our Blog
      </h2>
      <div className="row row-cols-1 row-cols-md-2 g-4">
        {blog.slice(0, 2).map((v, i) => (
          <BlogCard
            key={i}
            image={v?.image}
            id={v?.id}
            title={v?.title}
            data={v?.date}
            onClick={() => navigate("/blog-details", { state: v })}
          />
        ))}
      </div>

      <div className="d-flex justify-content-center mt-4">
        <Button
          title={" View All"}
          onClick={() => navigate("/blog")}
          icon={<span style={{ fontSize: "25px" }}>→</span>}
        />
      </div>
    </div>
  );
}
