import React, { Children } from "react";
import { motion } from 'framer-motion';


export const Drawer = ({ children, open, setOpen, title, size = "300px" }) => {
  return (
<div
    className={`collapse navbar-collapse ${open ? "show" : ""}`}
    style={{
      position: open ? "fixed" : "static",
      top: 0,
      right: 0,
      bottom: 0,
      width: open ? size : "0",
      height: "100vh",
      backgroundColor: "#fff",
      zIndex: 1000,
      transition: "all 0.3s ease-in-out",
      overflowY: "auto",
      boxShadow: open ? "-4px 0 10px rgba(0, 0, 0, 0.1)" : "none",
      padding: "20px",
    }}
  >
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h5 className="m-0">{title}</h5>
        <button
          className="btn-close"
          onClick={() => setOpen(!open)}
          aria-label="Close menu"
        ></button>
      </div>
      {children}
    </div>
  );
};
