import React from "react";
import { Outlet } from "react-router-dom";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import Sidebar from "../../components/Sidebar/Sidebar";

const ProfileLayout = () => {
  return (
    <div>
      <Container fluid className="dashboard-container">
        <h1
          className="py-4"
          style={{ textAlign: "center", color: "#121212BF" }}
        >
          My Account
        </h1>
        <Row className="dashboard">
          <Col md={2}>
            <Sidebar />
          </Col>
          <Col md={10}>
            <Outlet />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProfileLayout;
