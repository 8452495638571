import React from "react";
import { Outlet } from "react-router-dom";
import NavHeading from "../../components/Navheading/NavHeading";
import Navbar from "../../components/Navbar/Navbar";

const Layout = () => {
  return (
    <div>
      <NavHeading />
      <Navbar />
      <Outlet />
    </div>
  );
};

export default Layout;
